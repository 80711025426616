const FetchHorseNames = (
  raceNumber: number,
  horseNumbers: number[],
  metaRaceDataDetails: any,
  currentLanguage: string
) => {
  let jockeyNames: string[] = [];
  let horseNames: string[] = [];
  let trainerNames: string[] = [];
  let currentRatings: string[] = [];
  // console.log(metaRaceDataDetails[raceNumber - 1].runners[0].no);
  const runners: any[] = metaRaceDataDetails[raceNumber - 1].runners;
  // console.log(runners);
  // console.log(`Horse Number Array: ${horseNumbers}`);
  horseNumbers.forEach((horseNumber) => {
    runners.forEach((runner: any) => {
      // console.log(`Horse Number: ${typeof horseNumber}`);
      if (horseNumber.toString() === runner.no) {
        if (currentLanguage === "en") {
          // console.log(runner);
          // jockeyNames.push(runner.jockey.name_en);

          horseNames.push(runner.no + " " + runner.name_en);

          // trainerNames.push(runner.trainer.name_en);
        } else {
          // jockeyNames.push(runner.jockey.name_ch);

          horseNames.push(runner.no + " " + runner.name_ch);

          // trainerNames.push(runner.trainer.name_ch);
        }
        currentRatings.push(runner.currentRating);
      }
    });
  });

  return [jockeyNames, horseNames, trainerNames, currentRatings];
};

export default FetchHorseNames;
