import axios from "axios";
import { store } from "../../store";
import { resetToken } from "../../state/auth/authSlice";
const envUrl = process.env.REACT_APP_API_URL;
const refreshAccessUrl: string = envUrl + "token/refresh/";

const RefreshAccessToken = async (
  refreshToken: string
): Promise<string | null> => {
  const refreshTokenData = {
    refresh: refreshToken,
  };

  try {
    const response = await axios.post(refreshAccessUrl, refreshTokenData);
    const accessToken = response.data.data.access.toString();
    return accessToken;
  } catch (error: any) {
    // Check for token expiration error or status code
    if (error.response && error.response.status === 401) {
      console.log("Refresh token expired. Redirecting to login...");
      store.dispatch(resetToken());
      window.location.href = "/login";
    } else {
      console.log("Unexpected Error During Refresh:", error);
    }
    return null;
  }
};

export default RefreshAccessToken;
