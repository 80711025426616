const fetchRaceStartTime = (raceNumber: number, metaRaceDataDetails: any) => {
  try {
    let postTime = metaRaceDataDetails[raceNumber - 1]?.postTime;
    const dateObj = new Date(postTime);
    const timeStr = dateObj.toLocaleTimeString("en-GB", {
      hour12: false,
      timeZone: "Asia/Hong_Kong",
    });
    const date = dateObj.toISOString().split("T")[0]; // "2024-10-23"
    console.log(timeStr, dateObj);
    return [date, timeStr];
  } catch (error) {
    console.error(error);
    const dateObj = new Date();
    const timeStr = dateObj.toLocaleTimeString("en-GB", {
      hour12: false,
      timeZone: "Asia/Hong_Kong",
    });
    console.log(dateObj, timeStr);
    return [dateObj.toISOString().split("T")[0], timeStr];
  }
};

export default fetchRaceStartTime;
