import axios from "axios";

const envUrl = process.env.REACT_APP_API_URL;
const allOddsUrl = envUrl + "all-odds/";

const allOdds = async () => {
  try {
    const response = await axios.get(allOddsUrl);
    const racesString = response.data.data.data;
    let races;

    try {
      races = JSON.parse(racesString);
    } catch (error) {
      console.error("Error parsing JSON:", error);
      throw new Error("Failed to parse race data");
    }

    const overallRaceNumber = races.length;

    const objPayload = {
      totalRace: overallRaceNumber,
      odds: races,
    };

    console.log("Fetched Odds Data:", objPayload); // Log the data being returned
    return objPayload;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error; // Re-throw the error to be handled by the caller
  }
};

export default allOdds;
