const InstantSchedularOneHour = async (
  raceStartTimeString: string,
  raceStartDate: string,
  raceStartDateSaved: string,
  raceStartTimeStringSaved: string
) => {
  const currentDate = new Date();
  let isSchedule: boolean = false;

  // Get the current date and time in Hong Kong timezone
  const currentHongKongDateUnformatted = currentDate.toLocaleDateString(
    "en-US",
    {
      timeZone: "Asia/Hong_Kong",
    }
  );
  const [month, day, year] = currentHongKongDateUnformatted.split("/");
  const currentHongKongDateFormatted = `${year}-${month.padStart(
    2,
    "0"
  )}-${day.padStart(2, "0")}`;

  const currentHongKongTimeString = currentDate.toLocaleTimeString("en-US", {
    hour12: false,
    timeZone: "Asia/Hong_Kong",
  });

  //   console.log(`This is race start date: ${raceStartDate}`);
  //   console.log("Current Hong Kong Date: ", currentHongKongDateFormatted);

  const raceStartDateTimeSaved = new Date(
    `${raceStartDateSaved}T${raceStartTimeStringSaved}`
  );
  const dayAfterRaceDate = new Date(raceStartDateTimeSaved);
  dayAfterRaceDate.setDate(raceStartDateTimeSaved.getDate() + 1);
  const dayAfterRaceFormatted = dayAfterRaceDate.toISOString().split("T")[0];

  if (
    currentHongKongDateFormatted === raceStartDate ||
    currentHongKongDateFormatted <= dayAfterRaceFormatted
  ) {
    const currentHongKongDateTime = new Date(
      `${raceStartDate}T${currentHongKongTimeString}`
    );
    const raceStartDateTime = new Date(
      `${raceStartDate}T${raceStartTimeString}`
    );

    // console.log(`This is current Hongkong Time: ${currentHongKongDateTime}`);
    // console.log(`This is race start time: ${raceStartDateTime}`);

    // Subtract 2 minutes from the race start time
    const oneHourBeforeRace = new Date(
      raceStartDateTime.getTime() - 60 * 60000 // 60 minutes * 60,000 milliseconds
    );
    // console.log("Race Start DateTime: ", raceStartDateTime);
    // console.log("Two Minutes Before Race: ", oneHourBeforeRace);
    // console.log("Current Hong Kong DateTime: ", currentHongKongDateTime);

    if (currentHongKongDateTime >= oneHourBeforeRace) {
      isSchedule = true;
    } else if (currentHongKongDateFormatted <= dayAfterRaceFormatted) {
      isSchedule = true;
    } else {
      isSchedule = false;
    }
  } else {
    isSchedule = false;
  }

  return isSchedule;
};

export default InstantSchedularOneHour;
