import { Flex, Segmented } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
type segmentProps = {
  value: string;
};

const Segment = ({ value }: segmentProps) => {
  const navigate = useNavigate();
  const onChange = (value: string) => {
    //     <Route
    //     path="/race_information/upcoming_race"
    //     element={<UpcomingRace />}
    //   ></Route>
    //   <Route path="/race_information/win_place" element={<WinPlace />}></Route>
    //   <Route path="/race_information/quinella" element={<Quinella />}></Route>
    //   <Route path="/race_information/forecast" element={<Forecast />}></Route>
    //   <Route path="/race_information/trio" element={<Trio />}></Route>

    navigate(`/tips/${value}`);
  };
  const { t } = useTranslation();
  return (
    <div style={{ marginBottom: "10px" }}>
      {" "}
      <Flex justify="center" style={{ width: "100%" }}>
        {" "}
        <Segmented
          options={[
            {
              label: (
                <span style={{ fontSize: "0.6rem", padding: "0px" }}>
                  {t("statistic_king")}
                </span>
              ),
              value: "statistics_king",
            },
            {
              label: (
                <span style={{ fontSize: "0.6rem", padding: "0px" }}>
                  {t("horse_strength_index")}
                </span>
              ),
              value: "horse_strength_index",
            },
            {
              label: (
                <span style={{ fontSize: "0.6rem", padding: "0px" }}>
                  {t("final_tips")}
                </span>
              ),
              value: "Final_Tip",
            },
          ]}
          value={value}
          onChange={onChange}
        />
      </Flex>
    </div>
  );
};

export default Segment;
