import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import type { FormProps } from "antd";
import { useDispatch } from "react-redux";
import { Button, Checkbox, Form, Input, Flex, Typography, message } from "antd";
import MainLayout from "../Layout/MainLayout";
import axios from "axios";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { login } from "../../state/auth/authSlice";

const { Title } = Typography;

type FieldType = {
  username?: string;
  password?: string;
  remember?: boolean;
};

const boxStyle: React.CSSProperties = {
  width: "30%",
  margin: "0 auto",
  boxShadow: "0 0 8px rgba(0, 0, 0, 0.4)",
};

const innerStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  marginBottom: "8%",
  marginRight: "4%",
  alignItems: "center",
};

const ChangePassword: React.FC = () => {
  return (
    <div>
      <MainLayout Component={PageDisplay} />
    </div>
  );
};

export default ChangePassword;

const PageDisplay: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [postSubmitMessage, setPostSubmitMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [isEmail, setIsEmail] = useState<boolean>();
  const [showForgotPasswordForm, setShowForgotPasswordForm] =
    useState<boolean>(false);

  const envUrl = process.env.REACT_APP_API_URL;
  const loginUrl = envUrl + "";
  const loginUser = async () => {
    const payload = {
      username: userName,
      password: password,
    };
    try {
      const response = await axios.post(loginUrl, payload);
      console.log("Login successful:", response.data.data.refresh);
      const responseData = response.data;
      console.log(responseData.data.refresh);
      console.log(responseData.data.access);
      // console.log(`RefreshToken: ${response.data.user.refresh}`);
      dispatch(
        login({
          isLoggedIn: true, // Set isLoggedIn to true
          user: {
            refreshToken: responseData.data.refresh,
            accessToken: responseData.data.access,
          },
        })
      );
      setTimeout(() => {
        setLoading(false);
        message.success("Login Successful!");
        form.resetFields();
      }, 1000);
      navigate("/");
    } catch (error) {
      console.log(error);
      if (axios.isAxiosError(error)) {
        setLoading(false);
        // message.error("something went wrong");
        const serverError = error.response?.data;
        const serverErrorMessage = error.message;
        console.log(serverErrorMessage);
        console.log(serverError.message);
        if (
          serverError.message ===
          "No active account found with the given credentials"
        ) {
          setLoading(false);
          setPostSubmitMessage("*Invalid Credentials.");
          message.error("Invalid Credentails, Please try again");
        }
      } else {
        setLoading(false);
        setPostSubmitMessage("*Invalid Credentials.");
        console.error("Unexpected error:", error);
        message.error("An unexpected error occurred. Please try again.");
      }
    }
  };

  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    setLoading(true);

    const { username } = values;

    // Regular expression to check if the username is an email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Regular expression to check if the username is a phone number (basic)
    const phoneNumberRegex = /^(?:\+852\s?)?[569]\d{7}$|^(?:\+86\s?)?1\d{10}$/;

    if (typeof username === "string") {
      if (emailRegex.test(username)) {
        console.log("The username is an email.");
      } else {
        console.log(
          "The username is neither a valid email nor a phone number."
        );
      }
    }

    // Proceed with your logic
    setLoading(false);
  };

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (
    errorInfo
  ) => {
    console.log("Failed:", errorInfo);
  };
  const handleForgotPassword: FormProps<FieldType>["onFinish"] = async (
    values
  ) => {
    setLoading(true);
    // Implement your password reset logic here
    console.log("Forgot Password submitted for username:", values.username);
    message.success("Password reset instructions sent to your email.");
    setLoading(false);
    setShowForgotPasswordForm(false); // Hide the form after submission
  };
  return (
    <div>
      {" "}
      <Flex style={boxStyle} justify="center" align="center">
        <div style={innerStyle}>
          <Title level={4} style={{ marginBottom: "8%" }}>
            Change Password
          </Title>
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item<FieldType>
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="Password"
                size="large"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Item>
            <Form.Item<FieldType>
              label="Confirm Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="Password"
                size="large"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 19, span: 16 }}>
              {" "}
              <Button
                className="submitBtn"
                type="primary"
                htmlType="submit"
                loading={loading}
                style={{ marginLeft: "4px" }}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Flex>
    </div>
  );
};
