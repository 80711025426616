const RaceInformationSaved = (metaSavedData: any, currentLanguage: string) => {
  try {
    try {
      console.log(metaSavedData);
      const selectedRaceMeeting: any = metaSavedData.find(
        (meeting: any) =>
          meeting.venueCode === "HV" || meeting.venueCode === "ST"
      );

      let allRacesDataInfo;
      console.log(selectedRaceMeeting);
      const dateTime = selectedRaceMeeting.date;
      let venueCode = selectedRaceMeeting.venueCode;
      const allRacesData = selectedRaceMeeting.races;
      console.log(allRacesData);
      if (currentLanguage === "zh") {
        venueCode = venueCode === "HV" ? "跑馬地" : "沙田馬場";
      } else {
        venueCode = venueCode === "HV" ? "Happy Valley" : "Sha Tin";
      }

      const date = new Date(dateTime);
      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const daysOfWeekChinese = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];

      const dayName =
        currentLanguage === "zh"
          ? daysOfWeekChinese[date.getDay()]
          : daysOfWeek[date.getDay()];
      allRacesDataInfo = dayName + ", " + dateTime + ", " + venueCode;
      return [allRacesData, allRacesDataInfo, venueCode];
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return [[], ""];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [[], ""];
  }
};

export default RaceInformationSaved;
