import React, { useEffect, useState } from "react";
import MainLayout from "../Layout/MainLayout";
import { Card, Flex, Spin } from "antd";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import RefreshAccessToken from "../Authentication/RefreshToken";
import CubeComponent from "../MysteryBox/CubeComponent";
import { motion } from "framer-motion";
import "../../styles/InstantKeyHorse.css";
import FetchHorseInformaiton from "../Mapper/horseIndexInformationMapper";
import fetchAllMetaDetailsOdds from "../CommonApiCalls/metaDataRaceInformation";
import LockedContent from "../LockedContent/LockedContent";
import TitleCardDesktop from "../Tips/Responsive Title/TitleCardDesktop";
import { useTranslation } from "react-i18next";
import TitleCard from "../Tips/Responsive Title/TitleCard";
import Segment from "./Responsive Title/Segment";
import fetchRaceStartTime from "../Mapper/raceSchedule";
import InstantScheduleTimer from "../OutputScheduler/InstantSchedular";
import ResultsNA from "./Dependencies/ResultsNA";
import RaceInformationSaved from "../OutputScheduler/RaceInformationSaved";
import InstantKeyHorseSetup from "./Dependencies/InstantKeyHorseSetup";

const InstantKeyHorse: React.FC = () => {
  return (
    <div>
      <MainLayout Component={PageDisplay} />
    </div>
  );
};

export default InstantKeyHorse;

const PageDisplay: React.FC = () => {
  const envUrl = process.env.REACT_APP_API_URL;
  const [raceNumber, setRaceNumber] = useState<number>(1);
  const [maxRaceNumber, setMaxRaceNumber] = useState<number>(1);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [firstTabChange, setFirstTabChange] = useState(false);
  const [keyHorse, setKeyHorse] = useState<number>(0);
  const [isVisible, setIsVisible] = useState(false);
  const [newTab, setNewTab] = useState(false);
  const [confidenceIndex, setConfidenceIndex] = useState("");
  const [jockeyName, setJockeyName] = useState("");
  const [horseName, setHorseName] = useState("");
  const [trainerName, setTrainerName] = useState("");
  const [currentRating, setCurrentRating] = useState("");

  const [raceTitle, setRaceTitle] = useState<string | boolean>("");
  const [raceDayPlace, setRaceDayPlace] = useState<string | boolean>("");
  const [restOfDetails, setRestOfDetails] = useState<string | boolean>("");

  const [raceTitleSaved, setRaceTitleSaved] = useState<string | boolean>("");
  const [raceDayPlaceSaved, setRaceDayPlaceSaved] = useState<string | boolean>(
    ""
  );
  const [restOfDetailsSaved, setRestOfDetailsSaved] = useState<
    string | boolean
  >("");

  const [showCube, setShowCube] = useState<boolean>(true);
  const [isSchedule, setIsScheduled] = useState<boolean>(true);
  const currentLanguage = useSelector((state: RootState) => state.language.lng);
  const [isMobile, setIsMobile] = useState(false);

  const { t } = useTranslation();

  const statisticskingUrl = `${envUrl}instant-key-horse/`;
  const isLoggedInRedux2 = useSelector((state: RootState) => state.auth.user);
  const fetchMetaRaceDataDetails = async () => {
    const [metaRaceData, metaRaceDataInfo] = await fetchAllMetaDetailsOdds(
      currentLanguage
    );
    return [metaRaceData, metaRaceDataInfo];
  };

  const mysteryBoxContent = (response: any, metaRaceData: any) => {
    console.log(metaRaceData);
    const responseData = response.data.data;
    console.log(responseData);
    setKeyHorse(responseData.data[raceNumber - 1].Data["Key horse"]);
    const [jockeyName, horseName, trainerName, currentRating] =
      FetchHorseInformaiton(
        raceNumber,
        responseData.data[raceNumber - 1].Data["Key horse"],
        metaRaceData,
        currentLanguage
      );
    setJockeyName(jockeyName);
    setHorseName(horseName);
    setTrainerName(trainerName);
    setCurrentRating(currentRating);
    const confidenceIndex =
      responseData.data[raceNumber - 1].Data["Confidence index"];
    let stars = "";
    if (currentLanguage === "zh") {
      stars = "星星";
    } else {
      if (confidenceIndex === 1) {
        stars = "star";
      } else {
        stars = "stars";
      }
    }
    const confidenceIndexText = `${confidenceIndex} ${stars}`;
    setConfidenceIndex(confidenceIndexText);
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const [metaRaceData, metaRaceDataInfo] = await fetchMetaRaceDataDetails();
      if (isLoggedInRedux2 && isLoggedInRedux2.user.refreshToken) {
        const refreshToken = isLoggedInRedux2.user.refreshToken;

        const accessToken = await RefreshAccessToken(refreshToken);
        if (accessToken) {
          const [
            savedMetaRaceData,
            savedMetaRaceInfoValue,
            responseValue,
            accessLevelValue,
          ] = await fetchStatisticsKingData(accessToken);

          const [raceStartDate, raceStartTime] = fetchRaceStartTime(
            raceNumber,
            metaRaceData
          );

          const [savedRaceStartDate, savedRaceStartTime] = fetchRaceStartTime(
            raceNumber,
            savedMetaRaceData
          );

          const isSchedule: boolean = await InstantScheduleTimer(
            raceStartTime,
            raceStartDate,
            savedRaceStartDate,
            savedRaceStartTime
          );
          if (isSchedule) {
            const [
              raceTitleValue,
              raceDayPlaceValue,
              restOfDetailsValue,
              showCubeValue,
              isLoadingValue,
            ] = InstantKeyHorseSetup(
              accessLevelValue,
              responseValue,
              savedMetaRaceData,
              savedMetaRaceInfoValue,
              raceNumber,
              currentLanguage,
              isSchedule,
              mysteryBoxContent
            );
            setRaceTitleSaved(raceTitleValue);
            setRaceDayPlaceSaved(raceDayPlaceValue);
            setRestOfDetailsSaved(restOfDetailsValue);
            setShowCube(showCubeValue);
            setIsLoading(isLoadingValue);
          } else {
            const [
              raceTitleValue,
              raceDayPlaceValue,
              restOfDetailsValue,
              showCubeValue,
              isLoadingValue,
            ] = InstantKeyHorseSetup(
              accessLevelValue,
              responseValue,
              metaRaceData,
              metaRaceDataInfo,
              raceNumber,
              currentLanguage,
              isSchedule,
              mysteryBoxContent
            );

            setRaceTitle(raceTitleValue);
            setRaceDayPlace(raceDayPlaceValue);
            setRestOfDetails(restOfDetailsValue);
            setShowCube(showCubeValue);
            setIsLoading(isLoadingValue);
          }
          const totalRaces = isSchedule
            ? Object.keys(savedMetaRaceData).length
            : Object.keys(metaRaceData).length;
          setMaxRaceNumber(totalRaces);
          setIsScheduled(isSchedule);
        }
      }
    };
    fetchData();
  }, [raceNumber, currentLanguage]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchStatisticsKingData = async (accessToken: string) => {
    let savedMetaRaceData: string = "";
    let responseValue: any = "";
    let accessLevelValue: string | null = "";
    let savedMetaRaceInfoValue: any = "";
    await axios
      .get(statisticskingUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        const accessLevel: string | null = response.data.message;
        savedMetaRaceData = response?.data?.data?.meta_data?.data?.raceMeetings;
        const [savedMetaData, savedMetaRaceInfo, savedVenueCode] =
          RaceInformationSaved(savedMetaRaceData, currentLanguage);
        savedMetaRaceData = savedMetaData;
        savedMetaRaceInfoValue = savedMetaRaceInfo;
        responseValue = response;
        accessLevelValue = accessLevel;
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
    return [
      savedMetaRaceData,
      savedMetaRaceInfoValue,
      responseValue,
      accessLevelValue,
    ];
  };

  const items = Array.from({ length: maxRaceNumber }, (_, i) => ({
    key: (i + 1).toString(),
    label: `Race ${i + 1}`,
    children: "",
  }));

  const onChange = (key: string) => {
    setRaceNumber(parseInt(key, 10));
    setNewTab(!newTab);
    setFirstTabChange(true);
    console.log(key);
  };

  const openMysteryBox = () => {
    setIsVisible(true);
  };
  const closeMysteryBox = () => {
    setIsVisible(false);
  };

  const [day, date, venue] = raceDayPlace.split(",").map((item) => item.trim());

  return (
    <div>
      {isMobile ? (
        <div>
          <Segment value="instant_key_horse" />
        </div>
      ) : (
        <></>
      )}
      {isMobile ? (
        <TitleCard
          title={t("instant_key_horse")}
          date={""}
          venue={""}
          day={""}
          maxRaceNumber={maxRaceNumber}
          onChange={onChange}
        />
      ) : (
        <>
          <TitleCardDesktop
            title={t("instant_key_horse")}
            date={""}
            venue={""}
            day={""}
            maxRaceNumber={maxRaceNumber}
            onChange={onChange}
          />
        </>
      )}

      {isSchedule ? (
        <>
          <Flex
            justify="space-between"
            style={{
              marginBottom: "12px",
              paddingLeft: "4px",
              paddingRight: "4px",
            }}
          >
            <p
              style={{
                fontFamily: '"Montserrat", sans-serif',
                margin: "0px",
                fontSize: isMobile ? "0.6rem" : "0.8rem",
              }}
            >
              {" "}
              {raceTitleSaved}
            </p>
            <p
              style={{
                fontFamily: '"Montserrat", sans-serif',
                margin: "0px",
                fontSize: isMobile ? "0.6rem" : "0.8rem",
              }}
            >
              {" "}
              {restOfDetailsSaved}
            </p>
          </Flex>
        </>
      ) : (
        <>
          <Flex
            justify="space-between"
            style={{
              marginBottom: "12px",
              paddingLeft: "4px",
              paddingRight: "4px",
            }}
          >
            <p
              style={{
                fontFamily: '"Montserrat", sans-serif',
                margin: "0px",
                fontSize: "0.8rem",
              }}
            >
              {" "}
              {raceTitle}
            </p>
            <p
              style={{
                fontFamily: '"Montserrat", sans-serif',
                margin: "0px",
                fontSize: "0.8rem",
              }}
            >
              {" "}
              {restOfDetails}
            </p>
          </Flex>
        </>
      )}

      <div>
        <Flex align="center" justify="center" vertical>
          {isLoading ? (
            <>
              <Spin spinning={true} size="large"></Spin>{" "}
            </>
          ) : (
            <>
              {isSchedule ? (
                <>
                  {showCube ? (
                    <div style={{ marginTop: "50px" }}>
                      <CubeComponent
                        keyHorse={keyHorse}
                        openMysteryBox={openMysteryBox}
                        closeMysteryBox={closeMysteryBox}
                        newTab={newTab}
                        firstTabChange={firstTabChange}
                      />
                      {isVisible && (
                        <motion.div
                          initial={{ opacity: 0, y: -50 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -50 }}
                          transition={{ duration: 0.5 }}
                        >
                          <Card
                            title={horseName}
                            style={{ textAlign: "center", marginTop: "70px" }}
                          >
                            <p>
                              {t("jockey")}: {jockeyName}
                            </p>
                            <p>
                              {t("trainer")}: {trainerName}
                            </p>
                            <p>
                              {t("rating")}: {currentRating}
                            </p>
                            <p>
                              {t("confidence")}: {confidenceIndex}
                            </p>
                          </Card>
                        </motion.div>
                      )}
                    </div>
                  ) : (
                    <LockedContent />
                  )}
                </>
              ) : (
                <>
                  <ResultsNA />
                </>
              )}
            </>
          )}
        </Flex>
      </div>
    </div>
  );
};
