import React, { useEffect, useState } from "react";
import { LockFilled, LockOutlined } from "@ant-design/icons";
import MainLayout from "../Layout/MainLayout";
import { Flex } from "antd";
import { Link } from "react-router-dom";

const LockedPage: React.FC = () => {
  return (
    <div>
      <MainLayout Component={PageDisplay} />
    </div>
  );
};

export default LockedPage;

const PageDisplay: React.FC = () => {
  return (
    <div>
      <Flex
        align="center"
        justify="center"
        vertical
        style={{ marginTop: "10%" }}
      >
        {" "}
        <LockFilled style={{ fontSize: "900%" }} />
        <h4 style={{ display: "inline", width: "60%", textAlign: "center" }}>
          Become a UPICK member to enjoy unlimited access to our premium content
          and exclusive features.
          <Link to="/plans/pricing-and-plans"> Subscribe </Link> to upick to see
          more
        </h4>
      </Flex>
    </div>
  );
};
