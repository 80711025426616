import React, { useState, useEffect } from "react";
import { Table, Typography, Button, Form, Input, Select, message } from "antd";

const { Title } = Typography;
const { Option } = Select;

interface Author {
  id: string;
  firstName: string;
  lastName: string;
  age: number;
  email: string | null;
  phone: string | null;
  role: string;
  subscribed: string;
  subscriptionType: string;
}

const AdminAuthors: React.FC = () => {
  // State to store the data
  const [data, setData] = useState<Author[]>([]);

  // State to manage the form values
  const [form] = Form.useForm();

  // Simulating fetching data from an API
  useEffect(() => {
    // Mocked data, similar to API response
    const fetchData: Author[] = [
      {
        id: "1",
        firstName: "Michael",
        lastName: "Scott",
        age: 40,
        email: "MichealScott@DunderM.com",
        phone: "+332412131",
        role: "Author",
        subscribed: "Yes",
        subscriptionType: "Monthly",
      },
      {
        id: "2",
        firstName: "Jim",
        lastName: "Halpert",
        age: 35,
        email: "JimHalpert@DunderM.com",
        phone: null,
        role: "Author",
        subscribed: "Yes",
        subscriptionType: "Yearly",
      },
      {
        id: "3",
        firstName: "Pam",
        lastName: "Beesly",
        age: 33,
        email: null,
        phone: "+3312345678",
        role: "Author",
        subscribed: "No",
        subscriptionType: "N/A",
      },
      {
        id: "4",
        firstName: "Dwight",
        lastName: "Schrute",
        age: 39,
        email: "DwightSchrute@DunderM.com",
        phone: "+3312345679",
        role: "Author",
        subscribed: "Yes",
        subscriptionType: "Monthly",
      },
      {
        id: "5",
        firstName: "Angela",
        lastName: "Martin",
        age: 38,
        email: "AngelaMartin@DunderM.com",
        phone: null,
        role: "Author",
        subscribed: "Yes",
        subscriptionType: "Weekly",
      },
      {
        id: "6",
        firstName: "Kevin",
        lastName: "Malone",
        age: 42,
        email: null,
        phone: "+3312345680",
        role: "Author",
        subscribed: "No",
        subscriptionType: "N/A",
      },
    ];

    // Set the fetched data to the state
    setData(fetchData);
  }, []);

  // Handle form submission to add a new author
  const onFinish = (values: Author) => {
    const newAuthor = {
      ...values,
      id: (data.length + 1).toString(), // Generate a new ID
    };
    setData([...data, newAuthor]);
    message.success("Author added successfully!");
    form.resetFields();
  };

  // Define columns for the table
  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
    },
    {
      title: "Subscribed?",
      dataIndex: "subscribed",
      key: "subscribed",
    },
    {
      title: "Subscription Type",
      dataIndex: "subscriptionType",
      key: "subscriptionType",
    },
    {
      title: "Change Role",
      key: "changeRole",
      render: (_: any, record: Author) => (
        <Button
          onClick={() =>
            console.log(
              `Change role for ${record.firstName} ${record.lastName}`
            )
          }
        >
          Change Role
        </Button>
      ),
    },
  ];

  return (
    <div>
      <Title level={4} style={{ textAlign: "left" }}>
        UPICK AUTHORS
      </Title>
      <Table columns={columns} dataSource={data} rowKey="id" />

      {/* Add Author Form */}
      <Title level={4} style={{ marginTop: 20 }}>
        Add New Author
      </Title>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          label="First Name"
          name="firstName"
          rules={[{ required: true, message: "Please input the first name!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="lastName"
          rules={[{ required: true, message: "Please input the last name!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Age"
          name="age"
          rules={[{ required: true, message: "Please input the age!" }]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ type: "email", message: "Please input a valid email!" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Phone" name="phone">
          <Input />
        </Form.Item>
        <Form.Item
          label="Role"
          name="role"
          rules={[{ required: true, message: "Please select a role!" }]}
        >
          <Select>
            <Option value="Author">Author</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Subscribed?"
          name="subscribed"
          rules={[
            { required: true, message: "Please select subscription status!" },
          ]}
        >
          <Select>
            <Option value="Yes">Yes</Option>
            <Option value="No">No</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Subscription Type"
          name="subscriptionType"
          rules={[
            { required: true, message: "Please select a subscription type!" },
          ]}
        >
          <Select>
            <Option value="Monthly">Monthly</Option>
            <Option value="Yearly">Yearly</Option>
            <Option value="Weekly">Weekly</Option>
            <Option value="N/A">N/A</Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Add Author
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AdminAuthors;
