const formatTime = (dateString: string) => {
  const date = new Date(dateString);

  let hours = date.getHours();
  const minutes = date.getMinutes();

  return `${hours}:${minutes} `;
};

const FetchRaceInformation = (
  raceNumber: number,
  raceDayPlaceVenue: string,
  metaRaceDataDetails: any,
  language: string
) => {
  const raceDayPlace = raceDayPlaceVenue;
  const course = language === "zh" ? "距離" : "Course";
  const startTime = language === "zh" ? "開始時間:" : "Start Time:";
  const race = language === "zh" ? "比賽:" : "Race";
  let postTime = metaRaceDataDetails[raceNumber - 1]?.postTime;

  const dateObj = new Date(postTime);
  const timeStr = dateObj.toLocaleTimeString("en-GB", {
    hour12: false,
    timeZone: "Asia/Hong_Kong",
  });
  let restOfDetails;
  let raceTitle;
  if (language == "zh") {
    restOfDetails = `${
      metaRaceDataDetails[raceNumber - 1]?.raceTrack.description_ch
    } ${
      metaRaceDataDetails[raceNumber - 1]?.raceCourse.displayCode
    }, ${course} ${
      metaRaceDataDetails[raceNumber - 1]?.distance
    }M ${startTime} ${timeStr}`;

    raceTitle = `${race} ${metaRaceDataDetails[raceNumber - 1]?.no} - ${
      metaRaceDataDetails[raceNumber - 1]?.raceName_ch
    }`;
  } else {
    restOfDetails = `${
      metaRaceDataDetails[raceNumber - 1]?.raceTrack.description_en
    } ${
      metaRaceDataDetails[raceNumber - 1]?.raceCourse.displayCode
    }, ${course} ${
      metaRaceDataDetails[raceNumber - 1]?.distance
    }M ${startTime} ${timeStr} `;

    raceTitle = `${race} ${metaRaceDataDetails[raceNumber - 1]?.no} - ${
      metaRaceDataDetails[raceNumber - 1]?.raceName_en
    }`;
  }

  console.log(
    metaRaceDataDetails[raceNumber - 1]?.no,
    raceDayPlace,
    restOfDetails
  );
  return [raceTitle, raceDayPlace, restOfDetails];
};

export default FetchRaceInformation;
