import { LockFilled } from "@ant-design/icons";
import { Card, Flex } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { RootState } from "../../store";

const LockedContent = () => {
  const currentLanguage = useSelector((state: RootState) => state.language.lng);
  return (
    <div>
      <Card style={{ marginTop: "20px" }}>
        <Flex align="center" justify="center" vertical>
          {" "}
          <LockFilled style={{ fontSize: "800%", opacity: "50%" }} />
          {currentLanguage === "zh" ? (
            <h4
              style={{ display: "inline", width: "60%", textAlign: "center" }}
            >
              <Link to="/plans/pricing-and-plans">訂閱</Link>成為UPICK會員,
              享受獨家數據分析及即時提示
            </h4>
          ) : (
            <h4
              style={{ display: "inline", width: "60%", textAlign: "center" }}
            >
              Become a UPICK member to enjoy unlimited access to our premium
              content and exclusive features.
              <Link to="/plans/pricing-and-plans"> Subscribe </Link> to upick to
              see more
            </h4>
          )}
        </Flex>
      </Card>
    </div>
  );
};

export default LockedContent;
