// src/state/language/languageSlice.js
import { createSlice } from "@reduxjs/toolkit";

const topNavSlice = createSlice({
  name: "topNav",
  initialState: {
    current: "", // Default language
  },
  reducers: {
    switchTopNav: (state, action) => {
      state.current = action.payload;
    },
  },
});

export const { switchTopNav } = topNavSlice.actions;
export default topNavSlice.reducer;
