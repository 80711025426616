import React, { useEffect, useRef, useState } from "react";
import MainLayout from "../Layout/MainLayout";
import { Typography, Skeleton, UploadFile, Button, message } from "antd";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import axios from "axios";
import RefreshAccessToken from "../Authentication/RefreshToken";
import { useTranslation } from "react-i18next";
const { Title, Paragraph } = Typography;

const ArticleDisplayForDraft: React.FC = () => {
  return (
    <div>
      <MainLayout Component={PageDisplay} />
    </div>
  );
};

export default ArticleDisplayForDraft;

type ArticleProps = {
  id: number;
  title: string;
  paragraph: string;
  author: {
    username: string;
    email: string;
    first_name: string;
    last_name: string;
    id: number;
  };
  embedded_link: string | null;
  article_picture: string | null;
  article_banner: string | null;
  post_date_time: string;
  status: string;
};

const PageDisplay: React.FC = () => {
  const { t } = useTranslation();
  const textContainerRef = useRef<HTMLDivElement>(null);
  const [visibleText, setVisibleText] = useState<string>("");
  const [refreshToken, setRefreshtoken] = useState<string | null>(null);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [remainingText, setRemainingText] = useState<string>("");
  const [article, setArticle] = useState<ArticleProps | null>(null);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [articleTitle, setArticleTitle] = useState<string | null>(null);
  const [articleParagraph, setArticleParagraph] = useState<string | null>(null);
  const [articleDateAndTime, setArticledateAndTime] = useState<string | null>(
    null
  );
  const [embeddedLink, setEmbeddedLink] = useState<string | null>(null);
  const [bannerPicture, setBannerPicture] = useState<UploadFile[]>([]);
  const [articlePicture, setArticlePicture] = useState<UploadFile[]>([]);
  const [loading, setLoading] = useState<boolean>(true); // Add a loading state

  const envUrl = process.env.REACT_APP_API_URL;
  const refreshAccessUrl: string = envUrl + "token/refresh/";
  const getArticleUrl = envUrl + "articles/";
  const { id } = useParams();

  useEffect(() => {
    // Check the height and split the text accordingly
    const splitText = () => {
      if (textContainerRef.current) {
        const maxHeight = 300; // Set max height for the textContainer, can be adjusted
        const container = textContainerRef.current;

        if (typeof articleParagraph === "string") {
          let currentText = articleParagraph;
          let visible = "";
          let remaining = currentText;

          // Regex to match the end of a sentence (period, exclamation mark, or question mark)
          const sentenceEndRegex = /[.!?]\s+/g;

          let match;
          let lastMatchIndex = 0;

          // Loop through all matches of sentence-ending punctuation
          while ((match = sentenceEndRegex.exec(currentText)) !== null) {
            const nextVisible = currentText.slice(
              0,
              match.index + match[0].length
            );

            // Temporarily set the container's text to the next segment
            container.innerText = nextVisible;

            if (container.scrollHeight > maxHeight) {
              // Stop when the visible text exceeds the max height
              break;
            }

            visible = nextVisible;
            lastMatchIndex = match.index + match[0].length; // Update the last match index
          }

          // If no match found within max height, find a suitable break point
          if (!visible && currentText.length > 0) {
            for (let i = 0; i < currentText.length; i++) {
              container.innerText = visible + currentText[i];
              if (container.scrollHeight >= maxHeight) {
                break;
              }
              visible = visible + currentText[i];
              remaining = currentText.substring(i + 1);
            }
            visible = visible.slice(0, -1); // Remove the last character if it's incomplete
          }

          remaining = currentText.substring(lastMatchIndex); // Remaining text after the last full sentence

          setVisibleText(visible.trim());
          setRemainingText(remaining.trim());
        }
      }
    };

    splitText();
    window.addEventListener("resize", splitText);

    return () => window.removeEventListener("resize", splitText);
  }, [article]);

  const isLoggedInRedux2 = useSelector((state: RootState) => state.auth.user);
  const isLoggedInRedux: boolean = useSelector(
    (state: RootState) => state.auth.isLoggedIn
  );

  useEffect(() => {
    setIsLoggedIn(isLoggedInRedux);

    const checkLoginAndFetchArticles = async () => {
      if (isLoggedInRedux2 && isLoggedInRedux2.user.refreshToken) {
        const refreshTokens = isLoggedInRedux2.user.refreshToken;
        setRefreshtoken(refreshTokens);
        const refreshedAccessToken = await RefreshAccessToken(refreshTokens);
        setAccessToken(refreshedAccessToken);
        if (refreshedAccessToken) {
          await GetArticleData(refreshedAccessToken);
        }
      } else {
        console.log("User is not logged in or access token is missing.");
      }
    };
    checkLoginAndFetchArticles();
  }, [isLoggedInRedux, isLoggedInRedux2]);

  const GetArticleData = async (accessToken: string) => {
    try {
      const response = await axios.get(`${getArticleUrl}${id}/`, {
        headers: { Authorization: `Bearer ${accessToken}` },
      });

      const articleData = response.data.data;
      setArticle(articleData); // Store the article data in state
      setArticleTitle(articleData?.title);
      setArticleParagraph(articleData?.paragraph);
      setArticledateAndTime(articleData?.post_date_time);
      setEmbeddedLink(articleData?.embedded_link);
      if (articleData.article_picture) {
        setArticlePicture([
          {
            uid: "-1",
            name: "article_picture.png",
            status: "done",
            url: articleData.article_picture,
          },
        ]);
      }
      if (articleData.article_banner) {
        setBannerPicture([
          {
            uid: "-1",
            name: "article_banner.png",
            status: "done",
            url: articleData.article_banner,
          },
        ]);
      }
      setLoading(false); // Data has been loaded
    } catch (error) {
      console.log("Unexpected Error During Fetching Articles:", error);
      setLoading(false); // Data loading has failed
    }
  };

  const publishArticle = async () => {
    const status = "PUBLISHED";
    const publishedUrl = envUrl + `articles/${id}/`;
    let accessToken;
    if (refreshToken !== null) {
      accessToken = await RefreshAccessToken(refreshToken);
    }
    try {
      const response = await axios.patch(
        publishedUrl,
        { status: status },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      console.log("Article published", response.data);
      message.success("Article Published");
    } catch (error) {
      console.error("Error publishing article", error);
      message.error("Something went wrong! Please try again");
    }
  };
  const archiveArticle = async () => {
    const status = "ARCHIVED";
    const publishedUrl = envUrl + `articles/${id}/`;
    let accessToken;
    if (refreshToken !== null) {
      accessToken = await RefreshAccessToken(refreshToken);
    }
    try {
      const response = await axios.patch(
        publishedUrl,
        { status: status },
        {
          headers: { Authorization: `Bearer ${accessToken}` },
        }
      );
      message.success("Article Archieved");
      console.log("Article published", response.data);
    } catch (error) {
      message.error("Something went wrong! Please try again");
      console.error("Error publishing article", error);
    }
  };

  return (
    <div>
      {loading ? (
        <>
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
          <Skeleton active />
        </>
      ) : (
        <>
          {article?.article_banner && (
            <div
              className="imageContainer"
              style={{
                display: "flex",
                width: "100%",
                marginTop: "3%",
                justifyContent: "center",
              }}
            >
              <img
                src={bannerPicture[0]?.url}
                alt="Banner"
                style={{
                  width: "100%",
                  height: "auto",
                  marginBottom: "4%",
                  borderRadius: "2px",
                }}
              />
            </div>
          )}
          <div
            className="firstLayer"
            style={{ display: "flex", flexDirection: "row", gap: "2%" }}
          >
            {embeddedLink && (
              <div className="videoContainer">
                <iframe
                  className="youtubeEmbeddedLink"
                  width="550"
                  height="350"
                  src={embeddedLink}
                  allowFullScreen
                ></iframe>
              </div>
            )}
            <div className="textContainer">
              <div className="titleContainer">
                <Title
                  level={2}
                  className="articleTitle"
                  style={{
                    textAlign: "left",
                    marginTop: "0px",
                    marginBottom: "0px",
                  }}
                >
                  {articleTitle}
                </Title>
                <p className="dateTime" style={{ paddingBottom: "2%" }}>
                  {articleDateAndTime}
                </p>
              </div>
              <div
                className="paragraphContainer"
                ref={textContainerRef}
                style={{ maxHeight: 300, overflow: "hidden" }}
              >
                <Paragraph>{visibleText}</Paragraph>
              </div>
            </div>
          </div>
          <div
            className="restTextContainer"
            style={{ width: "100%", height: "auto", marginTop: "2%" }}
          >
            <Paragraph>{remainingText}</Paragraph>
          </div>
          {article?.article_picture && (
            <div
              className="imageContainer"
              style={{
                display: "flex",
                width: "100%",
                marginTop: "3%",
                justifyContent: "center",
              }}
            >
              <img
                src={articlePicture[0].url}
                alt="Article"
                style={{ maxWidth: "700px", maxHeight: "550px" }}
              />
            </div>
          )}

          <Button.Group>
            <Button type="primary" onClick={archiveArticle}>
              {t("archive")}
            </Button>
            <Button
              type="default"
              style={{ marginLeft: "4%" }}
              onClick={publishArticle}
            >
              {t("publish")}
            </Button>
          </Button.Group>
        </>
      )}
    </div>
  );
};
