import { EllipsisOutlined, UserOutlined } from "@ant-design/icons";
import {
  Avatar,
  Dropdown,
  Menu,
  Popconfirm,
  PopconfirmProps,
  message,
} from "antd";
import "../../styles/ArticleCard.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useNavigate } from "react-router-dom";
import RefreshAccessToken from "../Authentication/RefreshToken";
import { useTranslation } from "react-i18next";

type ArticleProps = {
  id: number;
  title: string | null;
  paragraph: string | null;
  author: {
    username: string;
    email: string;
    first_name: string | null;
    last_name: string | null;
    id: number;
  } | null;
  embedded_link: string | null;
  article_picture: string | null;
  article_banner: string | null;
  post_date_time: string | null;
  status: string;
};

const ArticleCardAuthor = ({
  id,
  title,
  paragraph,
  author,
  post_date_time,
  status,
}: ArticleProps) => {
  const [hovered, setHovered] = useState(false);
  const { t } = useTranslation();
  // Handle potential null values for author and post_date_time
  const firstName = author?.first_name ?? "";
  const lastName = author?.last_name ?? "";
  const date = post_date_time ? new Date(post_date_time) : new Date();
  const navigate = useNavigate();

  // Format the date and time
  const formattedDateTime = date.toLocaleString();

  // Define the maximum length for the title and paragraph
  const maxTitleLength = 20; // Adjust as needed
  const maxParagraphLength = 100; // Adjust as needed

  const envUrl = process.env.REACT_APP_API_URL;
  const deleteArticleUrl: string = envUrl + "articles/";
  const [accessToken, setAccessToken] = useState<string | null>("");

  const isLoggedInRedux2 = useSelector((state: RootState) => state.auth.user);
  const isLoggedInRedux: boolean = useSelector(
    (state: RootState) => state.auth.isLoggedIn
  );

  useEffect(() => {
    const checkLoginAndFetchArticles = async () => {
      if (isLoggedInRedux2 && isLoggedInRedux2.user.refreshToken) {
        const refreshToken = isLoggedInRedux2.user.refreshToken;
        const accessToken = await RefreshAccessToken(refreshToken);
        setAccessToken(accessToken);
      } else {
        console.log("User is not logged in");
      }
    };

    checkLoginAndFetchArticles();
  }, [isLoggedInRedux, isLoggedInRedux2]);

  const DeleteArticle = async () => {
    try {
      const response = await axios.delete(`${deleteArticleUrl}${id}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(response.data);
    } catch (error) {
      console.log("Unexpected Error During Refresh:", error);
      return null;
    }
  };

  const truncateText = (text: string | null, maxLength: number): string => {
    if (!text) return ""; // Return empty string if text is null
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + "...";
  };

  const confirmDelete: PopconfirmProps["onConfirm"] = (e) => {
    e?.stopPropagation(); // Prevent propagation
    DeleteArticle();
    message.success("Article Deleted Successfully");
    navigate(`/`);
  };
  const confirmEdit: PopconfirmProps["onConfirm"] = (e) => {
    e?.stopPropagation(); // Prevent propagation
    navigate(`/edit-article/${id}`);
  };

  const cancel: PopconfirmProps["onCancel"] = (e) => {
    e?.stopPropagation(); // Prevent propagation
    message.error("Click on No");
  };

  const menu = (
    <Menu onClick={(e) => e.domEvent.stopPropagation()}>
      <Menu.Item key="edit">
        <Popconfirm
          title={t("edit_the_article")}
          description={t("are_you_sure_edit")}
          onConfirm={confirmEdit}
          onCancel={cancel}
          okText={t("yes")}
          cancelText={t("no")}
        >
          {t("edit")}
        </Popconfirm>
      </Menu.Item>
      <Menu.Item key="delete">
        <Popconfirm
          title={t("delete_the_article")}
          description={t("are_you_sure_delete")}
          onConfirm={confirmDelete}
          onCancel={cancel}
          okText={t("yes")}
          cancelText={t("no")}
        >
          {t("delete")}
        </Popconfirm>
      </Menu.Item>
    </Menu>
  );

  const handleArticleClick = () => {
    if (status === "DRAFT") {
      navigate(`/writer-insights-draft/${id}`);
    } else if (status === "PUBLISHED") {
      navigate(`/writer-insights-published/${id}`);
    } else {
      navigate(`/writer-insights-archived/${id}`);
    }
  };

  return (
    <div>
      <div
        className="mainArticleContainer"
        style={{ position: "relative" }}
        onClick={handleArticleClick}
      >
        <Dropdown overlay={menu} trigger={["hover"]}>
          <EllipsisOutlined
            style={{
              position: "absolute",
              top: 0,
              right: 0,
              fontSize: "20px",
              cursor: "pointer",
              marginRight: "4%",
              marginTop: "2%",
              color: hovered ? "#1890ff" : "inherit",
              transform: hovered ? "scale(1.3)" : "scale(1)",
              transition: "transform 0.2s, color 0.2s",
            }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          />
        </Dropdown>
        <div
          className="profilePicture"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Avatar size={64} icon={<UserOutlined />} />
          <p style={{ textAlign: "center", fontSize: "12px", maxWidth: "90%" }}>
            {firstName} {lastName}
          </p>
        </div>
        <div className="articleInfo">
          <h3 className="shortArticleTitle">
            {truncateText(title, maxTitleLength)}
          </h3>
          <p className="dateTime">{formattedDateTime}</p>
          <p className="shortArticleParagraph">
            {truncateText(paragraph, maxParagraphLength)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ArticleCardAuthor;
