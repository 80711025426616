import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import type { FormProps } from "antd";
import { useDispatch } from "react-redux";
import { Button, Form, Input, Row, Col, Typography, message } from "antd";
import MainLayout from "../Layout/MainLayout";
import axios from "axios";
import { LockOutlined } from "@ant-design/icons";

const { Title } = Typography;

type FieldType = {
  newPassword?: string;
  confirmPassword?: string;
};

const boxStyle: React.CSSProperties = {
  width: "100%",
  maxWidth: "400px",
  margin: "0 auto",
  boxShadow: "0 0 8px rgba(0, 0, 0, 0.4)",
  padding: "20px",
};

const innerStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  padding: "10px",
};

const ForgetPassword: React.FC = () => {
  return (
    <div>
      <MainLayout Component={PageDisplay} />
    </div>
  );
};

export default ForgetPassword;

const PageDisplay: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const { token, otp } = useParams();

  const envUrl = process.env.REACT_APP_API_URL;
  const forgetPasswordUrl = envUrl + "password-change/";

  const changePassword = async () => {
    const payload = {
      uid: token,
      otp: otp,
      new_password: password,
    };
    try {
      await axios.post(forgetPasswordUrl, payload);
      message.success("Password changed successfully");
      navigate("/login");
    } catch (error) {
      message.error("Something went wrong! Please try again.");
      console.log(error);
    }
  };

  const onFinish: FormProps<FieldType>["onFinish"] = async () => {
    setLoading(true);
    await changePassword();
  };

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (
    errorInfo
  ) => {
    console.log("Failed:", errorInfo);
  };

  const validatePasswords = (_: any, value: string) => {
    if (form.getFieldValue("password") === value) {
      return Promise.resolve();
    }
    return Promise.reject("Passwords do not match!");
  };

  return (
    <Row justify="center" align="middle" style={{ minHeight: "60vh" }}>
      <Col xs={30} sm={16} md={12} lg={8} xl={6}>
        <div style={boxStyle}>
          <div style={innerStyle}>
            <Title level={3}>New Password</Title>
            <Form
              form={form}
              name="basic"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout="vertical"
            >
              <Form.Item
                name="password"
                label="New Password"
                rules={[
                  { required: true, message: "Please input your password!" },
                  {
                    validator: (_, value) =>
                      value && value.length > 4
                        ? Promise.resolve()
                        : Promise.reject(
                            "Password must be at least 5 characters long"
                          ),
                  },
                ]}
                hasFeedback
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Password"
                  size="large"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Form.Item>

              <Form.Item
                name="confirmPassword"
                label="Confirm Password"
                dependencies={[{ password }]}
                hasFeedback
                rules={[
                  { required: true, message: "Please confirm your password!" },
                  { validator: validatePasswords },
                ]}
              >
                <Input.Password
                  prefix={<LockOutlined />}
                  placeholder="Confirm Password"
                  size="large"
                />
              </Form.Item>

              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  loading={loading}
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Col>
    </Row>
  );
};
