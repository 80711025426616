import React, { useEffect, useState } from "react";
import MainLayout from "../Layout/MainLayout";
import { Flex, Spin, Table } from "antd";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import "../../styles/Quinella.css";
import FetchRaceInformation from "../Mapper/raceInformationMapper";
import fetchAllMetaDetailsOdds from "../CommonApiCalls/metaDataRaceInformation";
import allOdds from "../CommonApiCalls/allOdds";
import { useTranslation } from "react-i18next";
import TitleCard from "./Responsive Title/TitleCard";
import Segment from "./Responsive Title/Segment";
import TitleCardDesktop from "./Responsive Title/TitleCardDesktop";
import QwinTable from "./QwinTable";
import QplaceTable from "./QplaceTable";

type OddsData = {
  combString: string;
  oddsValue: string;
  hotFavourite: boolean;
  oddsDropValue: number;
  bankerOdds: any[];
};

type OddsCategory = {
  last_updated: string;
  data: OddsData[];
};

type Race = {
  "race number": number;
  odds: {
    win: OddsCategory;
    place: OddsCategory;
    qwin: OddsCategory;
    qplace: OddsCategory;
    forecast: OddsCategory;
    trio: OddsCategory;
  };
};

const Quinella = () => {
  return (
    <div>
      <MainLayout Component={QuinellaDisplay} />
    </div>
  );
};

export default Quinella;

interface HorseData {
  "Horse number": string;
  "Horse name": string;
  "Equipped with": string;
  file: string;
  jockey: string;
  "Negative pounds": string;
  "Addition and subtraction": string;
  Trainer: string;
  Score: string;
  "Addition and subtraction2": string;
  Year: string;
  "Ranked Weight": string;
  "Addition and subtraction3": string;
}

const QuinellaDisplay: React.FC = () => {
  const [raceNumber, setRaceNumber] = useState<number>(1);
  const [maxRaceNumber, setMaxRaceNumber] = useState<number>(1);
  const [totalRowsColumns, setTotalRowsColumns] = useState<number>(1);
  const { t } = useTranslation();
  const [selectedRace, setSelectedRace] = useState<OddsData[]>([]);
  const [qwinOdds, setQwinOdds] = useState<any[]>([]);
  const [qplaceOdds, setQplaceOdds] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [raceTitle, setRaceTitle] = useState("");
  const [raceDayPlace, setRaceDayPlace] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [restOfDetails, setRestOfDetails] = useState("");
  const currentLanguage: string = useSelector(
    (state: RootState) => state.language.lng
  );

  const [raceData, setRaceData] = useState<any[]>([]);
  const [totalNumberOfHorses, setTotalNumberOfHorses] = useState<number>(0);

  const fetchRaceData = async (
    raceNumber: number,
    allRaceData: any,
    metaRaceData: any
  ) => {
    // Check if allRaceData and allRaceData.odds are defined
    if (!allRaceData || !allRaceData.odds) {
      console.error("Race data is not available");
      setSelectedRace([]); // Optionally clear selected race data
      return;
    }
    const race = allRaceData.odds.find(
      (r: Race) => r["race number"] === raceNumber
    );
    console.log(metaRaceData);
    const totalRaces = Object.keys(metaRaceData).length;
    setMaxRaceNumber(totalRaces);
    const tableDataQwin = race.odds.qwin.data;
    const tableDataQPlace = race.odds.qplace.data;
    const Qwin = [
      ...tableDataQwin.map((entry: any) => ({ ...entry, type: "Qwin" })),
    ];
    const Qplace = [
      ...tableDataQPlace.map((entry: any) => ({ ...entry, type: "Qplace" })),
    ];
    const combinedData = [
      ...tableDataQwin.map((entry: any) => ({ ...entry, type: "Qwin" })),
      ...tableDataQPlace.map((entry: any) => ({ ...entry, type: "Qplace" })),
    ];

    setSelectedRace(combinedData);
    console.log(Qwin);
    setQwinOdds(Qwin);
    setQplaceOdds(Qplace);
  };

  const fetchedMetaData = (raceNumber: number, metaRaceData: any) => {
    setTotalNumberOfHorses(metaRaceData[raceNumber - 1].runners.length);
    setTotalRowsColumns(metaRaceData[raceNumber - 1].runners.length);
  };

  const fetchRaceInformation = (
    metaRaceData: any,
    metaRaceDataDetailsInfo: any
  ) => {
    const raceDayPlaceVenue = Object.keys(metaRaceDataDetailsInfo)
      .filter((key) => !isNaN(key)) // Exclude any non-numeric keys (e.g., "_persist")
      .sort((a, b) => a - b) // Sort the numeric keys in ascending order
      .map((key) => metaRaceDataDetailsInfo[key]) // Map the keys to their corresponding values
      .join(""); // Join them into a string
    console.log(raceDayPlaceVenue);

    const [raceTitle, raceDayPlace, restOfDetails] = FetchRaceInformation(
      raceNumber,
      raceDayPlaceVenue,
      metaRaceData,
      currentLanguage
    );
    setRaceTitle(raceTitle);
    setRaceDayPlace(raceDayPlace);
    setRestOfDetails(restOfDetails);
  };

  const fetchMetaRaceDataDetails = async () => {
    const [metaRaceData, metaRaceDataInfo] = await fetchAllMetaDetailsOdds(
      currentLanguage
    );
    return [metaRaceData, metaRaceDataInfo];
  };

  const fetchAllOdds = async () => {
    let oddsData: any = await allOdds(); // Await the result of allOdds
    if (oddsData) {
      console.log(oddsData);
      return oddsData;
    } else {
      console.error("Failed to fetch odds data or oddsData is null");
    }
  };

  const fetchedMetaDataDetails = (
    raceNumber: number,
    metaRaceDataDetails: any
  ) => {
    if (metaRaceDataDetails[raceNumber - 1]) {
      const runners = metaRaceDataDetails[raceNumber - 1].runners;
      let horseDetails;
      if (Array.isArray(runners)) {
        horseDetails = runners
          .filter((runner) => runner.barrierDrawNumber !== "")
          .map((runner, index) => ({
            horseNumber: index + 1,
            color: runner.horse.code,
            horseName:
              currentLanguage === "zh" ? runner.name_ch : runner.name_en,
            last6Run: runner.last6run,
            weight: runner.handicapWeight,
            currentWeight: runner.currentWeight,
            jockey:
              currentLanguage === "zh"
                ? runner.jockey?.name_ch || "N/A"
                : runner.jockey?.name_en || "N/A",
            draw: runner.barrierDrawNumber,
            trainer:
              currentLanguage === "zh"
                ? runner.trainer?.name_ch || "N/A"
                : runner.trainer?.name_en || "N/A",
            rating: runner.currentRating,
            gear: runner.gearInfo,
          }));
        console.log(horseDetails);
        setRaceData(horseDetails);
        return horseDetails;
      }
    }
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const [metaRaceData, metaRaceDataInfo] = await fetchMetaRaceDataDetails(); // Call your function to fetch and set metaRaceDataDetails
      const allRaceData = await fetchAllOdds();
      console.log(metaRaceData && allRaceData);
      if (metaRaceData && allRaceData) {
        fetchedMetaDataDetails(raceNumber, metaRaceData);
        fetchedMetaData(raceNumber, metaRaceData);
        fetchRaceData(raceNumber, allRaceData, metaRaceData);
        fetchRaceInformation(metaRaceData, metaRaceDataInfo);
      }
      setLoading(false);
    };

    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
    }, 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, [raceNumber, currentLanguage]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const onChange = (key: string) => {
    setRaceNumber(parseInt(key, 10));
  };

  const total = totalRowsColumns;

  const desktopColumns = [
    {
      title: (
        <>
          Qwin
          <br />
          <p
            style={{
              color: "lightGrey",
              padding: "0px",
              margin: "0px",
            }}
          >
            Qplace
          </p>
        </>
      ),
      dataIndex: "number",
      key: "number",
    },
    ...Array.from({ length: totalRowsColumns }, (_, i) => ({
      title: `${i + 1}`,
      dataIndex: `${i + 1}`,
      key: `${i + 1}`,
      render: (text: string) => (
        <div className="odds-cell">
          {text.split(" / ").map((part, index) => (
            <div
              key={index}
              className={part.startsWith("Qplace") ? "qplace" : ""}
            >
              <p
                style={{
                  color: index === 1 ? "lightgray" : "black",
                  padding: "0px",
                  margin: "0px",
                }}
              >
                {part}
              </p>
            </div>
          ))}
        </div>
      ),
    })),
  ];

  const horseInfoColumns = [
    {
      title: t("no."),
      dataIndex: "horseNumber",
      key: "Horse number",
    },

    {
      title: t("horse_name"),
      dataIndex: "horseName",
      key: "Horse name",
    },
  ];

  const horseInfoColumnsMobile = [
    {
      title: t("no."),
      dataIndex: "horseNumber",
      key: "Horse number",
      render: (text: string) => (
        <span style={{ fontSize: "0.5rem", padding: "0px" }}>{text}</span>
      ),
    },

    {
      title: t("horse_name"),
      dataIndex: "horseName",
      key: "Horse name",
      render: (text: string) => (
        <span style={{ fontSize: "0.5rem", padding: "0px" }}>{text}</span>
      ),
    },
  ];
  // Initialize table data
  const data = Array.from({ length: total }, (_, index) => ({
    key: index + 1, // Unique key for each row
    number: index + 1, // Values from 1 to total for the first column
    ...Array.from({ length: totalRowsColumns }, (_, colIndex) => ({
      [`${colIndex + 1}`]: "", // Initialize all other columns with empty strings
    })).reduce((acc, curr) => ({ ...acc, ...curr }), {}), // Merge the column values into the row object
  }));

  // Populate table data with odds values
  selectedRace.forEach((item: OddsData) => {
    const [row, col] = item.combString.split(",").map(Number);
    if (data[row - 1]) {
      const cellContent =
        item.type === "Qwin" ? `${item.oddsValue}` : `${item.oddsValue}`;
      data[row - 1][col] = data[row - 1][col]
        ? `${data[row - 1][col]} / ${cellContent}`
        : cellContent; // Combine odds values
    }
  });

  // Tabs configuration
  const items = Array.from({ length: maxRaceNumber }, (_, i) => ({
    key: (i + 1).toString(),
    label: `${t("race")} ${i + 1}`,
    children: "",
  }));

  const splitDataSource = (dataSource: any[], splitCount: number) => {
    const chunkSize = Math.ceil(dataSource.length / splitCount);
    const splitData = [];
    for (let i = 0; i < dataSource.length; i += chunkSize) {
      splitData.push(dataSource.slice(i, i + chunkSize));
    }
    return splitData;
  };

  const [day, date, venue] = raceDayPlace.split(",").map((item) => item.trim());

  return (
    <div className="dynamicTable">
      {isMobile && (
        <>
          <Segment value="quinella" />
        </>
      )}{" "}
      {isMobile ? (
        <TitleCard
          title={t("quinella")}
          date={date}
          venue={venue}
          day={day}
          maxRaceNumber={maxRaceNumber}
          onChange={onChange}
        />
      ) : (
        <>
          <TitleCardDesktop
            title={t("quinella")}
            date={date}
            venue={venue}
            day={day}
            maxRaceNumber={maxRaceNumber}
            onChange={onChange}
          />
        </>
      )}
      {loading ? (
        <Spin tip="Loading" size="large">
          <Table
            dataSource={data}
            tableLayout="auto"
            columns={desktopColumns}
            size="small"
            pagination={false}
            rowClassName={(_, index) =>
              index % 2 === 0 ? "row-even" : "row-odd"
            }
          />
        </Spin>
      ) : (
        <>
          {isMobile ? (
            <>
              {" "}
              <Flex gap={5} style={{ marginBottom: "12px" }}>
                {" "}
                <p
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    margin: "0px",
                    fontSize: "0.7rem",
                    paddingLeft: "4px",
                  }}
                >
                  {raceTitle}
                </p>
                <p
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    margin: "0px",
                    fontSize: "0.7rem",
                    textAlign: "right",
                    paddingRight: "4px",
                  }}
                >
                  {restOfDetails}
                </p>
              </Flex>{" "}
              <Flex justify="space-between" style={{ width: "100%" }}>
                {" "}
                <Table
                  className="quinella-horse-info"
                  dataSource={raceData}
                  scroll={{ x: "max-content" }}
                  columns={isMobile ? horseInfoColumnsMobile : horseInfoColumns} // Use mobile or desktop columns based on screen size
                  size="small"
                  style={{ width: "23%" }}
                  pagination={false}
                  rowClassName={(_, index) =>
                    index % 2 === 0 ? "row-even" : "row-odd"
                  }
                />
                <Flex gap={12} vertical style={{ width: "72%" }}>
                  {" "}
                  <QwinTable
                    qwinOdds={qwinOdds}
                    horseInfoColumns={horseInfoColumns}
                    widthStyle={"100%"}
                    isMobile={isMobile}
                  />
                  <QplaceTable
                    qplaceOdds={qplaceOdds}
                    horseInfoColumns={horseInfoColumns}
                    widthStyle={"100%"}
                    isMobile={isMobile}
                  />
                </Flex>
              </Flex>
            </>
          ) : (
            <>
              <Flex
                justify="space-between"
                style={{
                  marginBottom: "12px",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                }}
              >
                <p
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    margin: "0px",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {raceTitle}
                </p>
                <p
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    margin: "0px",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {restOfDetails}
                </p>
              </Flex>
              {/* {renderTables()} */}
              <Flex justify="space-between" style={{ width: "100%" }}>
                {" "}
                <Table
                  className="desktop-trio-table"
                  dataSource={raceData}
                  columns={horseInfoColumns} // Use mobile or desktop columns based on screen size
                  size="small"
                  style={{ fontSize: "0.7rem", width: "20%" }}
                  pagination={false}
                  rowClassName={(_, index) =>
                    index % 2 === 0 ? "row-even" : "row-odd"
                  }
                />
                <Flex gap={100} vertical style={{ width: "75%" }}>
                  {" "}
                  <QwinTable
                    qwinOdds={qwinOdds}
                    horseInfoColumns={horseInfoColumns}
                    widthStyle={"100%"}
                    isMobile={isMobile}
                  />
                  <QplaceTable
                    qplaceOdds={qplaceOdds}
                    horseInfoColumns={horseInfoColumns}
                    widthStyle={"100%"}
                    isMobile={isMobile}
                  />
                </Flex>
              </Flex>
            </>
          )}
        </>
      )}
    </div>
  );
};
