import React, { useEffect, useState } from "react";
import MainLayout from "../Layout/MainLayout";
import { Flex, Spin, Table, Tabs } from "antd";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import FetchRaceInformation from "../Mapper/raceInformationMapper";
import fetchAllMetaDetailsOdds from "../CommonApiCalls/metaDataRaceInformation";
import allOdds from "../CommonApiCalls/allOdds";
import { useTranslation } from "react-i18next";
import TitleCard from "./Responsive Title/TitleCard";
import Segment from "./Responsive Title/Segment";
import TitleCardDesktop from "./Responsive Title/TitleCardDesktop";

type OddsData = {
  combString: string;
  oddsValue: string;
  hotFavourite: boolean;
  oddsDropValue: number;
  bankerOdds: any[];
};

type OddsCategory = {
  last_updated: string;
  data: OddsData[];
};

type Race = {
  "race number": number;
  odds: {
    win: OddsCategory;
    place: OddsCategory;
    qwin: OddsCategory;
    qplace: OddsCategory;
    forecast: OddsCategory;
    trio: OddsCategory;
  };
};

const Forecast = () => {
  return (
    <div>
      <MainLayout Component={ForecastDisplay} />
    </div>
  );
};

export default Forecast;

const ForecastDisplay: React.FC = () => {
  const [raceNumber, setRaceNumber] = useState<number>(1);
  const [maxRaceNumber, setMaxRaceNumber] = useState<number>(1);
  const [totalRowsColumns, setTotalRowsColumns] = useState<number>(1);
  const { t } = useTranslation();
  const [raceTitle, setRaceTitle] = useState("");
  const [raceDayPlace, setRaceDayPlace] = useState("");
  const [restOfDetails, setRestOfDetails] = useState("");
  const [isMobile, setIsMobile] = useState(false);

  const currentLanguage: string = useSelector(
    (state: RootState) => state.language.lng
  );
  const [selectedRace, setSelectedRace] = useState<OddsData[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [raceData, setRaceData] = useState<any[]>([]);
  const [totalNumberOfHorses, setTotalNumberOfHorses] = useState<number>(0);

  const fetchRaceData = async (
    raceNumber: number,
    allRaceData: any,
    metaRaceData: any
  ) => {
    if (!allRaceData || !allRaceData.odds) {
      console.error("Race data is not available");
      setSelectedRace([]);
      return;
    }
    const race = allRaceData.odds.find(
      (r: Race) => r["race number"] === raceNumber
    );
    const totalRaces = Object.keys(metaRaceData).length;
    setMaxRaceNumber(totalRaces);
    const tableData = race.odds.forecast.data;
    console.log(tableData);
    setSelectedRace(tableData);
  };

  const fetchedMetaData = (raceNumber: number, metaRaceData: any) => {
    setTotalNumberOfHorses(metaRaceData[raceNumber - 1].runners.length);
    setTotalRowsColumns(metaRaceData[raceNumber - 1].runners.length);
  };
  const fetchRaceInformation = (
    metaRaceData: any,
    metaRaceDataDetailsInfo: any
  ) => {
    const raceDayPlaceVenue = Object.keys(metaRaceDataDetailsInfo)
      .filter((key: any) => !isNaN(key))
      .sort((a: any, b: any) => a - b)
      .map((key) => metaRaceDataDetailsInfo[key])
      .join("");
    console.log(raceDayPlaceVenue);

    const [raceTitle, raceDayPlace, restOfDetails] = FetchRaceInformation(
      raceNumber,
      raceDayPlaceVenue,
      metaRaceData,
      currentLanguage
    );
    setRaceTitle(raceTitle);
    setRaceDayPlace(raceDayPlace);
    setRestOfDetails(restOfDetails);
  };
  const fetchMetaRaceDataDetails = async () => {
    const [metaRaceData, metaRaceDataInfo] = await fetchAllMetaDetailsOdds(
      currentLanguage
    );
    return [metaRaceData, metaRaceDataInfo];
  };

  const fetchAllOdds = async () => {
    let oddsData: any = await allOdds();
    if (oddsData) {
      return oddsData;
    } else {
      console.error("Failed to fetch odds data or oddsData is null");
    }
  };

  const fetchedMetaDataDetails = (
    raceNumber: number,
    metaRaceDataDetails: any
  ) => {
    if (metaRaceDataDetails[raceNumber - 1]) {
      const runners = metaRaceDataDetails[raceNumber - 1].runners;
      let horseDetails;
      if (Array.isArray(runners)) {
        horseDetails = runners
          .filter((runner) => runner.barrierDrawNumber !== "")
          .map((runner, index) => ({
            horseNumber: index + 1,
            color: runner.horse.code,
            horseName:
              currentLanguage === "zh" ? runner.name_ch : runner.name_en,
            last6Run: runner.last6run,
            weight: runner.handicapWeight,
            currentWeight: runner.currentWeight,
            jockey:
              currentLanguage === "zh"
                ? runner.jockey?.name_ch || "N/A"
                : runner.jockey?.name_en || "N/A",
            draw: runner.barrierDrawNumber,
            trainer:
              currentLanguage === "zh"
                ? runner.trainer?.name_ch || "N/A"
                : runner.trainer?.name_en || "N/A",
            rating: runner.currentRating,
            gear: runner.gearInfo,
          }));
        console.log(horseDetails);
        setRaceData(horseDetails);
        return horseDetails;
      }
    }
  };
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const [metaRaceData, metaRaceDataInfo] = await fetchMetaRaceDataDetails(); // Call your function to fetch and set metaRaceDataDetails
      const allRaceData = await fetchAllOdds();
      console.log(metaRaceData && allRaceData);
      if (metaRaceData && allRaceData) {
        fetchedMetaDataDetails(raceNumber, metaRaceData);
        fetchedMetaData(raceNumber, metaRaceData);
        fetchRaceData(raceNumber, allRaceData, metaRaceData);
        fetchRaceInformation(metaRaceData, metaRaceDataInfo);
      }
      setLoading(false);
    };

    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
    }, 60000);

    return () => {
      clearInterval(intervalId);
    };
  }, [raceNumber, currentLanguage]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const onChange = (key: string) => {
    setRaceNumber(parseInt(key, 10));
  };

  const total = totalRowsColumns;

  const columns = [
    {
      title: (
        <p style={{ margin: "0px", padding: "0px", fontSize: "0.6rem" }}>
          1<sup style={{ fontSize: "smaller" }}>st</sup> / 2
          <sup style={{ fontSize: "smaller" }}>nd</sup>
        </p>
      ),
      dataIndex: "number",
      key: "number",
      fixed: "left",
    },
    ...Array.from({ length: totalRowsColumns }, (_, i) => ({
      title: `${i + 1}`,
      dataIndex: `${i + 1}`,
      key: `${i + 1}`,
    })),
  ];
  const mobileColumns = [
    {
      title: (
        <p
          style={{
            margin: "0px",
            padding: "0px",
            fontSize: "0.5rem",
            textAlign: "center",
          }}
        >
          1<sup style={{ fontSize: "smaller" }}>st</sup> / 2
          <sup style={{ fontSize: "smaller" }}>nd</sup>
        </p>
      ),
      dataIndex: "number",
      key: "number",
      fixed: "left",
    },
    ...Array.from({ length: totalRowsColumns }, (_, i) => ({
      title: (
        <span style={{ fontSize: "0.5rem", textAlign: "center" }}>{i + 1}</span>
      ),
      dataIndex: `${i + 1}`,
      key: `${i + 1}`,
    })),
  ];

  const horseInfoColumns = [
    {
      title: t("no."),
      dataIndex: "horseNumber",
      key: "Horse number",
    },

    {
      title: t("horse_name"),
      dataIndex: "horseName",
      key: "Horse name",
    },
  ];

  const horseInfoColumnsMobile = [
    {
      title: <span style={{ fontSize: "0.5rem" }}>{t("no.")}</span>,
      dataIndex: "horseNumber",
      key: "Horse number",
      render: (text: string) => (
        <span style={{ fontSize: "0.5rem", padding: "0px" }}>{text}</span>
      ),
    },

    {
      title: <span style={{ fontSize: "0.5rem" }}>{t("horse_name")}</span>,
      dataIndex: "horseName",
      key: "Horse name",
      render: (text: string) => (
        <span style={{ fontSize: "0.5rem", padding: "0px" }}>{text}</span>
      ),
    },
  ];

  const data = Array.from({ length: total }, (_, index) => ({
    key: index + 1,
    number: (
      <p
        style={{
          margin: "0px",
          padding: "0px",
          textAlign: "center",
          background: index % 2 === 0 ? "#fff" : "rgb(234, 247, 252)",
        }}
      >
        {index + 1}
      </p>
    ),
    ...Array.from({ length: totalRowsColumns }, (_, colIndex) => ({
      [`${colIndex + 1}`]: "",
    })).reduce((acc, curr) => ({ ...acc, ...curr }), {}),
  }));

  selectedRace.forEach((item: OddsData) => {
    const [col, row] = item.combString.split(",").map(Number);
    if (data[row - 1]) {
      data[row - 1][col] = item.oddsValue;
    }
  });

  const items = Array.from({ length: maxRaceNumber }, (_, i) => ({
    key: (i + 1).toString(),
    label: `${t("race")} ${i + 1}`,
    children: "",
  }));
  const [day, date, venue] = raceDayPlace.split(",").map((item) => item.trim());

  return (
    <div className="dynamicTable">
      {isMobile && (
        <>
          <Segment value="forecast" />
        </>
      )}{" "}
      {isMobile ? (
        <TitleCard
          title={t("forecast")}
          date={date}
          venue={venue}
          day={day}
          maxRaceNumber={maxRaceNumber}
          onChange={onChange}
        />
      ) : (
        <>
          <TitleCardDesktop
            title={t("forecast")}
            date={date}
            venue={venue}
            day={day}
            maxRaceNumber={maxRaceNumber}
            onChange={onChange}
          />
        </>
      )}
      {loading ? (
        <Spin tip="Loading" size="large">
          <Table
            dataSource={data}
            columns={columns}
            tableLayout="auto"
            size="small"
            pagination={false}
            rowClassName={(_, index) =>
              index % 2 === 0 ? "row-even" : "row-odd"
            }
          />
        </Spin>
      ) : (
        <>
          {isMobile ? (
            <>
              {" "}
              <Flex gap={5} style={{ marginBottom: "12px" }}>
                {" "}
                <p
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    margin: "0px",
                    fontSize: "0.7rem",
                    paddingLeft: "4px",
                  }}
                >
                  {raceTitle}
                </p>
                <p
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    margin: "0px",
                    fontSize: "0.7rem",
                    textAlign: "right",
                    paddingRight: "4px",
                  }}
                >
                  {restOfDetails}
                </p>
              </Flex>
              <Flex gap={5} style={{ width: "100%" }}>
                <Table
                  className="desktop-trio-table"
                  dataSource={raceData}
                  columns={isMobile ? horseInfoColumnsMobile : horseInfoColumns}
                  size="small"
                  style={{ fontSize: "0.7rem", width: "100%" }}
                  pagination={false}
                  rowClassName={(_, index) =>
                    index % 2 === 0 ? "row-even" : "row-odd"
                  }
                />
                <Table
                  dataSource={data}
                  columns={mobileColumns.map((col) => ({
                    ...col,
                    render: (text: string) => {
                      return (
                        <div
                          style={{
                            textAlign: "center",
                            fontSize: "0.5rem",
                          }}
                        >
                          {text}
                        </div>
                      );
                    },
                  }))}
                  tableLayout="auto"
                  size="small"
                  pagination={false}
                  style={{ maxWidth: "62%" }}
                  scroll={{ x: "max-content" }}
                  rowClassName={(_, index) =>
                    index % 2 === 0 ? "row-even" : "row-odd"
                  }
                />
              </Flex>
            </>
          ) : (
            <>
              {" "}
              <Flex
                justify="space-between"
                style={{
                  marginBottom: "12px",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                }}
              >
                <p
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    margin: "0px",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {raceTitle}
                </p>
                <p
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    margin: "0px",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {restOfDetails}
                </p>
              </Flex>
              <Flex justify="space-between">
                <Table
                  className="desktop-trio-table"
                  dataSource={raceData}
                  columns={horseInfoColumns} // Use mobile or desktop columns based on screen size
                  size="small"
                  style={{ fontSize: "0.7rem", width: "20%" }}
                  pagination={false}
                  rowClassName={(_, index) =>
                    index % 2 === 0 ? "row-even" : "row-odd"
                  }
                />
                <Table
                  dataSource={data}
                  columns={columns}
                  tableLayout="auto"
                  size="small"
                  pagination={false}
                  style={{ width: "80%" }}
                  rowClassName={(_, index) =>
                    index % 2 === 0 ? "row-even" : "row-odd"
                  }
                />
              </Flex>
            </>
          )}
        </>
      )}
    </div>
  );
};
