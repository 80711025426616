import { ClockCircleFilled } from "@ant-design/icons";
import { Card, Flex } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const ResultsNA = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Card style={{ marginTop: "20px" }}>
        <Flex align="center" justify="center" vertical>
          {" "}
          <ClockCircleFilled style={{ fontSize: "500%", opacity: "50%" }} />
          <h4 style={{ display: "inline", width: "60%", textAlign: "center" }}>
            {t("prediction_8PM_release")}
          </h4>
        </Flex>
      </Card>
    </div>
  );
};

export default ResultsNA;
