import React, { memo, useEffect, useState } from "react";
import { Pie, PieConfig } from "@ant-design/plots";
import { Button, Card, Flex, Popconfirm, Spin, Switch, message } from "antd";
import { isEqual } from "lodash-es";
import { DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import RefreshAccessToken from "../Authentication/RefreshToken";
import { useTranslation } from "react-i18next";

// Define the types for the data prop
interface DataItem {
  type: string;
  value: number;
}

// Define the types for the DemoPie component props
interface DemoPieProps {
  data: DataItem[];
  onReady: ({ chart }: { chart: any }) => void; // Replace 'any' with the specific type if known
}

// Memoized DemoPie component
const DemoPie = memo(
  ({ data, onReady }: DemoPieProps) => {
    var config: PieConfig = {
      data,
      angleField: "value",
      colorField: "type",
      label: {
        text: "value",
        position: "outside",
      },
      onReady,
    };
    return <Pie {...config} />;
  },
  (pre, next) => {
    return isEqual(pre?.data, next?.data);
  }
);

type User = {
  id: number;
  last_login: string | null;
  is_superuser: boolean;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  is_staff: boolean;
  is_active: boolean;
  date_joined: string;
  mobile_number: string;
  is_verified: boolean;
  is_subscribed: boolean;
  verification_method: string;
  groups: [];
  user_permissions: [];
};

type AdminHomeProps = {
  authors: User[] | null; // Adjust the type as per your requirement
  isLoading: boolean;
  refreshToken: string;
  refetchAuthors: any;
  totalAuthors: number;
  totalSubscribedUsers: number;
  totalUnsubscribedUsers: number;
};

// AdminHome component
const AdminHome: React.FC<AdminHomeProps> = ({
  authors,
  isLoading,
  refreshToken,
  refetchAuthors,
  totalAuthors,
  totalSubscribedUsers,
  totalUnsubscribedUsers,
}) => {
  const [isUSD, setIsUSD] = useState(false); // State to manage currency toggle
  const [amountCNY] = useState(1); // Amount in Chinese Yuan (CNY)
  const exchangeRate = 0.14;
  const envUrl = process.env.REACT_APP_API_URL;
  const { t } = useTranslation();
  const removeAuthorUrl = envUrl + "author/remove/";
  const [data, setData] = useState<DataItem[]>([
    {
      type: "Authors",
      value: totalAuthors,
    },
    {
      type: "Subscribed Users",
      value: totalSubscribedUsers,
    },
    {
      type: "Unsubscribed Users",
      value: totalUnsubscribedUsers,
    },
  ]);

  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = () => {
      setLoading(false);
    };

    fetchData();
  }, [authors]);

  useEffect(() => {
    setData([
      {
        type: t("authors"),
        value: totalAuthors,
      },
      {
        type: t("subscribed_users"),
        value: totalSubscribedUsers,
      },
      {
        type: t("unsubscribed_users"),
        value: totalUnsubscribedUsers,
      },
    ]);
  }, [totalAuthors, totalSubscribedUsers, totalUnsubscribedUsers]);

  const convertCurrency = () => {
    return isUSD ? (amountCNY * exchangeRate).toFixed(2) : amountCNY;
  };

  const currencySymbol = () => (isUSD ? "$" : "¥");
  const removeAuthor = async (authorId: number) => {
    console.log(refreshToken);
    const accessToken = await RefreshAccessToken(refreshToken);
    console.log(accessToken);
    const payload = {
      user_id: authorId,
    };
    console.log(payload);
    axios
      .post(removeAuthorUrl, payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then(async (response) => {
        console.log("Data posted successfully:", response.data);
        setLoading(false);
        message.success("Author Removed Successfully!");
        await refetchAuthors();
      })
      .catch((error) => {
        console.error("Error posting data:", error);
        setLoading(false);
        message.error("something went wrong! try again");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const refreshAll = async () => {
    await refetchAuthors();
  };
  return (
    <div>
      <Flex
        justify="space-between"
        align="flex-start"
        style={{ marginBottom: "4%" }}
      >
        <Card size="small" title={t("total_revenue")} style={{ width: 600 }}>
          <Flex justify="flex-end">
            <Switch
              checked={isUSD}
              onChange={() => setIsUSD(!isUSD)}
              checkedChildren="USD"
              unCheckedChildren="CNY"
              style={{ marginLeft: 10 }}
            />
          </Flex>
          <Flex justify="center" align="center">
            <p style={{ fontSize: "2rem" }}>
              {currencySymbol()}
              {convertCurrency()}
            </p>
          </Flex>
        </Card>

        <Card size="small" title={t("all_authors")} style={{ width: 600 }}>
          <div style={{ maxHeight: 150, overflowY: "auto" }}>
            {isLoading ? (
              <div style={{ textAlign: "center", padding: "20px 0" }}>
                <Spin size="large" tip="Loading authors..." />
              </div>
            ) : authors && authors.length > 0 ? (
              authors.map((author, index) => (
                <div key={index} style={{ paddingBottom: 8 }}>
                  <Flex
                    justify="space-between"
                    align="center"
                    style={{ width: "100%" }}
                  >
                    {author.first_name} {author.last_name}
                    <Popconfirm
                      title="Do you want to delete this?"
                      onConfirm={() => removeAuthor(author.id)}
                      okText={t("yes")}
                      cancelText={t("no")}
                    >
                      <Button
                        type="text"
                        icon={<DeleteOutlined style={{ color: "red" }} />}
                        style={{ marginLeft: 8 }}
                      />
                    </Popconfirm>
                  </Flex>
                </div>
              ))
            ) : (
              <p>No authors available.</p>
            )}
          </div>
        </Card>
      </Flex>

      <Button style={{ margin: "0 10px" }} type="primary" onClick={refreshAll}>
        {t("refresh")}
      </Button>
      {isLoading ? (
        <div style={{ textAlign: "center", padding: "20px 0" }}>
          <Spin size="large" tip="Loading authors..." />
        </div>
      ) : (
        <DemoPie
          data={data}
          onReady={({ chart }) => {
            // You can handle the chart instance here if needed
          }}
        />
      )}
    </div>
  );
};

export default AdminHome;
