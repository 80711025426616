import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import authReducer from "./state/auth/authSlice";
import authorReducer from "./state/author/authorSlice";
import languageReducer from "./state/language/languageSlice";
import topNavReducer from "./state/topNav/topNavSlice";

// Create separate persist configurations
const authPersistConfig = {
  key: "auth",
  storage,
};

const authorPersistConfig = {
  key: "author",
  storage,
};

const languagePersistConfig = {
  key: "language",
  storage,
};

// Apply the configs individually
const persistedAuthReducer = persistReducer(authPersistConfig, authReducer);
const persistedAuthorReducer = persistReducer(
  authorPersistConfig,
  authorReducer
);
const persistedLanguageReducer = persistReducer(
  languagePersistConfig,
  languageReducer
);

export const store = configureStore({
  reducer: {
    auth: persistedAuthReducer,
    authorsList: persistedAuthorReducer,
    language: persistedLanguageReducer,
    topNav: topNavReducer,
  },
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
