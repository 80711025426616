import { Flex, Segmented } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
type segmentProps = {
  value: string;
};

const Segment = ({ value }: segmentProps) => {
  const navigate = useNavigate();
  const onChange = (value: string) => {
    navigate(`/instantBettingSuggestion/${value}`);
  };

  const { t } = useTranslation();

  return (
    <div style={{ marginBottom: "10px" }}>
      {" "}
      <Flex justify="center" style={{ width: "100%" }}>
        {" "}
        <Segmented
          options={[
            {
              label: (
                <span style={{ fontSize: "0.6rem", padding: "0px" }}>
                  {t("instant_key_horse")}
                </span>
              ),
              value: "instant_key_horse",
            },
            {
              label: (
                <span style={{ fontSize: "0.6rem", padding: "0px" }}>
                  {t("instant_analysis")}
                </span>
              ),
              value: "InstantComprehensiveDataAnalysis",
            },
            {
              label: (
                <span style={{ fontSize: "0.6rem", padding: "0px" }}>
                  {t("instant_horse_trend")}
                </span>
              ),
              value: "InstantHorseTrendRecommendation",
            },
          ]}
          value={value}
          onChange={onChange}
        />
      </Flex>
    </div>
  );
};

export default Segment;
