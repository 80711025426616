import {
  BulbOutlined,
  CreditCardOutlined,
  CrownOutlined,
  FileTextOutlined,
  FlagFilled,
  FlagOutlined,
  GlobalOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  LogoutOutlined,
  MenuOutlined,
  ThunderboltOutlined,
  TrophyOutlined,
  UserOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
import {
  Alert,
  Avatar,
  Button,
  Drawer,
  Dropdown,
  Menu,
  Switch,
  message,
  Flex,
  theme,
} from "antd";
import upickLogo from "../../pictures/upickLogo.png";
import upickLogoResized from "../../pictures/UpickBannerResized.png";

import { Header } from "antd/es/layout/layout";
import Title from "antd/es/typography/Title";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../styles/HeaderLayout.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState, store } from "../../store";
import axios from "axios";
import { logout, resetToken } from "../../state/auth/authSlice";
import { useTranslation } from "react-i18next";
import {
  selectAllAuthorsList,
  selectUser,
} from "../../state/selector/selectors";
import { switchLanguage } from "../../state/language/languageSlice";
import type { MenuProps } from "antd";
import { switchTopNav } from "../../state/topNav/topNavSlice";
type MenuItem = Required<MenuProps>["items"][number];
interface Author {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}
const HeaderLayoutPhone = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isloggedIn, setIsLoggedIn] = useState(false);
  const [isAuthor, setIsAuthor] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [visible, setVisible] = useState(false);
  const [accountNotVerified, setAccountNotVerified] = useState(false);
  const [current, setCurrent] = useState("");
  const [refreshToken, setRefreshToken] = useState<string | null>("");
  const [authorsData, setAuthorsData] = useState<Author[]>([]);
  const envUrl = process.env.REACT_APP_API_URL;
  const logoutUrl = envUrl + "logout/";
  const currentLanguage: string = useSelector(
    (state: RootState) => state.language.lng
  );
  const currentNav: string = useSelector(
    (state: RootState) => state.topNav.current
  );

  const showDrawer = () => {
    setVisible(true);
  };
  const onClick = () => {
    showDrawer();
  };
  const onClose = () => {
    setVisible(false);
  };
  const verifyTokenUrl = envUrl + "send-verification-token/";
  const items: any = [
    {
      key: "home",
      icon: <HomeOutlined />,
      label: <Link to="/">{t("home")}</Link>,
    },
    isloggedIn && {
      key: "profile",
      icon: <UserOutlined style={{ color: "black" }} />,
      label: <Link to="/profile">{t("profile")}</Link>,
    },
    isloggedIn &&
      isAdmin && {
        key: "admin",
        icon: <CrownOutlined />,
        label: <Link to="/admin/home">{t("admin")}</Link>,
      },
    {
      label: t("race_information"),
      key: "SubMenu3",
      icon: <InfoCircleOutlined />,
      children: [
        {
          type: "group",
          children: [
            {
              key: "upcomingRace",
              label: (
                <Link to="/race_information/upcoming_race">
                  {t("upcoming_race")}
                </Link>
              ),
            },
            {
              key: "WinPlace",
              label: (
                <Link to="/race_information/win_place">{t("win_place")}</Link>
              ),
            },
            {
              key: "QuinellaQuinella Place",
              label: (
                <Link to="/race_information/quinella">
                  {t("quinella/quinella_place")}
                </Link>
              ),
            },
            {
              key: "Forecast",
              label: (
                <Link to="/race_information/forecast">{t("forecast")}</Link>
              ),
            },
            {
              key: "Trio",
              label: <Link to="/race_information/trio">{t("trio")}</Link>,
            },
          ],
        },
      ],
    },
    isloggedIn && {
      label: t("articles"),
      key: "articlesByAuthor",
      icon: <FileTextOutlined />,
      children: [
        {
          type: "group",
          children: [
            {
              key: "allArticles",
              label: <Link to="/articles">{t("all_articles")}</Link>,
            },
            ...authorsData.map((author) => ({
              key: `author-${author.id}`,
              label: (
                <Link to={`/articles/${author.id}`}>
                  {author.firstName} {author.lastName}
                </Link>
              ),
            })),
          ],
        },
      ],
    },
    isAuthor &&
      isloggedIn && {
        label: t("author"),
        key: "author",
        icon: <UserOutlined />,
        children: [
          {
            type: "group",
            // label: t("articles"),
            children: [
              {
                key: "writeArticle",
                label: <Link to="/write-article">{t("write")}</Link>,
              },

              {
                key: "draftedArticles",
                label: <Link to="/draft">{t("draft")}</Link>,
              },
              {
                key: "publishedArticle",
                label: <Link to="/published">{t("published")}</Link>,
              },
              {
                key: "archievedArticles",
                label: <Link to="/archived">{t("archieved")}</Link>,
              },
            ],
          },
        ],
      },
    isloggedIn && {
      label: t("tips"),
      key: "SubMenuTips",
      icon: <BulbOutlined />,
      children: [
        {
          type: "group",
          //   label: "Tips",
          children: [
            {
              key: "statisticKing",
              label: (
                <Link to="/tips/statistics_king">{t("statistic_king")}</Link>
              ),
            },
            {
              key: "horseStrengthIndex",
              label: (
                <Link to="/tips/horse_strength_index">
                  {t("horse_strength_index")}
                </Link>
              ),
            },
            {
              key: "FinalTips",
              label: <Link to="/tips/Final_Tip">{t("final_tips")}</Link>,
            },
          ],
        },
      ],
    },
    isloggedIn && {
      label: t("instant_betting_suggestion"),
      key: "instantBettingSuggestion",
      icon: <TrophyOutlined />,
      children: [
        {
          type: "group",
          children: [
            {
              key: "instantkeyHorse",
              label: (
                <Link to="/instantBettingSuggestion/instant_key_horse">
                  {t("instant_key_horse")}
                </Link>
              ),
            },
            {
              key: "InstantAnalysis",
              label: (
                <Link to="/instantBettingSuggestion/InstantComprehensiveDataAnalysis">
                  {t("instant_analysis")}
                </Link>
              ),
            },
            {
              key: "InstantHorseTrend",
              label: (
                <Link to="/instantBettingSuggestion/InstantHorseTrendRecommendation">
                  {t("instant_horse_trend")}
                </Link>
              ),
            },
          ],
        },
      ],
    },
    {
      key: "youtube",
      icon: <YoutubeOutlined />,
      label: <Link to="/youtubeVideoLinks">{t("youTube")}</Link>,
    },
    isloggedIn && {
      key: "paymentAndPricing",
      icon: <CreditCardOutlined />,
      label: <Link to="/plans/pricing-and-plans">{t("payment")}</Link>,
    },
  ];
  const logoutUser = async () => {
    const payload = {
      refresh: refreshToken,
    };
    try {
      const response = await axios.post(logoutUrl, payload);
      console.log("Logout successful:", response.data);
      store.dispatch(logout());
      navigate("/");
      message.success(t("logout_successful"));
    } catch (error) {
      store.dispatch(resetToken());
      navigate("/");

      console.log(error);
    }
  };

  const handleLogOut = async () => {
    await logoutUser();
  };
  const languageMenu = (
    <Menu className="profileMenu">
      <Menu.Item
        key="1"
        onClick={() => onChange("zh")}
        style={{
          fontWeight: currentLanguage === "zh" ? "bold" : "normal",
          color: currentLanguage === "zh" ? "darkslategray" : "black",
        }}
      >
        {currentLanguage === "zh" ? (
          <FlagFilled style={{ color: "darkslategray", paddingRight: "3px" }} />
        ) : (
          <FlagOutlined style={{ color: "black", paddingRight: "3px" }} />
        )}
        Chinese
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => onChange("en")}
        style={{
          fontWeight: currentLanguage === "en" ? "bold" : "normal",
          color: currentLanguage === "en" ? "darkslategray" : "black",
        }}
      >
        {currentLanguage === "en" ? (
          <FlagFilled style={{ color: "darkslategray", paddingRight: "3px" }} />
        ) : (
          <FlagOutlined style={{ color: "black", paddingRight: "3px" }} />
        )}
        English
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {}, [authorsData]);
  const isLoggedInRedux: boolean = useSelector(
    (state: RootState) => state.auth.isLoggedIn
  );

  const metaRaceData = useSelector(selectUser);
  const authorDetails = useSelector(selectAllAuthorsList);
  const isLoggedInRedux2 = useSelector((state: RootState) => state.auth.user);
  const user = useSelector(selectUser);

  useEffect(() => {
    setCurrent(currentNav);
    setIsLoggedIn(isLoggedInRedux);
    if (user?.user.groups === 1) {
      setIsAuthor(true);
    }
    if (user?.user.is_superuser) {
      setIsAdmin(true);
    }
    if (isLoggedInRedux2 && isLoggedInRedux2.user.refreshToken) {
      setRefreshToken(isLoggedInRedux2.user.refreshToken);
      console.log(authorDetails.authors);
      setAuthorsData(authorDetails.authors);
      isVerified(metaRaceData?.user.is_verified);
    } else {
    }
  }, [isLoggedInRedux, isLoggedInRedux2]);

  const dispatch = useDispatch();

  const onChange = (language: string) => {
    dispatch(switchLanguage(language));
  };

  const isVerified = async (verificationStatus: boolean) => {
    if (verificationStatus) {
      setAccountNotVerified(false);
    } else {
      setAccountNotVerified(true);
    }
  };
  const handleVerifyClick = async () => {
    console.log("Redirecting to verification...");
    const userName = metaRaceData?.user.username;
    const payload = {
      username: userName,
    };
    console.log("flow here");
    console.log(payload);
    try {
      const response = await axios.post(verifyTokenUrl, payload);
      const uid = response.data.data?.uid;
      navigate(`/verification/account/${userName}/${uid}`);
    } catch (error) {
      console.error(error);
    }
  };
  const logoutItem = isloggedIn
    ? {
        key: "logout",
        icon: <LogoutOutlined />,
        label: t("logout"),
        onClick: () => handleLogOut(),
      }
    : null;
  const onClickTopMenu: MenuProps["onClick"] = (e) => {
    console.log("click ", e.key);
    dispatch(switchTopNav(e.key));
  };

  let topMenuItems: MenuItem[];
  isloggedIn
    ? (topMenuItems = [
        {
          label: <Link to="/articles">{t("articles")}</Link>,
          key: "articles",
        },
        {
          label: (
            <Link to="/race_information/upcoming_race">
              {t("race_information_top")}
            </Link>
          ),
          key: "information",
        },
        {
          label: <Link to="/tips/statistics_king">{t("tips")}</Link>,
          key: "tips",
        },
        {
          label: (
            <Link to="/instantBettingSuggestion/instant_key_horse">
              {t("instant_analysis_top")}
            </Link>
          ),
          key: "analysis",
        },
      ])
    : (topMenuItems = [
        {
          label: (
            <Link to="/race_information/upcoming_race">
              {t("race_information_top")}
            </Link>
          ),
          key: "information",
        },
      ]);

  return (
    <div>
      <Header
        style={{
          backgroundColor: "rgba(240, 242, 242)",
          top: 0,
          zIndex: 1,
          display: "flex",
          justifyContent: "space-between",
          padding: "0 20px",
          border: "1px solid #E0E0E0",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <img
          src={upickLogoResized}
          style={{
            width: "82px",
            height: "62px",
            marginLeft: "-20px",
            borderRadius: "4px",
          }}
        />
        <Menu
          style={{
            width: "80%",
            backgroundColor: "rgba(240, 242, 242)",
            fontSize: "0.6rem",
          }}
          onClick={onClickTopMenu}
          selectedKeys={[current]}
          mode="horizontal"
          items={topMenuItems}
        />
        <Drawer
          placement="left"
          onClose={onClose}
          title={
            user?.user.is_active ? (
              <>
                {" "}
                <Flex justify="space-between">
                  {" "}
                  <Flex justify="left" align="left">
                    <Avatar
                      shape="square" // Change the shape to square
                      style={{
                        backgroundColor: "#fde3cf",
                        color: "black",
                        height: "45px",
                        width: "45px",
                        padding: "0",
                        margin: "0",
                        marginRight: "10px",
                      }}
                    >
                      {user?.user?.first_name.substring(0, 2).toUpperCase()}
                    </Avatar>
                    <div>
                      {" "}
                      <p
                        style={{
                          textAlign: "left",
                          color: "black",
                          padding: "0px",
                          margin: "0px",
                        }}
                      >
                        {user?.user?.first_name} {user?.user?.last_name}
                      </p>
                      <p
                        style={{
                          color: "black",
                          opacity: ".5",
                          textAlign: "left",
                          padding: "0px",
                          margin: "0px",
                        }}
                      >
                        {user?.user.is_subscribed === false ? (
                          currentLanguage === "en" ? (
                            <>Free Tier</>
                          ) : (
                            <>免費用戶</>
                          )
                        ) : currentLanguage === "en" ? (
                          <>Premium Tier</>
                        ) : (
                          <>訂閱會員</>
                        )}
                      </p>
                    </div>
                  </Flex>
                  <>
                    <Dropdown overlay={languageMenu} trigger={["hover"]}>
                      <Button
                        icon={<GlobalOutlined style={{ color: "black" }} />}
                      ></Button>
                    </Dropdown>
                  </>
                </Flex>
              </>
            ) : (
              <Flex justify="space-between" align="center">
                <Title level={3}>UPICK</Title>
                <Dropdown overlay={languageMenu} trigger={["hover"]}>
                  <Button
                    icon={<GlobalOutlined style={{ color: "black" }} />}
                  ></Button>
                </Dropdown>
              </Flex>
            )
          }
          open={visible}
          bodyStyle={{
            display: "flex",
            flexDirection: "column",
            padding: "0",
            margin: "0",
            border: "none", // Remove any border from the Drawer itself
          }} // Add flex column layout
          style={{
            padding: "0",
            margin: "0",
            backgroundColor: "rgba(240, 242, 242)",
            height: "100vh",
            border: "none", // Remove any border from the Drawer itself
          }}
        >
          <Menu
            mode="inline"
            theme="light"
            style={{
              background: "rgba(240, 242, 242)",
              margin: "0px",
              padding: "0px",
              borderTop: "1px solid #E0E0E0", // Optional styling to separate it from the rest of the menu
              // position: "absolute", // Make this menu item fixed to the bottom
              // bottom: 45, // Align the menu item to the bottom
              width: "100%", // Ensure it spans the full width of the Sider
            }}
            items={[...items, logoutItem].filter(Boolean)}
            onClick={onClose}
          />
        </Drawer>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {" "}
          <div style={{ color: "white" }}>
            {" "}
            <Button
              style={{ backgroundColor: "white" }}
              icon={<MenuOutlined />}
              onClick={onClick}
            />
          </div>
          {isloggedIn ? (
            <div></div>
          ) : (
            <Flex>
              <Link to="/login">
                <Button type="default" className="loginBtnMobile">
                  {t("login")}
                </Button>
              </Link>
              <Link to="/registration">
                <Button type="default" className="signUpBtnMobile">
                  {t("sign_up")}
                </Button>
              </Link>
            </Flex>
          )}
        </div>
      </Header>
      {accountNotVerified && (
        <Alert
          message={
            <span>
              {t("account_not_verified")}
              <span
                style={{ color: "blue", cursor: "pointer" }}
                onClick={handleVerifyClick}
              >
                {" "}
                {t("here")}{" "}
              </span>
              {t("to_verify")}
            </span>
          }
          type="warning"
          showIcon
          closable
        />
      )}
    </div>
  );
};

export default HeaderLayoutPhone;
