import { createSlice } from "@reduxjs/toolkit";

interface Author {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}

interface AuthorState {
  authors: Author[]; // Now authors is an array of Author objects
}

const initialState: AuthorState = {
  authors: [], // Initial state is an empty array
};

export const authorSlice = createSlice({
  name: "author",
  initialState,
  reducers: {
    setAuthors: (state, action) => {
      state.authors = action.payload; // Store the array of authors
    },
    clearAuthors: (state) => {
      state.authors = []; // Clear all authors from the state
    },
  },
});

export const { setAuthors, clearAuthors } = authorSlice.actions;

export default authorSlice.reducer;
