import React, { useEffect, useState } from "react";
import { Spin, Table, Flex, Empty } from "antd";
import MainLayout from "../Layout/MainLayout";
import "../../styles/Trio.css";
import FetchRaceInformation from "../Mapper/raceInformationMapper";
import fetchAllMetaDetailsOdds from "../CommonApiCalls/metaDataRaceInformation";
import { useTranslation } from "react-i18next";
import allOdds from "../CommonApiCalls/allOdds";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import TitleCard from "./Responsive Title/TitleCard";
import Segment from "./Responsive Title/Segment";
import TitleCardDesktop from "./Responsive Title/TitleCardDesktop";
const Trio = () => {
  return (
    <div>
      <MainLayout Component={WinPlaceDisplay} />
    </div>
  );
};
export default Trio;
type OddsData = {
  combString: string;
  oddsValue: string;
  hotFavourite: boolean;
  oddsDropValue: number;
  bankerOdds: any[];
};

type OddsCategory = {
  last_updated: string;
  data: OddsData[];
};

type Race = {
  "race number": number;
  odds: {
    win: OddsCategory;
    place: OddsCategory;
    qwin: OddsCategory;
    qplace: OddsCategory;
    forecast: OddsCategory;
    trio: OddsCategory;
  };
};
const WinPlaceDisplay: React.FC = () => {
  const [raceNumber, setRaceNumber] = useState<number>(1);
  const [maxRaceNumber, setMaxRaceNumber] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState(false);
  const [selectedRace, setSelectedRace] = useState<any[]>([]);
  const [raceTitle, setRaceTitle] = useState("");
  const { t } = useTranslation();
  const currentLanguage: string = useSelector(
    (state: RootState) => state.language.lng
  );
  const [raceDayPlace, setRaceDayPlace] = useState("");
  const [restOfDetails, setRestOfDetails] = useState("");
  const [raceData, setRaceData] = useState<any[]>([]);
  const onChange = (key: string) => {
    setRaceNumber(parseInt(key, 10));
  };

  const fetchRaceData = async (
    raceNumber: number,
    allRaceData: any,
    metaRaceDataDetails: any
  ) => {
    console.log(allRaceData);
    if (!allRaceData || !allRaceData.odds) {
      console.error("Race data is not available");
      setSelectedRace([]);
      return;
    }
    const race = allRaceData.odds.find(
      (r: Race) => r["race number"] === raceNumber
    );
    const totalRaces = Object.keys(metaRaceDataDetails).length;
    setMaxRaceNumber(totalRaces);
    const trios = race.odds.trio.data;
    console.log(race);
    if (Array.isArray(trios)) {
      const horseDetails = trios
        .filter((trio) => trio.oddsValue !== "SCR") // Filter out entries with "SCR" oddsValue
        .map((trio, index) => ({
          key: index + 1,
          combString: trio.combString,
          oddsValue: trio.oddsValue,
        }));
      horseDetails.sort((a, b) => a.oddsValue - b.oddsValue);
      console.log(horseDetails);
      setSelectedRace(horseDetails.slice(0, 20));
    }
  };
  const fetchRaceInformation = (
    metaRaceDataDetails: any,
    metaRaceDataDetailsInfo: any
  ) => {
    const raceDayPlaceVenue = Object.keys(metaRaceDataDetailsInfo)
      .filter((key: any) => !isNaN(key))
      .sort((a: any, b: any) => a - b)
      .map((key) => metaRaceDataDetailsInfo[key])
      .join("");
    const [raceTitle, raceDayPlace, restOfDetails] = FetchRaceInformation(
      raceNumber,
      raceDayPlaceVenue,
      metaRaceDataDetails,
      currentLanguage
    );
    setRaceTitle(raceTitle);
    setRaceDayPlace(raceDayPlace);
    setRestOfDetails(restOfDetails);
  };

  const fetchMetaRaceDataDetails = async () => {
    const [metaRaceData, metaRaceDataInfo] = await fetchAllMetaDetailsOdds(
      currentLanguage
    );
    return [metaRaceData, metaRaceDataInfo];
  };

  const fetchAllOdds = async () => {
    let oddsData: any = await allOdds();
    if (oddsData) {
      return oddsData;
    } else {
      console.error("Failed to fetch odds data or oddsData is null");
    }
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const [metaRaceData, metaRaceDataInfo] = await fetchMetaRaceDataDetails();
      const allRaceData = await fetchAllOdds();
      console.log(metaRaceData && allRaceData);
      if (metaRaceData && allRaceData) {
        fetchedMetaDataDetails(raceNumber, metaRaceData);
        fetchRaceData(raceNumber, allRaceData, metaRaceData);
        fetchRaceInformation(metaRaceData, metaRaceDataInfo);
      }
      setLoading(false);
    };
    fetchData();
    const intervalId = setInterval(() => {
      fetchData();
    }, 60000);
    return () => {
      clearInterval(intervalId);
    };
  }, [raceNumber, currentLanguage]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchedMetaDataDetails = (
    raceNumber: number,
    metaRaceDataDetails: any
  ) => {
    if (metaRaceDataDetails[raceNumber - 1]) {
      const runners = metaRaceDataDetails[raceNumber - 1].runners;
      let horseDetails;
      if (Array.isArray(runners)) {
        horseDetails = runners
          .filter((runner) => runner.barrierDrawNumber !== "")
          .map((runner, index) => ({
            horseNumber: index + 1,
            color: runner.horse.code,
            horseName:
              currentLanguage === "zh" ? runner.name_ch : runner.name_en,
            last6Run: runner.last6run,
            weight: runner.handicapWeight,
            currentWeight: runner.currentWeight,
            jockey:
              currentLanguage === "zh"
                ? runner.jockey?.name_ch || "N/A"
                : runner.jockey?.name_en || "N/A",
            draw: runner.barrierDrawNumber,
            trainer:
              currentLanguage === "zh"
                ? runner.trainer?.name_ch || "N/A"
                : runner.trainer?.name_en || "N/A",
            rating: runner.currentRating,
            gear: runner.gearInfo,
          }));
        setRaceData(horseDetails);
        return horseDetails;
      }
    }
  };

  const horseInfoColumns = [
    {
      title: t("no."),
      dataIndex: "horseNumber",
      key: "Horse number",
    },

    {
      title: t("horse_name"),
      dataIndex: "horseName",
      key: "Horse name",
    },
  ];

  const horseInfoColumnsMobile = [
    {
      title: t("no."),
      dataIndex: "horseNumber",
      key: "Horse number",
      render: (text: string) => (
        <span style={{ fontSize: "0.5rem", padding: "0px" }}>{text}</span>
      ),
    },
    {
      title: t("horse_name"),
      dataIndex: "horseName",
      key: "Horse name",
      render: (text: string) => (
        <span style={{ fontSize: "0.5rem", padding: "0px" }}>{text}</span>
      ),
    },
  ];

  const quarterIndex = Math.ceil(selectedRace.length / 4);
  const firstQuarter = selectedRace.slice(0, quarterIndex);
  const secondQuarter = selectedRace.slice(quarterIndex, 2 * quarterIndex);
  const thirdQuarter = selectedRace.slice(2 * quarterIndex, 3 * quarterIndex);
  const fourthQuarter = selectedRace.slice(3 * quarterIndex);
  const transformedString = (combString: string) => {
    console.log("Original string:", combString);

    const result = combString
      .split(",")
      .filter((num) => num !== "")
      .map((num) => parseInt(num, 10))
      .join(" - ");
    return result;
  };

  const desktopColumns = [
    {
      title: (
        <p style={{ textAlign: "center", padding: "0px", margin: "0px" }}>
          Trio Top 20
        </p>
      ),
      dataIndex: "combString",
      key: "combString",
      render: () => (
        <Flex justify="space-between">
          {/* First Quarter */}
          <div style={{ width: "23%" }}>
            {firstQuarter.map((record, index) => (
              <Flex
                justify="space-between"
                key={index}
                style={{
                  background: index % 2 === 0 ? "white" : "rgb(234, 247, 252)",
                }}
              >
                <p style={{ width: "100%", textAlign: "center" }}>
                  {transformedString(record.combString)}
                </p>
                <p
                  style={{
                    background: "rgba(240, 242, 242)",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    marginRight: "10px",
                    textAlign: "center",
                  }}
                >
                  {record.oddsValue}
                </p>
              </Flex>
            ))}
          </div>

          {/* Second Quarter */}
          <div style={{ width: "23%" }}>
            {secondQuarter.map((record, index) => (
              <Flex
                justify="space-between"
                key={index}
                style={{
                  background: index % 2 === 0 ? "white" : "rgb(234, 247, 252)", // Grey for odd rows
                }}
              >
                <p style={{ width: "100%", textAlign: "center" }}>
                  {transformedString(record.combString)}
                </p>
                <p
                  style={{
                    background: "rgba(240, 242, 242)",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    marginRight: "10px",
                    textAlign: "center",
                  }}
                >
                  {record.oddsValue}
                </p>
              </Flex>
            ))}
          </div>

          {/* Third Quarter */}
          <div style={{ width: "23%" }}>
            {thirdQuarter.map((record, index) => (
              <Flex
                justify="space-between"
                key={index}
                style={{
                  background: index % 2 === 0 ? "white" : "rgb(234, 247, 252)", // Grey for odd rows
                }}
              >
                <p style={{ width: "100%", textAlign: "center" }}>
                  {transformedString(record.combString)}
                </p>
                <p
                  style={{
                    background: "rgba(240, 242, 242)",
                    paddingLeft: "10px",
                    marginRight: "10px",
                    paddingRight: "10px",
                    textAlign: "center",
                  }}
                >
                  {record.oddsValue}
                </p>
              </Flex>
            ))}
          </div>

          {/* Fourth Quarter */}
          <div style={{ width: "23%" }}>
            {fourthQuarter.map((record, index) => (
              <Flex
                justify="space-between"
                key={index}
                style={{
                  background: index % 2 === 0 ? "white" : "rgb(234, 247, 252)", // Grey for odd rows
                }}
              >
                <p style={{ width: "100%", textAlign: "center" }}>
                  {transformedString(record.combString)}
                </p>
                <p
                  style={{
                    background: "rgba(240, 242, 242)",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    marginRight: "10px",
                    textAlign: "center",
                  }}
                >
                  {record.oddsValue}
                </p>
              </Flex>
            ))}
          </div>
        </Flex>
      ),
    },
  ];

  const halfIndex = Math.ceil(selectedRace.length / 2);
  const firstHalf = selectedRace.slice(0, halfIndex);
  const secondHalf = selectedRace.slice(halfIndex);

  const mobileColumns = [
    {
      title: (
        <p style={{ textAlign: "center", padding: "0px", margin: "0px" }}>
          Trio Top 20
        </p>
      ),
      dataIndex: "combString",
      key: "combString",
      render: () => (
        <Flex justify="space-between">
          {/* First half of the data */}
          <div style={{ width: "48%" }}>
            {firstHalf.map((record, index) => (
              <Flex
                justify="space-between"
                key={index}
                style={{
                  background: index % 2 === 0 ? "white" : "rgb(234, 247, 252)", // Grey for odd rows
                }}
              >
                <p
                  style={{
                    margin: "8px",
                    width: "100%",
                    textAlign: "center",
                    fontSize: "0.5rem",
                  }}
                >
                  {" "}
                  {transformedString(record.combString)}
                </p>
                <p
                  style={{
                    background: "rgba(240, 242, 242)",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    textAlign: "center",
                    margin: "8px",
                    fontSize: "0.5rem",
                  }}
                >
                  {record.oddsValue}
                </p>
              </Flex>
            ))}
          </div>

          {/* Second half of the data */}
          <div style={{ width: "48%" }}>
            {secondHalf.map((record, index) => (
              <Flex
                justify="space-between"
                key={index}
                style={{
                  background: index % 2 === 0 ? "white" : "rgb(234, 247, 252)", // Grey for odd rows
                }}
              >
                <p
                  style={{
                    margin: "8px",
                    width: "100%",
                    textAlign: "center",
                    fontSize: "0.5rem",
                  }}
                >
                  {" "}
                  {transformedString(record.combString)}
                </p>
                <p
                  style={{
                    background: "rgba(240, 242, 242)",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                    textAlign: "center",
                    margin: "8px",
                    fontSize: "0.5rem",
                  }}
                >
                  {record.oddsValue}
                </p>
              </Flex>
            ))}
          </div>
        </Flex>
      ),
    },
  ];

  const items = Array.from({ length: maxRaceNumber }, (_, i) => ({
    key: (i + 1).toString(),
    label: isMobile ? `${i + 1}` : `${t("race")} ${i + 1}`,
    children: "",
  }));

  const [day, date, venue] = raceDayPlace.split(",").map((item) => item.trim());
  return (
    <div>
      {isMobile && (
        <>
          <Segment value="trio" />
        </>
      )}{" "}
      {isMobile ? (
        <TitleCard
          title={t("trio")}
          date={date}
          venue={venue}
          day={day}
          maxRaceNumber={maxRaceNumber}
          onChange={onChange}
        />
      ) : (
        <>
          <TitleCardDesktop
            title={t("trio")}
            date={date}
            venue={venue}
            day={day}
            maxRaceNumber={maxRaceNumber}
            onChange={onChange}
          />
        </>
      )}
      {loading ? (
        <>
          <Flex align="center" justify="center">
            <Spin tip="Loading" size="large"></Spin>
          </Flex>
        </>
      ) : (
        <>
          {isMobile ? (
            <>
              {" "}
              <Flex gap={5} style={{ marginBottom: "12px" }}>
                {" "}
                <p
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    margin: "0px",
                    fontSize: "0.7rem",
                    paddingLeft: "4px",
                  }}
                >
                  {raceTitle}
                </p>
                <p
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    margin: "0px",
                    fontSize: "0.7rem",
                    textAlign: "right",
                    paddingRight: "4px",
                  }}
                >
                  {restOfDetails}
                </p>
              </Flex>
              <Flex gap={20}>
                <Table
                  className="mobile-trio-table"
                  style={{ width: "30%" }}
                  dataSource={raceData}
                  columns={isMobile ? horseInfoColumnsMobile : horseInfoColumns} // Use mobile or desktop columns based on screen size
                  size="small"
                  pagination={false}
                  rowClassName={(_, index) =>
                    index % 2 === 0 ? "row-even" : "row-odd"
                  }
                />
                <Table
                  className="mobile-trio-table"
                  dataSource={[{ key: 1 }]}
                  columns={isMobile ? mobileColumns : desktopColumns} // Use mobile or desktop columns based on screen size
                  size="small"
                  pagination={false}
                  style={{ width: "70%" }}
                  rowClassName={(_, index) =>
                    index % 2 === 0 ? "row-even" : "row-odd"
                  }
                />
              </Flex>
            </>
          ) : (
            <>
              <Flex
                justify="space-between"
                style={{
                  marginBottom: "12px",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                }}
              >
                <p
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    margin: "0px",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {raceTitle}
                </p>
                <p
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    margin: "0px",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {restOfDetails}
                </p>
              </Flex>
              <Flex justify="space-between" style={{ width: "100%" }}>
                {" "}
                <div style={{ width: "20%" }}>
                  <Table
                    className="desktop-trio-table"
                    dataSource={raceData}
                    columns={horseInfoColumns} // Use mobile or desktop columns based on screen size
                    size="small"
                    style={{ fontSize: "0.7rem", width: "100%" }}
                    pagination={false}
                    rowClassName={(_, index) =>
                      index % 2 === 0 ? "row-even" : "row-odd"
                    }
                  />
                </div>
                <div style={{ width: "75%" }}>
                  {" "}
                  <Table
                    dataSource={[{ key: 1 }]} // Essential for your logic
                    columns={isMobile ? mobileColumns : desktopColumns}
                    size="small"
                    pagination={false}
                    style={{ width: "100%" }}
                    locale={{
                      emptyText:
                        selectedRace.length === 1 &&
                        selectedRace[0].key === 1 ? (
                          <Empty
                            description="Race odds have not been updated yet" // Your custom message
                          />
                        ) : null,
                    }}
                  />
                </div>
              </Flex>
            </>
          )}
        </>
      )}
    </div>
  );
};
