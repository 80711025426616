import React from "react";
import { Routes, Route } from "react-router-dom";
import UpcomingRace from "../Race_Information/UpcomingRace.tsx";
import Login from "../../components/Authentication/Login.tsx";
import Home2 from "../../components/HomePage/Home2.tsx";
import ArticleDisplay from "../../components/Aritcle/ArticleDisplay.tsx";
import YoutubeLinks from "../../components/Youtube/YoutubeLinks.tsx";
import PaymentPlans from "../../components/Payment/PaymentPlans.tsx";
import Registration from "../../components/Authentication/Registration.tsx";
import AllArticles from "../../components/Aritcle/AllArticles.tsx";
import ArticleWrite from "../../components/Aritcle/ArticleWrite.tsx";
import WinPlace from "../../components/Race_Information/WinPlace.tsx";
import Forecast from "../../components/Race_Information/Forecast.tsx";
import AccountInformation from "../../components/Profile/AccountInformation.tsx";
import AccountSettings from "../../components/Profile/AccountSettings.tsx";
import ArticleEdit from "../../components/Aritcle/ArticleEdit.tsx";
import AdminHomePage from "../../components/Admin/AdminHomePage.tsx";
import Quinella from "../../components/Race_Information/Quinella.tsx";
import Trio from "../../components/Race_Information/Trio.tsx";
import OnccResults from "../../components/Race_Information/OnccResults.tsx";
import ForgetPassword from "../../components/Authentication/ForgetPassword.tsx";
import StatisticsKing from "../Tips/StatisticsKing.tsx";
import HorseStrengthIndex from "../../components/Tips/HorseStrengthIndex.tsx";
import InstantKeyHorse from "../InstantBettingSuggestion/InstantKeyHorse.tsx";
import ForgotPasswordUsername from "../Authentication/ForgotPasswordUsername.tsx";
import EmailSentPage from "../Verification/EmailSentPage.tsx";
import AccountVerification from "../Verification/AccountVerification.tsx";
import ChangePassword from "../Authentication/ChangePassword.tsx";
import PasswordChangedPage from "../Authentication/PasswordChangedPage.tsx";
import AllArticlesByAuthor from "../Aritcle/AllArticlesByAuthor.tsx";
import AllArticlesByAuthorDraft from "../Aritcle/AllArticlesDraft.tsx";
import ArticleDisplayForDraft from "../ArticleDisplay/ArticleDisplayForDraft.tsx";
import AllArticlesByAuthorArchived from "../Aritcle/AllArticlesArchived.tsx";
import AllArticlesByAuthorPublished from "../Aritcle/AllArticlesPublished.tsx";
import ArticleDisplayForPublished from "../ArticleDisplay/ArticleDisplayForPublished.tsx";
import ArticleDisplayForArchieved from "../ArticleDisplay/ArticleDisplayForArchieved.tsx";
import LockedPage from "../Tips/LockedPage.tsx";
import FinalTip from "../Tips/FinalTip.tsx";
import InstantComprehensiveDataAnalysis from "../InstantBettingSuggestion/InstantComprehensiveDataAnalysis.tsx";
import InstantHorseTrendRecommendation from "../InstantBettingSuggestion/InstantHorseTrendRecommendation.tsx";
import ProtectedRoute from "./ProtectiveRoute.tsx";
import PaymentSuccess from "../Payment/PaymentSuccess.tsx";
import PaymentFailed from "../Payment/PaymentFailed.tsx";

const AllRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home2 />}></Route>
      <Route path="/home" element={<Home2 />}></Route>
      <Route path="/Home2" element={<Home2 />}></Route>
      {/* Add route protection here */}
      <Route path="/login" element={<Login />}></Route> {/* use this */}
      <Route
        path="/forget_password/:token/:otp"
        element={<ForgetPassword />}
      ></Route>
      <Route
        path="/forget_password/"
        element={<ForgotPasswordUsername />}
      ></Route>
      <Route path="/change_password" element={<ChangePassword />}></Route>
      <Route
        path="/change_password/confirmed"
        element={<PasswordChangedPage />}
      ></Route>
      <Route
        path="/verification/email/:email"
        element={<EmailSentPage />}
      ></Route>{" "}
      <Route
        path="/verification/account/:username/:uid"
        element={<AccountVerification />}
      ></Route>
      <Route path="/registration" element={<Registration />}></Route>
      <Route
        path="articles"
        element={
          <ProtectedRoute>
            <AllArticles />
          </ProtectedRoute>
        }
      ></Route>
      <Route
        path="articles/:authorId"
        element={<AllArticlesByAuthor />}
      ></Route>
      <Route path="/write-article" element={<ArticleWrite />}></Route>
      <Route path="/draft" element={<AllArticlesByAuthorDraft />}></Route>
      <Route path="/archived" element={<AllArticlesByAuthorArchived />}></Route>
      <Route
        path="/published"
        element={<AllArticlesByAuthorPublished />}
      ></Route>
      <Route path="/writer-insights/:id" element={<ArticleDisplay />}></Route>
      <Route
        path="/writer-insights-draft/:id"
        element={<ArticleDisplayForDraft />}
      ></Route>
      <Route
        path="/writer-insights-published/:id"
        element={<ArticleDisplayForPublished />}
      ></Route>
      <Route
        path="/writer-insights-archived/:id"
        element={<ArticleDisplayForArchieved />}
      ></Route>
      <Route path="/edit-article/:id" element={<ArticleEdit />} />
      <Route path="/youtubeVideoLinks" element={<YoutubeLinks />}></Route>
      <Route path="/plans/pricing-and-plans" element={<PaymentPlans />}></Route>
      <Route path="/subscription/success" element={<PaymentSuccess />}></Route>
      <Route path="/subscription/error" element={<PaymentFailed />}></Route>
      <Route
        path="/race_information/upcoming_race"
        element={<UpcomingRace />}
      ></Route>
      <Route path="/race_information/win_place" element={<WinPlace />}></Route>
      <Route path="/race_information/quinella" element={<Quinella />}></Route>
      <Route path="/race_information/forecast" element={<Forecast />}></Route>
      <Route path="/race_information/trio" element={<Trio />}></Route>
      <Route
        path="/race_information/onccResults"
        element={<OnccResults />}
      ></Route>
      <Route
        path="/account-information"
        element={<AccountInformation />}
      ></Route>
      {/* Add route for is logged in */}
      <Route path="/tips/statistics_king" element={<StatisticsKing />}></Route>
      {/* Add route for is logged in */}
      <Route
        path="/tips/horse_strength_index"
        element={<HorseStrengthIndex />}
      ></Route>
      <Route path="/tips/Final_Tip" element={<FinalTip />}></Route>
      {/* Add route for is logged in */}
      <Route
        path="/instantBettingSuggestion/instant_key_horse"
        element={<InstantKeyHorse />}
      ></Route>
      {/* Add route for is logged in */}
      <Route path="/tips/locked" element={<LockedPage />}></Route>
      {/* Add route for is logged in */}
      <Route
        path="/instantBettingSuggestion/InstantComprehensiveDataAnalysis"
        element={<InstantComprehensiveDataAnalysis />}
      ></Route>
      {/* Add route for is logged in */}
      <Route
        path="/instantBettingSuggestion/InstantHorseTrendRecommendation"
        element={<InstantHorseTrendRecommendation />}
      ></Route>
      {/* Add route for is logged in */}
      <Route path="/account-settings" element={<AccountSettings />}></Route>
      {/* Add route for is logged in */}
      <Route path="/admin/home" element={<AdminHomePage />}></Route>
      {/* add protective route for admin */}
      <Route path="/profile" element={<AccountInformation />}></Route>
    </Routes>
  );
};

export default AllRoutes;
