import { UserOutlined } from "@ant-design/icons";
import { Avatar } from "antd";
import "../../styles/ArticleCard.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useNavigate } from "react-router-dom";
import RefreshAccessToken from "../Authentication/RefreshToken";

type ArticleProps = {
  id: number;
  title: string | null;
  paragraph: string | null;
  author: {
    username: string;
    email: string;
    first_name: string | null;
    last_name: string | null;
    id: number;
  } | null;
  embedded_link: string | null;
  article_picture: string | null;
  article_banner: string | null;
  post_date_time: string | null;
  published_date: string | null;
  status: string;
};

const ArticleCard = ({
  id,
  title,
  paragraph,
  author,
  embedded_link,
  article_picture,
  article_banner,
  post_date_time,
  status,
  published_date,
}: ArticleProps) => {
  const [hovered, setHovered] = useState(false);

  // Handle potential null values for author and post_date_time
  const firstName = author?.first_name ?? "";
  const lastName = author?.last_name ?? "";
  const date = published_date ? new Date(published_date) : new Date();
  const navigate = useNavigate();

  // Format the date and time
  const formattedDateTime = published_date;

  // Define the maximum length for the title and paragraph
  const maxTitleLength = 20; // Adjust as needed
  const maxParagraphLength = 100; // Adjust as needed

  const envUrl = process.env.REACT_APP_API_URL;
  const refreshAccessUrl: string = envUrl + "token/refresh/";
  const deleteArticleUrl: string = envUrl + "articles/";
  const [accessToken, setAccessToken] = useState<string | null>("");

  const isLoggedInRedux2 = useSelector((state: RootState) => state.auth.user);
  const isLoggedInRedux: boolean = useSelector(
    (state: RootState) => state.auth.isLoggedIn
  );

  useEffect(() => {
    console.log(post_date_time);
    const checkLoginAndFetchArticles = async () => {
      if (isLoggedInRedux2 && isLoggedInRedux2.user.refreshToken) {
        const refreshToken = isLoggedInRedux2.user.refreshToken;
        const accessToken = await RefreshAccessToken(refreshToken);
        setAccessToken(accessToken);
      } else {
        console.log("User is not logged in");
      }
    };

    checkLoginAndFetchArticles();
  }, [isLoggedInRedux, isLoggedInRedux2]);

  const truncateText = (text: string | null, maxLength: number): string => {
    if (!text) return ""; // Return empty string if text is null
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + "...";
  };

  const handleArticleClick = () => {
    navigate(`/writer-insights/${id}`);
  };

  return (
    <div>
      <div
        className="mainArticleContainer"
        style={{ position: "relative", marginLeft: "5px" }}
        onClick={handleArticleClick}
      >
        <div
          className="profilePicture"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            paddingTop: "10px",
          }}
        >
          <Avatar
            shape="square"
            style={{
              backgroundColor: "lightGrey",
              height: "40px",
              width: "40px",
              color: "black",
            }}
          >
            {firstName.substring(0, 2).toUpperCase()}
          </Avatar>
          <p style={{ textAlign: "center", fontSize: "12px", maxWidth: "90%" }}>
            {firstName} {lastName}
          </p>
        </div>
        <div className="articleInfo">
          <h3 className="shortArticleTitle">
            {truncateText(title, maxTitleLength)}
          </h3>
          <p className="dateTime">{formattedDateTime}</p>
          <p className="shortArticleParagraph">
            {truncateText(paragraph, maxParagraphLength)}
          </p>
        </div>
      </div>
    </div>
  );
};

export default ArticleCard;
