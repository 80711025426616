import React, { useEffect, useState } from "react";
import Card from "antd/es/card/Card";
import DraggableImage from "./DraggableImage";
import shaTin from "../../../pictures/sha-tin.jpg";
import happyValley from "../../../pictures/happy-valley.jpg";
import Horse from "../../../pictures/equestrian (2).png";
import { Badge, Flex } from "antd";
import { useNavigate } from "react-router-dom";
import "../../../styles/UpcomingMatchesCard.css";
type CardRacesProp = {
  raceNumber: number;
  raceTitle: string;
  raceTrack: string;
  raceCourse: string;
  raceDistance: string | number;
  raceStartTime: string;
  raceDayPlace: string;
  totalHorses: number;
  venueCode: string;
};

const UpcomingMatchesCard = (cardRaces: CardRacesProp) => {
  const navigate = useNavigate();
  const handleCardClick = () => {
    navigate("/race_information/upcoming_race");
  };
  useEffect(() => {
    console.log(cardRaces.venueCode);
  }, []);
  return (
    <div>
      <Card
        onClick={handleCardClick}
        hoverable
        cover={
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
              height: "200px",
            }}
          >
            <DraggableImage
              src={cardRaces.venueCode === "Sha Tin" ? shaTin : happyValley}
              alt={
                cardRaces.venueCode === "Sha Tin" ? "Sha Tin" : "Happy Valley"
              }
            />
          </div>
        }
        style={{
          width: "100%",
          height: 350,
          background: "rgba(245, 249, 255)",
        }}
      >
        {" "}
        <Flex justify="space-between">
          <div style={{ lineHeight: "1.6rem", marginTop: "15px" }}>
            <h3 style={{ padding: "0px", margin: "0px" }}>
              Race: {cardRaces.raceNumber} - {cardRaces.raceTitle}
            </h3>
            <p style={{ padding: "0px", margin: "0px" }}>
              {cardRaces.raceDayPlace}
            </p>
            <p style={{ padding: "0px", margin: "0px" }}>
              {cardRaces.raceTrack} {cardRaces.raceCourse},{" "}
              {cardRaces.raceDistance}M Start Time: {cardRaces.raceStartTime}
            </p>
          </div>
          <div>
            <Badge
              count={cardRaces.totalHorses}
              style={{
                backgroundColor: "orange",
                fontWeight: "bolder",
                marginRight: "50%",
                marginTop: "80%",
              }}
            >
              {" "}
              <img src={Horse} />
            </Badge>
          </div>
        </Flex>
      </Card>
    </div>
  );
};

export default UpcomingMatchesCard;
