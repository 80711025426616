import React, { useEffect, useState } from "react";
import { Spin, Table, Flex } from "antd";
import MainLayout from "../Layout/MainLayout";
import "../../styles/WinPlace.css";
import { useSelector } from "react-redux";
import FetchRaceInformation from "../Mapper/raceInformationMapper";
import fetchAllMetaDetailsOdds from "../CommonApiCalls/metaDataRaceInformation";
import allOdds from "../CommonApiCalls/allOdds";
import { useTranslation } from "react-i18next";
import { RootState } from "../../store";
import TitleCard from "./Responsive Title/TitleCard";
import Segment from "./Responsive Title/Segment";
import TitleCardDesktop from "./Responsive Title/TitleCardDesktop";

const WinPlace = () => {
  return (
    <div>
      <MainLayout Component={WinPlaceDisplay} />
    </div>
  );
};

export default WinPlace;
type OddsData = {
  combString: string;
  oddsValue: string;
  hotFavourite: boolean;
  oddsDropValue: number;
  bankerOdds: any[];
};
type OddsCategory = {
  last_updated: string;
  data: OddsData[];
};
type Race = {
  "race number": number;
  odds: {
    win: OddsCategory;
    place: OddsCategory;
    qwin: OddsCategory;
    qplace: OddsCategory;
    forecast: OddsCategory;
    trio: OddsCategory;
  };
};

type HorseDetails = {
  key: number;
  draw: string;
  weight: string;
  horseName: string;
  trainerName: string;
  winOdds: string;
  placeOdds: null;
}[];

const WinPlaceDisplay: React.FC = () => {
  const [raceNumber, setRaceNumber] = useState<number>(1);
  // const [raceInfo, setRaceInfo] = useState({}); // Assuming raceInfo is not used
  const [maxRaceNumber, setMaxRaceNumber] = useState<number>(1);
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [totalRowsColumns, setTotalRowsColumns] = useState<number>(1);
  const [totalNumberOfHorses, setTotalNumberOfHorses] = useState<number>(0);
  const [winPlaceData, setWinPlaceData] = useState<any[]>([]);
  const [raceTitle, setRaceTitle] = useState("");
  const [raceDayPlace, setRaceDayPlace] = useState("");
  const [restOfDetails, setRestOfDetails] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const currentLanguage: string = useSelector(
    (state: RootState) => state.language.lng
  );

  const envUrl = process.env.REACT_APP_API_URL;

  const fetchRaceData = async (
    raceNumber: number,
    horseDetails: HorseDetails,
    allRaceData: any,
    metaRaceData: any
  ) => {
    // console.log(allRaceData);
    if (allRaceData !== null) {
      const race = allRaceData.odds.find(
        (r: Race) => r["race number"] === raceNumber
      );
      // console.log(allRaceData);
      const totalRaces = Object.keys(metaRaceData).length;
      setMaxRaceNumber(totalRaces);
      const tableDataPlace = race.odds.place.data;
      const tableDataWin = race.odds.win.data;
      if (Array.isArray(tableDataPlace)) {
        tableDataPlace.forEach((oddsEntry) => {
          // Assuming oddsEntry has a property like `horseId` or `combString` that matches with horseDetails
          const horse = horseDetails.find(
            (horse) => horse.key === Number(oddsEntry.combString)
          );
          if (horse) {
            horse.placeOdds = oddsEntry.oddsValue; // Assign place odds
          }
        });

        // Update state with merged horseDetails
        // console.log(horseDetails);
        setWinPlaceData(horseDetails);
      }

      if (Array.isArray(tableDataWin)) {
        tableDataWin.forEach((oddsEntry) => {
          // Assuming oddsEntry has a property like `horseId` or `combString` that matches with horseDetails
          const horse = horseDetails.find(
            (horse) => horse.key === Number(oddsEntry.combString)
          );
          if (horse) {
            // console.log("controlHerer");
            // console.log(horse.winOdds);
            horse.winOdds = oddsEntry.oddsValue; // Assign place odds
          }
        });

        // Update state with merged horseDetails
        // console.log(horseDetails);
        setWinPlaceData(horseDetails);
      }
    }
  };

  const fetchedMetaData = (
    raceNumber: number,
    metaRaceDataDetails: any,
    metaRaceDataDetailsInfo: any
  ) => {
    console.log(metaRaceDataDetailsInfo);
    const raceDayPlaceVenue = Object.keys(metaRaceDataDetailsInfo)
      .filter((key) => !isNaN(key)) // Exclude any non-numeric keys (e.g., "_persist")
      .sort((a, b) => a - b) // Sort the numeric keys in ascending order
      .map((key) => metaRaceDataDetailsInfo[key]) // Map the keys to their corresponding values
      .join(""); // Join them into a string
    console.log(raceDayPlaceVenue);

    const [raceTitle, raceDayPlace, restOfDetails] = FetchRaceInformation(
      raceNumber,
      raceDayPlaceVenue,
      metaRaceDataDetails,
      currentLanguage
    );
    setRaceTitle(raceTitle);
    setRaceDayPlace(raceDayPlace);
    setRestOfDetails(restOfDetails);

    setTotalNumberOfHorses(metaRaceDataDetails[raceNumber - 1].runners.length);
    setTotalRowsColumns(metaRaceDataDetails[raceNumber - 1].runners.length);
  };

  const fetchedMetaDataDetails = (
    raceNumber: number,
    metaRaceDataDetails: any
  ) => {
    if (metaRaceDataDetails[raceNumber - 1]) {
      const runners = metaRaceDataDetails[raceNumber - 1].runners;
      if (Array.isArray(runners)) {
        const horseDetails: HorseDetails = runners
          .filter((runner) => runner.barrierDrawNumber !== "")
          .map((runner, index) => ({
            key: index + 1,
            draw: runner.barrierDrawNumber,
            weight: runner.handicapWeight,
            horseName:
              currentLanguage === "zh" ? runner.name_ch : runner.name_en,
            trainerName:
              currentLanguage === "zh"
                ? runner.trainer?.name_ch || "N/A"
                : runner.trainer?.name_en || "N/A",

            winOdds: null,
            placeOdds: null,
          }));
        return horseDetails;
      }
    }
  };
  const fetchMetaRaceDataDetails = async () => {
    const [metaRaceData, metaRaceDataInfo] = await fetchAllMetaDetailsOdds(
      currentLanguage
    );
    return [metaRaceData, metaRaceDataInfo];
  };

  const fetchAllOdds = async () => {
    let oddsData: any = await allOdds(); // Await the result of allOdds
    if (oddsData) {
      return oddsData;
    } else {
      console.error("Failed to fetch odds data or oddsData is null");
    }
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const [metaRaceData, metaRaceDataInfo] = await fetchMetaRaceDataDetails(); // Call your function to fetch and set metaRaceDataDetails
      const allRaceData = await fetchAllOdds();
      console.log(metaRaceData);
      console.log(metaRaceData && allRaceData);
      if (metaRaceData && allRaceData) {
        fetchedMetaData(raceNumber, metaRaceData, metaRaceDataInfo);
        const horseDetails = fetchedMetaDataDetails(raceNumber, metaRaceData);
        if (horseDetails) {
          fetchRaceData(raceNumber, horseDetails, allRaceData, metaRaceData);
        } else {
          console.warn("Horse details are null or undefined");
        }
        setLoading(false);
      }
      setLoading(false);
    };

    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
    }, 60000); // 60 seconds

    return () => {
      clearInterval(intervalId);
    };
  }, [raceNumber, currentLanguage]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const onChange = (key: string) => {
    setRaceNumber(parseInt(key, 10));
  };
  const fontSize = "0.6rem"; // You can adjust this value as needed

  const mobileColumns = [
    {
      title: <span style={{ fontSize: fontSize }}>{t("no.")}</span>,
      dataIndex: "key",
      key: "key",
      render: (text: string) => (
        <span style={{ fontSize: fontSize }}>{text}</span>
      ),
    },
    {
      title: <span style={{ fontSize: fontSize }}>{t("horse_name")}</span>,
      dataIndex: "horseName",
      key: "horseName",
      render: (text: string) => (
        <span style={{ fontSize: fontSize }}>{text}</span>
      ),
    },
    {
      title: <span style={{ fontSize: fontSize }}>{t("trainer")}</span>,
      dataIndex: "trainerName",
      key: "trainerName",
      render: (text: string) => (
        <span style={{ fontSize: fontSize }}>{text}</span>
      ),
    },
    {
      title: <span style={{ fontSize: fontSize }}>{t("win")}</span>,
      dataIndex: "winOdds",
      key: "winOdds",
      render: (text: string) => (
        <span style={{ fontSize: fontSize }}>{text}</span>
      ),
    },
    {
      title: <span style={{ fontSize: fontSize }}>{t("place")}</span>,
      dataIndex: "placeOdds",
      key: "placeOdds",
      render: (text: string) => (
        <span style={{ fontSize: fontSize }}>{text}</span>
      ),
    },
  ];
  const columns = [
    { title: t("no."), dataIndex: "key", key: "key" },
    { title: t("horse_name"), dataIndex: "horseName", key: "horseName" },
    { title: t("draw"), dataIndex: "draw", key: "draw" },
    { title: t("wt."), dataIndex: "weight", key: "weight" },
    { title: t("trainer"), dataIndex: "trainerName", key: "trainerName" },
    { title: t("win"), dataIndex: "winOdds", key: "winOdds" },
    { title: t("place"), dataIndex: "placeOdds", key: "placeOdds" },
  ];

  const items = Array.from({ length: maxRaceNumber }, (_, i) => ({
    key: (i + 1).toString(),
    label: `${t("race")} ${i + 1}`,
    children: "",
  }));
  const [day, date, venue] = raceDayPlace.split(",").map((item) => item.trim());
  return (
    <div className="dynamicTable">
      {isMobile && (
        <>
          <Segment value="win_place" />
        </>
      )}{" "}
      {isMobile ? (
        <TitleCard
          title={t("win_place")}
          date={date}
          venue={venue}
          day={day}
          maxRaceNumber={maxRaceNumber}
          onChange={onChange}
        />
      ) : (
        <>
          <TitleCardDesktop
            title={t("win_place")}
            date={date}
            venue={venue}
            day={day}
            maxRaceNumber={maxRaceNumber}
            onChange={onChange}
          />
        </>
      )}
      {loading ? (
        <Spin tip="Loading" size="large">
          <Table
            dataSource={winPlaceData}
            columns={columns}
            size="small"
            tableLayout="auto"
            style={{ width: "100%" }}
            pagination={false}
            rowClassName={(_, index) =>
              index % 2 === 0 ? "row-even" : "row-odd"
            } // Alternating row classes
          />
        </Spin>
      ) : (
        <>
          {isMobile ? (
            <>
              {" "}
              <Flex gap={5} style={{ marginBottom: "12px" }}>
                {" "}
                <p
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    margin: "0px",
                    fontSize: "0.7rem",
                    paddingLeft: "4px",
                  }}
                >
                  {raceTitle}
                </p>
                <p
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    margin: "0px",
                    fontSize: "0.7rem",
                    textAlign: "right",
                    paddingRight: "4px",
                  }}
                >
                  {restOfDetails}
                </p>
              </Flex>
              <div
                className="table-container"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "16px",
                }}
              >
                <Table
                  dataSource={winPlaceData}
                  columns={isMobile ? mobileColumns : columns} // Use mobile or desktop columns based on screen size
                  size="small"
                  tableLayout="auto"
                  pagination={false}
                  style={{ width: "100%" }}
                  rowClassName={(_, index) =>
                    index % 2 === 0 ? "row-even" : "row-odd"
                  } // Alternating row classes
                />
              </div>
            </>
          ) : (
            <>
              <Flex
                justify="space-between"
                style={{
                  marginBottom: "12px",
                  paddingLeft: "4px",
                  paddingRight: "4px",
                }}
              >
                <p
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    margin: "0px",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {raceTitle}
                </p>
                <p
                  style={{
                    fontFamily: '"Montserrat", sans-serif',
                    margin: "0px",
                    fontSize: "0.8rem",
                  }}
                >
                  {" "}
                  {restOfDetails}
                </p>
              </Flex>
              <div
                className="table-container"
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "16px",
                }}
              >
                <Table
                  dataSource={winPlaceData}
                  columns={isMobile ? mobileColumns : columns} // Use mobile or desktop columns based on screen size
                  size="small"
                  tableLayout="auto"
                  style={{ width: "100%" }}
                  pagination={false}
                  rowClassName={(_, index) =>
                    index % 2 === 0 ? "row-even" : "row-odd"
                  } // Alternating row classes
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};
