import React, { useEffect, useState } from "react";
import "../../styles/MainLayout.css";
import { Button, Layout, Menu, message, theme } from "antd";
import FooterLayout from "./FooterLayout";
import Sider from "antd/es/layout/Sider";
import {
  HomeOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
} from "@ant-design/icons";
import useMenuItems from "./NavigationMenu";
import { useSelector } from "react-redux";
import {
  selectAllAuthorsList,
  selectUser,
} from "../../state/selector/selectors";
import { RootState, store } from "../../store";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { logout, resetToken } from "../../state/auth/authSlice";
import { useNavigate } from "react-router-dom";
import HeaderLayout from "./HeaderLayout";
import HeaderLayoutPhone from "./HeaderLayoutPhone";

const { Content, Footer } = Layout;

type mailLayoutProp = {
  Component: React.ComponentType;
};
interface Author {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}
const MainLayout = ({ Component }: mailLayoutProp) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isAuthor, setIsAuthor] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [authorsData, setAuthorsData] = useState<Author[]>([]);
  const [isMobile, setIsMobile] = useState(false);
  const authorDetails = useSelector(selectAllAuthorsList);
  const navigate = useNavigate();
  const envUrl = process.env.REACT_APP_API_URL;
  const logoutUrl = envUrl + "logout/";
  const [refreshToken, setRefreshToken] = useState("");
  const isLoggedInRedux2 = useSelector((state: RootState) => state.auth.user);
  const isLoggedInRedux: boolean = useSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  const { t } = useTranslation();
  const user = useSelector(selectUser);
  useEffect(() => {
    setIsLoggedIn(isLoggedInRedux);
    if (user?.user.groups === 1) {
      setIsAuthor(true);
    }
    if (user?.user.is_superuser) {
      setIsAdmin(true);
    }
    if (isLoggedInRedux2 && isLoggedInRedux2.user.refreshToken) {
      setRefreshToken(isLoggedInRedux2.user.refreshToken);
    }
    setAuthorsData(authorDetails.authors);

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768); // Update the state based on screen width
    };

    // Set the initial state and add an event listener for screen resizing
    handleResize();
    window.addEventListener("resize", handleResize);

    // Cleanup event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, [isLoggedInRedux, isLoggedInRedux2]);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const items: any = useMenuItems({
    isLoggedIn,
    isAdmin,
    isAuthor,
    authorsData,
    t,
  });
  const [collapsed, setCollapsed] = useState(false);
  const logoutUser = async () => {
    const payload = {
      refresh: refreshToken,
    };
    try {
      const response = await axios.post(logoutUrl, payload);
      console.log("Logout successful:", response.data);
      store.dispatch(logout());
      navigate("/");
      message.success(t("logout_successful"));
    } catch (error) {
      store.dispatch(resetToken());
      navigate("/");

      console.log(error);
    }
  };
  const handleLogOut = async () => {
    await logoutUser();
  };

  return (
    <Layout
      style={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        background: "white",
      }}
    >
      {/* <HeaderLayoutPhone /> */}
      {isMobile ? <HeaderLayoutPhone /> : <HeaderLayout />}
      <Layout style={{ background: "white" }}>
        {" "}
        {!isMobile && (
          <>
            {" "}
            <Sider
              trigger={null}
              collapsible
              collapsed={collapsed}
              style={{
                background: "rgba(240, 242, 242)",
                border: "1px solid #E0E0E0",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                margin: "0px",
                padding: "0px",
                position: "relative", // Make the Sider a reference for absolute positioning
              }}
            >
              <Menu
                theme="light"
                mode="inline"
                style={{
                  background: "rgba(240, 242, 242)",
                  margin: "0px",
                  padding: "0px",
                  borderBottom: "1px solid #E0E0E0", // Optional styling to separate it from the rest of the menu
                }}
              >
                {" "}
                <Menu.Item
                  key="home"
                  icon={<HomeOutlined />}
                  onClick={() => navigate("/home")}
                >
                  {t("home")}
                </Menu.Item>
              </Menu>
              {/* Top content (e.g., logo and menu items) */}
              <Menu
                theme="light"
                mode="inline"
                defaultSelectedKeys={["1"]}
                style={{
                  background: "rgba(240, 242, 242)",
                  margin: "0px",
                  padding: "0px",
                }}
                items={items}
              />

              {/* Logout Button at the bottom */}
              {isLoggedIn && (
                <Menu
                  theme="light"
                  mode="inline"
                  style={{
                    background: "rgba(240, 242, 242)",
                    margin: "0px",
                    padding: "0px",
                    borderTop: "1px solid #E0E0E0", // Optional styling to separate it from the rest of the menu
                    // position: "absolute", // Make this menu item fixed to the bottom
                    // bottom: 45, // Align the menu item to the bottom
                    width: "100%", // Ensure it spans the full width of the Sider
                  }}
                >
                  {" "}
                  <Menu.Item
                    key="logout"
                    icon={<LogoutOutlined />}
                    onClick={() => handleLogOut()}
                  >
                    {" "}
                    {t("logout")}
                  </Menu.Item>
                </Menu>
              )}
            </Sider>
            <Button
              type="text"
              icon={
                collapsed ? (
                  <MenuUnfoldOutlined style={{ color: "black" }} />
                ) : (
                  <MenuFoldOutlined style={{ color: "black" }} />
                )
              }
              onClick={() => setCollapsed(!collapsed)}
              style={{
                fontSize: "16px",
                width: 44,
                height: 44,
                marginTop: "0",
              }}
            />
          </>
        )}
        <Content
          className="layout-content"
          style={{
            padding: "0 48px",
            paddingLeft: "0px",
            marginTop: isMobile ? "0" : "50px",
            marginBottom: "100px",
            flexGrow: 1,
          }}
        >
          <div
            className="layout-container"
            style={{
              background: colorBgContainer,
              minHeight: 280,
              borderRadius: borderRadiusLG,
            }}
          >
            <div
              className="inner-content"
              style={{
                minHeight: 580,
                minWidth: 300,
              }}
            >
              <Component />
            </div>
          </div>
        </Content>
      </Layout>

      <Footer style={{ padding: 0, margin: 0 }}>
        <FooterLayout />
      </Footer>
    </Layout>
  );
};

export default MainLayout;
function dispatch(arg0: any) {
  throw new Error("Function not implemented.");
}
