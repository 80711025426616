import React, { useEffect } from "react";
import { Card, Button, Typography } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const { Title, Paragraph } = Typography;

// const AccountVerified = () => {
//   return (
//     <div>
//       <MainLayout Component={AccountVerifiedDisplay} />
//     </div>
//   );
// };

const PaymentFailed: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Extract query parameters from the URL
  const searchParams = new URLSearchParams(location.search);
  const uid = searchParams.get("uid");
  const token = searchParams.get("token");

  const handleContinueClick = () => {
    navigate("/"); // Replace '/dashboard' with your target route
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Card
        style={{
          maxWidth: 400,
          textAlign: "center",
          borderRadius: 8,
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
        }}
      >
        <CloseCircleOutlined style={{ fontSize: "48px", color: "red" }} />
        <Title level={3} style={{ marginTop: 20 }}>
          Payment Failed
        </Title>
        <Paragraph>Something went wrong! Please try again later</Paragraph>
        <Button
          type="primary"
          onClick={handleContinueClick}
          style={{ marginTop: 20 }}
        >
          Continue to UPICK
        </Button>
      </Card>
    </div>
  );
};

export default PaymentFailed;
