import React, { useRef, useState, useEffect } from "react";
import Draggable from "react-draggable";

type DraggableImageProps = {
  src: string;
  alt: string;
};

const DraggableImage = ({ src, alt }: DraggableImageProps) => {
  const imgRef = useRef<HTMLImageElement | null>(null); // Ref for the image element
  const containerRef = useRef<HTMLDivElement | null>(null); // Ref for the container
  const [bounds, setBounds] = useState<{
    top: number;
    left: number;
    right: number;
    bottom: number;
  }>({
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  });

  useEffect(() => {
    const img = imgRef.current;
    const container = containerRef.current;

    if (img && container) {
      const containerRect = container.getBoundingClientRect();
      const imgRect = img.getBoundingClientRect();

      // Calculate the bounds to limit dragging inside the container
      setBounds({
        top: Math.min(0, containerRect.height - imgRect.height),
        left: Math.min(0, containerRect.width - imgRect.width),
        right: 0,
        bottom: 0,
      });
    }
  }, []);

  return (
    <div
      ref={containerRef}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden",
        height: "200px", // Set the height of the container
        width: "100%", // Full width container
        position: "relative",
      }}
    >
      <Draggable bounds={bounds}>
        <img
          ref={imgRef}
          alt={alt}
          src={src}
          style={{
            width: "100%", // Image will fit the container's width
            objectFit: "contain", // Make the image fit inside the container
            objectPosition: "bottom right", // Start from the bottom-right
            borderRadius: "10px", // Rounded corners
            cursor: "grab", // Indicate that the image is draggable
          }}
        />
      </Draggable>
    </div>
  );
};

export default DraggableImage;
