import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import type { FormProps } from "antd";
import { Button, Form, Input, Row, Col, Typography, message } from "antd";
import MainLayout from "../Layout/MainLayout";
import axios from "axios";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { InputOTP } from "antd-input-otp";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

type FieldType = {
  username?: string;
};

const boxStyle: React.CSSProperties = {
  width: "100%",
  maxWidth: "400px",
  margin: "0 auto",
  boxShadow: "0 0 8px rgba(0, 0, 0, 0.4)",
  padding: "20px",
};

const innerStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  marginBottom: "8%",
  alignItems: "center",
};

const ForgotPasswordUsername: React.FC = () => {
  return (
    <div>
      <MainLayout Component={PageDisplay} />
    </div>
  );
};

export default ForgotPasswordUsername;

const PageDisplay: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [userName, setUserName] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [uid, setUid] = useState<string>("");
  const [isRunning, setIsRunning] = useState(true);
  const [timeLeft, setTimeLeft] = useState(120); // 120 seconds = 2 minutes
  const [showOtp, setShowOtp] = useState<boolean>(false);
  const { t } = useTranslation();
  const envUrl = process.env.REACT_APP_API_URL;

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };

  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    setLoading(true);
    const verifyTokenUrl = envUrl + "send-password-change-token/";

    const { username } = values;

    try {
      const payload = { username: username };
      const response = await axios.post(verifyTokenUrl, payload);
      const uid = response.data.data?.uid;
      setUid(uid);
      setShowOtp(true);
      setTimeLeft(120);
    } catch (error) {
      message.error(t("account_does_not_exist"));
    }

    setLoading(false);
  };

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timer); // Clear the interval on component unmount
    } else {
      setIsRunning(false); // Set isRunning to false when the timer hits zero
    }
  }, [timeLeft]);

  const resendCode = async () => {
    try {
      console.log("Resending OTP code...");
      const verifyTokenUrl = envUrl + "send-password-change-token/";

      if (!userName) {
        throw new Error("Username is missing.");
      }

      const payload = { username: userName };

      const response = await axios.post(verifyTokenUrl, payload);
      console.log("OTP resent successfully:", response.data);

      setTimeLeft(120);
      setIsRunning(true); // Restart the countdown
      message.success(t("otp_resent_successfully"));
    } catch (error) {
      console.error("Failed to resend OTP:", error);
      message.error(t("failed_to_send_otp"));
    }
  };

  const handleFinish = (values: any) => {
    const { otp } = values;

    if (!otp || otp.includes(undefined) || otp.includes(""))
      return form.setFields([{ name: "otp", errors: ["OTP is invalid."] }]);

    const otpString = otp.join("");
    navigate(`/forget_password/${uid}/${otpString}`);
  };

  return (
    <Row justify="center" align="middle" style={{ minHeight: "60vh" }}>
      <Col xs={30} sm={18} md={12} lg={10} xl={8}>
        <div style={boxStyle}>
          <div style={innerStyle}>
            <Title level={4} style={{ marginBottom: "8%" }}>
              {t("enter_username")}
            </Title>
            <Form
              name="basic"
              layout="vertical"
              onFinish={onFinish}
              autoComplete="off"
            >
              <Form.Item<FieldType>
                label={t("username")}
                name="username"
                rules={[{ required: true, message: t("username_required") }]}
              >
                <Input
                  prefix={<UserOutlined />}
                  placeholder={t("username")}
                  size="large"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                />
              </Form.Item>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  loading={loading}
                >
                  {t("send_code")}
                </Button>
              </Form.Item>
            </Form>

            {showOtp && (
              <Form form={form} onFinish={handleFinish}>
                <Form.Item
                  name="otp"
                  rules={[{ validator: async () => Promise.resolve() }]}
                >
                  <InputOTP
                    autoFocus
                    length={6}
                    inputType="alphabet-numeric"
                    style={{
                      width: "40px", // Adjust the width of each input
                      height: "40px", // Adjust the height of each input
                      fontSize: "16px", // Adjust the font size
                    }}
                  />
                </Form.Item>
                <p style={{ color: "red", textAlign: "center" }}>
                  {formatTime(timeLeft)}
                </p>
                <Form.Item>
                  <Button type="primary" block htmlType="submit">
                    {t("submit")}
                  </Button>
                </Form.Item>
                <Form.Item>
                  <Button block onClick={resendCode}>
                    {t("resend_code")}
                  </Button>
                </Form.Item>
              </Form>
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
};
