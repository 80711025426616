const InstantComprehensiveDataAnalysisSuggestion = (
  finalPrediction: number
) => {
  let suggestedAmount: string = "";
  finalPrediction = Math.trunc(finalPrediction * 10);

  if (finalPrediction == 1) {
    suggestedAmount = "小注";
  } else if (finalPrediction >= 2 && finalPrediction <= 3) {
    suggestedAmount = "中注";
  } else if (finalPrediction >= 4) {
    suggestedAmount = "大注";
  } else if (finalPrediction == 0) {
    suggestedAmount = "不買";
  } else {
    suggestedAmount = "invalid";
  }

  return suggestedAmount;
};

export default InstantComprehensiveDataAnalysisSuggestion;
