import React, { useState } from "react";
import { Form, Input, Button, Typography, message, Radio, Flex } from "antd";
import {
  UserOutlined,
  MailOutlined,
  LockOutlined,
  PhoneOutlined,
} from "@ant-design/icons";
import "../../styles/Registration.css"; // Make sure to create this CSS file to handle styling
import MainLayout from "../Layout/MainLayout";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

const Registration: React.FC = () => {
  return (
    <div>
      <MainLayout Component={PageDisplay} />
    </div>
  );
};

export default Registration;

const PageDisplay: React.FC = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [contactType, setContactType] = useState<"email" | "phone">("email"); // State to track contact type
  const envUrl = process.env.REACT_APP_API_URL;
  const registrationUrl = envUrl + "register/";
  const { t } = useTranslation();

  const registerUser = async () => {
    const payload = {
      username: contactType === "email" ? email : mobileNumber,
      first_name: firstName,
      last_name: lastName,
      password: password,
      ...(contactType === "email"
        ? { email }
        : { mobile_number: mobileNumber }),
    };

    console.log(payload);

    try {
      const response = await axios.post(registrationUrl, payload);
      console.log("Registration successful:", response.data);
      setTimeout(() => {
        setLoading(false);
        message.success("Registration Successful!");
        form.resetFields();
      }, 1000);
      navigate("/login");
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setLoading(false);
        const serverError = error.response?.data;
        console.log(serverError);
        if (
          serverError?.message?.email?.[0] ===
          "user with this email already exists."
        ) {
          message.error(t("account_already_exists"));
        } else if (
          serverError?.message?.mobile_number?.[0] ===
          "user with this mobile number already exists."
        ) {
          message.error(t("account_already_exists"));
        } else if (serverError?.message) {
          message.error(t("registration_failed"));
        } else {
          message.error(t("unexpected_error_occurred"));
        }
      } else {
        message.error(t("unexpected_error_occurred"));
      }
    }
  };

  const onFinish = async (values: any) => {
    setLoading(true);
    await registerUser();
  };

  const validatePasswords = (_: any, value: string) => {
    if (form.getFieldValue("password") === value) {
      return Promise.resolve();
    }
    return Promise.reject("Passwords do not match!");
  };

  // Synchronize the local email state with the Ant Design form state
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setEmail(newValue);
    form.setFieldsValue({ email: newValue });
  };

  const handleMobileNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setMobileNumber(newValue);
    form.setFieldsValue({ mobileNumber: newValue });
  };

  return (
    <div>
      <Title
        level={2}
        className="registration-title"
        style={{
          textAlign: "center",
          fontFamily: "sans-serif",
        }}
      >
        {t("sign_up_title")}{" "}
      </Title>
      <Flex
        justify="center"
        align="flex-start"
        style={{ width: "100%", minHeight: "100vh" }}
      >
        <div
          className="registration-container"
          style={{ width: "100%", maxWidth: "500px", padding: "20px" }}
        >
          <Title
            level={3}
            className="registration-title"
            style={{
              textAlign: "left",
              marginTop: "0.5%",
              marginBottom: "1%",
              fontFamily: "sans-serif",
              paddingTop: "0px",
            }}
          ></Title>
          <Form
            form={form}
            name="register"
            onFinish={onFinish}
            scrollToFirstError
            layout="vertical"
            className="registration-form"
          >
            <Form.Item
              name="firstName"
              label={
                <span style={{ fontFamily: "sans-serif" }}>
                  {t("first_name")}
                </span>
              }
              rules={[{ required: true, message: t("enter_first_name") }]}
            >
              <Input
                prefix={<UserOutlined />}
                placeholder={t("first_name")}
                size="large"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              name={t("last_name")}
              label={
                <span style={{ fontFamily: "sans-serif" }}>
                  {t("last_name")}
                </span>
              }
              rules={[{ required: true, message: t("enter_last_name") }]}
            >
              <Input
                prefix={<UserOutlined />}
                placeholder={t("last_name")}
                size="large"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Form.Item>

            <Form.Item label="Register with">
              <Radio.Group
                value={contactType}
                onChange={(e) => setContactType(e.target.value)}
              >
                <Radio value="email">{t("email")}</Radio>
                <Radio value="phone">{t("phone_number")}</Radio>
              </Radio.Group>
            </Form.Item>

            {contactType === "email" ? (
              <Form.Item
                name="email"
                label={t("email")}
                rules={[
                  {
                    type: "email",
                    message: t("enter_valid_email"),
                  },
                  { required: true, message: t("enter_email") },
                ]}
              >
                <Input
                  prefix={<MailOutlined />}
                  placeholder={t("email")}
                  size="large"
                  value={email}
                  onChange={handleEmailChange}
                />
                <p
                  style={{
                    color: "gray",
                    fontSize: "0.75rem",
                    margin: "0px",
                    marginTop: "4px",
                    padding: "0px",
                  }}
                >
                  {t("username_note")}
                </p>
              </Form.Item>
            ) : (
              <Form.Item
                name="mobileNumber"
                label={t("mobile_number")}
                rules={[
                  {
                    required: true,
                    message: t("enter_mobile_number"),
                  },
                  {
                    pattern: /^[4-9][0-9]{7}$/,
                    message: t("enter_valid_mobile_number"),
                  },
                  {
                    len: 8,
                    message: t("8_digits"),
                  },
                ]}
              >
                <Input
                  prefix={<PhoneOutlined />}
                  placeholder={t("mobile_number")}
                  size="large"
                  value={mobileNumber}
                  onChange={handleMobileNumberChange}
                />
                <p
                  style={{
                    color: "gray",
                    fontSize: "0.75rem",
                    margin: "0px",
                    marginTop: "4px",
                    padding: "0px",
                  }}
                >
                  {t("mobile_number_note")}
                </p>
              </Form.Item>
            )}

            <Form.Item
              name="password"
              label={t("password")}
              rules={[
                { required: true, message: t("enter_password") },
                {
                  validator: (_, value) =>
                    value && value.length > 4
                      ? Promise.resolve()
                      : Promise.reject(t("password_length")),
                },
              ]}
              hasFeedback
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder={t("password")}
                size="large"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Item>

            <Form.Item
              name="confirmPassword"
              label={t("confirm_password")}
              dependencies={[{ password }]}
              hasFeedback
              rules={[
                { required: true, message: t("re_enter_password") },
                { validator: validatePasswords },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder={t("confirm_password")}
                size="large"
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="registration-button"
                loading={loading}
                size="large"
                block
              >
                {t("register")}
              </Button>
            </Form.Item>
          </Form>
          <p style={{ textAlign: "center" }}>
            {t("already_have_account")} <Link to="/login">{t("here")} </Link>
          </p>
        </div>
      </Flex>
    </div>
  );
};
