// selectors.ts
import { RootState } from "../../store"; // Adjust the import path as necessary

export const selectRefreshToken = (state: RootState) =>
  state.auth.user?.refreshToken || null;

export const selectUser = (state: RootState) => state.auth.user || null;

export const IsLoggedIn = (state: RootState) => state.auth?.isLoggedIn;

export const selectHkjcData = (state: RootState) => state.hkjc?.odds;

export const selectMetaRaceData = (state: RootState) => state.metaRace.races;

export const selectMetaRaceDataDetails = (state: RootState) =>
  state.metaRaceDetails;

export const selectMetaRaceDataDetailsInfo = (state: RootState) =>
  state.metaRaceDetailsInfo;

export const selectAllAuthorsList = (state: RootState) => state.authorsList;

export const selectCurrentUserId = (state: RootState) => state.auth.user?.id;
