import { ThemeConfig } from "antd/lib/config-provider/context";

export const APPLICATION_THEME: ThemeConfig = {
  // Theme base values
  token: {
    colorPrimary: "#000864",
  },
  // Override any component wise theme below
  components: {
    Button: {},
  },
};
