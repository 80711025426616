import {
  BulbOutlined,
  FileTextOutlined,
  FlagFilled,
  FlagOutlined,
  GlobalOutlined,
  InfoCircleOutlined,
  TrophyOutlined,
} from "@ant-design/icons";
import { Alert, Avatar, Button, Dropdown, Menu, Flex } from "antd";
import upickLogoResized from "../../pictures/UpickBannerResized.png";
import { Header } from "antd/es/layout/layout";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../styles/HeaderLayout.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import axios from "axios";
import { useTranslation } from "react-i18next";
import {
  selectAllAuthorsList,
  selectUser,
} from "../../state/selector/selectors";
import { switchLanguage } from "../../state/language/languageSlice";
import type { MenuProps } from "antd";
import { switchTopNav } from "../../state/topNav/topNavSlice";
type MenuItem = Required<MenuProps>["items"][number];

interface Author {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
}
const HeaderLayout = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isloggedIn, setIsLoggedIn] = useState(false);
  const [accountNotVerified, setAccountNotVerified] = useState(false);
  const [authorsData, setAuthorsData] = useState<Author[]>([]);
  const [current, setCurrent] = useState("");
  const envUrl = process.env.REACT_APP_API_URL;
  const currentLanguage: string = useSelector(
    (state: RootState) => state.language.lng
  );
  const currentNav: string = useSelector(
    (state: RootState) => state.topNav.current
  );

  const verifyTokenUrl = envUrl + "send-verification-token/";

  const languageMenu = (
    <Menu className="profileMenu">
      <Menu.Item
        key="1"
        onClick={() => onChange("zh")}
        style={{
          fontWeight: currentLanguage === "zh" ? "bold" : "normal",
          color: currentLanguage === "zh" ? "darkslategray" : "black",
        }}
      >
        {currentLanguage === "zh" ? (
          <FlagFilled style={{ color: "darkslategray", paddingRight: "3px" }} />
        ) : (
          <FlagOutlined style={{ color: "black", paddingRight: "3px" }} />
        )}
        Chinese
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => onChange("en")}
        style={{
          fontWeight: currentLanguage === "en" ? "bold" : "normal",
          color: currentLanguage === "en" ? "darkslategray" : "black",
        }}
      >
        {currentLanguage === "en" ? (
          <FlagFilled style={{ color: "darkslategray", paddingRight: "3px" }} />
        ) : (
          <FlagOutlined style={{ color: "black", paddingRight: "3px" }} />
        )}
        English
      </Menu.Item>
    </Menu>
  );

  useEffect(() => {}, [authorsData]);
  const isLoggedInRedux: boolean = useSelector(
    (state: RootState) => state.auth.isLoggedIn
  );

  const metaRaceData = useSelector(selectUser);
  const authorDetails = useSelector(selectAllAuthorsList);
  const isLoggedInRedux2 = useSelector((state: RootState) => state.auth.user);
  const user = useSelector(selectUser);

  useEffect(() => {
    setCurrent(currentNav);
    setIsLoggedIn(isLoggedInRedux);
    if (isLoggedInRedux2 && isLoggedInRedux2.user.refreshToken) {
      console.log(authorDetails.authors);
      setAuthorsData(authorDetails.authors);
      isVerified(metaRaceData?.user.is_verified);
    } else {
    }
  }, [isLoggedInRedux, isLoggedInRedux2]);

  const dispatch = useDispatch();

  const onChange = (language: string) => {
    dispatch(switchLanguage(language));
  };

  const isVerified = async (verificationStatus: boolean) => {
    if (verificationStatus) {
      setAccountNotVerified(false);
    } else {
      setAccountNotVerified(true);
    }
  };
  const handleVerifyClick = async () => {
    const userName = metaRaceData?.user.username;
    const payload = {
      username: userName,
    };
    console.log(payload);
    try {
      const response = await axios.post(verifyTokenUrl, payload);
      const uid = response.data.data?.uid;
      navigate(`/verification/account/${userName}/${uid}`);
    } catch (error) {
      console.error(error);
    }
  };
  const handleMouseEnter = (e: React.MouseEvent<HTMLDivElement>) => {
    e.currentTarget.style.background = "none";
  };

  const handleMouseLeave = (e: React.MouseEvent<HTMLDivElement>) => {
    e.currentTarget.style.background = "none";
  };
  const handleClick = () => {
    navigate("/profile");
  };

  const onClickTopMenu: MenuProps["onClick"] = (e) => {
    console.log("click ", e.key);
    dispatch(switchTopNav(e.key));
  };

  let topMenuItems: MenuItem[];

  isloggedIn
    ? (topMenuItems = [
        {
          label: (
            <Link to="/race_information/upcoming_race">
              {t("race_information")}
            </Link>
          ),
          key: "information",
          icon: <InfoCircleOutlined style={{ color: "black" }} />,
        },

        {
          label: <Link to="/articles">{t("articles")}</Link>,
          key: "articles",
          icon: <FileTextOutlined style={{ color: "black" }} />,
        },

        {
          label: <Link to="/tips/statistics_king">{t("tips")}</Link>,
          key: "tips",
          icon: <BulbOutlined style={{ color: "black" }} />,
        },
        {
          label: (
            <Link to="/instantBettingSuggestion/instant_key_horse">
              {t("instant_analysis")}
            </Link>
          ),
          key: "analysis",
          icon: <TrophyOutlined style={{ color: "black" }} />,
        },
      ])
    : (topMenuItems = [
        {
          label: (
            <Link to="/race_information/upcoming_race">
              {t("race_information")}
            </Link>
          ),
          key: "information",
          icon: <InfoCircleOutlined style={{ color: "black" }} />,
        },
      ]);

  return (
    <div>
      <Header
        style={{
          backgroundColor: "rgba(240, 242, 242)",
          top: 0,
          zIndex: 1,
          display: "flex",
          justifyContent: "space-between",
          padding: "0 20px",
          border: "1px solid #E0E0E0",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <img
          src={upickLogoResized}
          style={{
            width: "100px",
            height: "60px",
            borderRadius: "4px",
            marginTop: "1px",
          }}
        />
        <Menu
          style={{
            width: "50%",
            backgroundColor: "rgba(240, 242, 242)",
            display: "flex",
            justifyContent: "center",
          }}
          onClick={onClickTopMenu}
          selectedKeys={[current]}
          mode="horizontal"
          items={topMenuItems}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {" "}
          <div style={{ color: "white" }}>
            {" "}
            <Dropdown overlay={languageMenu} trigger={["hover"]}>
              <Button
                icon={<GlobalOutlined style={{ color: "black" }} />}
              ></Button>
            </Dropdown>
          </div>
          {isloggedIn ? (
            <div>
              <Button
                type="text"
                style={{
                  border: "none",
                  marginTop: "0px",
                  display: "flex",
                  alignItems: "center",
                }}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
              >
                <div>
                  <Flex vertical>
                    <p
                      style={{
                        textAlign: "right",
                        color: "black",
                        padding: "0px",
                        margin: "0px",
                      }}
                    >
                      {user?.user?.first_name} {user?.user?.last_name}
                    </p>
                    <p
                      style={{
                        color: "black",
                        opacity: ".5",
                        textAlign: "right",
                        padding: "0px",
                        margin: "0px",
                      }}
                    >
                      {user?.user.is_subscribed === false ? (
                        currentLanguage === "en" ? (
                          <>Free Tier</>
                        ) : (
                          <>免費用戶</>
                        )
                      ) : currentLanguage === "en" ? (
                        <>Premium Tier</>
                      ) : (
                        <>訂閱會員</>
                      )}
                    </p>
                  </Flex>
                </div>
                <Avatar
                  shape="square" // Change the shape to square
                  style={{
                    backgroundColor: "#fde3cf",
                    color: "black",
                    paddingTop: "4px",
                  }}
                >
                  {user?.user?.first_name.substring(0, 2).toUpperCase()}
                </Avatar>
              </Button>
            </div>
          ) : (
            <div>
              <Link to="/login">
                <Button type="primary" className="loginBtn">
                  {t("login")}
                </Button>
              </Link>
              <Link to="/registration">
                <Button type="default" className="signUpBtn">
                  {t("sign_up")}
                </Button>
              </Link>
            </div>
          )}
        </div>
      </Header>
      {accountNotVerified && (
        <Alert
          message={
            <span>
              {t("account_not_verified")}
              <span
                style={{ color: "blue", cursor: "pointer" }}
                onClick={handleVerifyClick}
              >
                {" "}
                {t("here")}{" "}
              </span>
              {t("to_verify")}
            </span>
          }
          type="warning"
          showIcon
          closable
        />
      )}
    </div>
  );
};

export default HeaderLayout;
