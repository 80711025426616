import { Flex, Segmented } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../store";
import { useTranslation } from "react-i18next";
type segmentProps = {
  value: string;
};

const Segment = ({ value }: segmentProps) => {
  const currentLanguage: string = useSelector(
    (state: RootState) => state.language.lng
  );
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onChange = (value: string) => {
    //     <Route
    //     path="/race_information/upcoming_race"
    //     element={<UpcomingRace />}
    //   ></Route>
    //   <Route path="/race_information/win_place" element={<WinPlace />}></Route>
    //   <Route path="/race_information/quinella" element={<Quinella />}></Route>
    //   <Route path="/race_information/forecast" element={<Forecast />}></Route>
    //   <Route path="/race_information/trio" element={<Trio />}></Route>

    navigate(`/race_information/${value}`);
  };
  return (
    <div style={{ marginBottom: "10px" }}>
      {" "}
      <Flex justify="center" style={{ width: "100%" }}>
        {" "}
        <Segmented
          options={[
            {
              label: (
                <span style={{ fontSize: "0.6rem", padding: "0px" }}>
                  {t("upcoming_race")}
                </span>
              ),
              value: "upcoming_race",
            },
            {
              label: (
                <span style={{ fontSize: "0.6rem", padding: "0px" }}>
                  {t("win")} / {t("place")}
                </span>
              ),
              value: "win_place",
            },
            {
              label: (
                <span style={{ fontSize: "0.6rem", padding: "0px" }}>
                  {t("quinella/quinella_place")}
                </span>
              ),
              value: "quinella",
            },
            {
              label: (
                <span style={{ fontSize: "0.6rem", padding: "0px" }}>
                  {t("forecast")}
                </span>
              ),
              value: "forecast",
            },
            {
              label: (
                <span style={{ fontSize: "0.6rem", padding: "0px" }}>
                  {t("trio")}
                </span>
              ),
              value: "trio",
            },
          ]}
          value={value}
          onChange={onChange}
        />
      </Flex>
    </div>
  );
};

export default Segment;
