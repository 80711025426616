import { Flex, Tabs, Typography } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import React from "react";
import "./TitleCard.css";
type titleContentProps = {
  title: string;
  date: string;
  day: string;
  venue: string;
  maxRaceNumber: number;
  onChange: (key: string) => void;
};
const TitleCard = ({
  title,
  date,
  day,
  venue,
  maxRaceNumber,
  onChange,
}: titleContentProps) => {
  const { Text } = Typography;
  const titleContainerStyle: React.CSSProperties = {
    width: "100%",
    padding: "12px",
    borderRadius: "5px",
    backgroundColor: "#084c8c",
    color: "white",
  };

  const textStyle: React.CSSProperties = {
    color: "White",
    fontWeight: "bold",
    fontSize: "0.7rem",
  };

  const items = Array.from({ length: maxRaceNumber }, (_, i) => ({
    key: (i + 1).toString(),
    label: `${i + 1}`,
    children: "",
  }));
  return (
    <div>
      {" "}
      <div style={titleContainerStyle}>
        <Flex justify="space-between">
          <Text style={textStyle}>{title}</Text>
          <Text style={textStyle}>{date}</Text>
          <Text style={textStyle}>{day}</Text>
          <Text style={textStyle}>{venue}</Text>
        </Flex>
      </div>
      <Tabs defaultActiveKey="1" onChange={onChange} className="custom-tabs">
        {items.map((item) => (
          <TabPane
            tab={item.label}
            key={item.key}
            className="custom-tab-pane"
          />
        ))}
      </Tabs>
    </div>
  );
};

export default TitleCard;
