// authSlice.ts
import { createSlice } from "@reduxjs/toolkit";
interface AuthState {
  isLoggedIn: boolean;
  user: {
    user: any;
    id: number;
    username: string;
    first_name: string;
    last_name: string;
    is_active: boolean;
    date_joined: string;
    email: string | null;
    mobile_number: string | null;
    is_subscribed: boolean;
    is_verified: boolean;
    is_superuser: boolean;
    subscription_end_date: string | null;
    verification_method: string;
    groups: number;
    refreshToken: string;
    accessToken: string;
  } | null;
  accessToken: string | null;
  refreshToken: string | null;
}

const initialState: AuthState = {
  isLoggedIn: false,
  accessToken: null,
  refreshToken: null,
  user: null,
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      state.isLoggedIn = true;
      state.user = action.payload;
    },

    logout: (state) => {
      state.isLoggedIn = false;
      state.user = null;
    },

    resetToken: (state) => {
      state.accessToken = null;
      state.refreshToken = null;
      state.user = null;
      state.isLoggedIn = false;
    },
  },
});

// export const { login, logout, subscribe } = authSlice.actions;
export const { login, logout, resetToken } = authSlice.actions;

export default authSlice.reducer;
