const FetchHorseNames = (
  raceNumber: number,
  horseNumbers: number[],
  metaRaceDataDetails: any,
  currentLanguage: string
) => {
  let jockeyNames: string[] = [];
  let horseNames: string[] = [];
  let trainerNames: string[] = [];
  let currentRatings: string[] = [];
  let horseColorCode: string[] = [];
  console.log(metaRaceDataDetails[raceNumber - 1].runners[0].no);
  const runners: any[] = metaRaceDataDetails[raceNumber - 1].runners;
  console.log(runners);
  console.log(`Horse Number Array: ${horseNumbers}`);
  horseNumbers.forEach((horseNumber) => {
    runners.forEach((runner: any) => {
      console.log(`Horse Number: ${typeof horseNumber}`);
      if (horseNumber.toString() === runner.no) {
        if (currentLanguage === "en") {
          console.log(runner);
          // jockeyNames.push(runner.jockey.name_en);

          horseNames.push(
            `<img src="https://racing.hkjc.com/racing/content/Images/RaceColor/${runner.horse.code}.gif" 
                  alt="Horse Color Code ${runner.horse.code}" 
                  style="width: 20px; height: 20px; margin-right: 5px;" />
             ${runner.no} ${runner.name_en}`
          );
          horseColorCode.push(runner.horse.code);

          // trainerNames.push(runner.trainer.name_en);
        } else {
          // jockeyNames.push(runner.jockey.name_ch);

          horseNames.push(
            `<img src="https://racing.hkjc.com/racing/content/Images/RaceColor/${runner.horse.code}.gif" 
                  alt="Horse Color Code ${runner.horse.code}" 
                  style="width: 20px; height: 20px; margin-right: 5px;" />
             ${runner.no} ${runner.name_ch}`
          );
          horseColorCode.push(runner.horse.code);

          // trainerNames.push(runner.trainer.name_ch);
        }
        currentRatings.push(runner.currentRating);
      }
    });
  });

  return [
    jockeyNames,
    horseNames,
    trainerNames,
    currentRatings,
    horseColorCode,
  ];
};

export default FetchHorseNames;
