import React, { useEffect, useState } from "react";
import MainLayout from "../Layout/MainLayout";
import { Card, Flex, Rate, Spin, Table, Tag } from "antd";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import RefreshAccessToken from "../Authentication/RefreshToken";
import { useTranslation } from "react-i18next";
import totalRaceMetaData from "../CommonApiCalls/totalRace";
import LockedContent from "../LockedContent/LockedContent";
import TitleCardDesktop from "./Responsive Title/TitleCardDesktop";
import TitleCard from "./Responsive Title/TitleCard";
import FetchHorseNames from "../Mapper/horseName";
import Segment from "./Responsive Title/Segment";
import ResultsNA from "./Dependencies/ResultsNA";
import InstantScheduleTimer from "../OutputScheduler/InstantSchedular";
import fetchRaceStartTime from "../Mapper/raceSchedule";
import fetchAllMetaDetailsOdds from "../CommonApiCalls/metaDataRaceInformation";
import FetchRaceInformation from "../Mapper/raceInformationMapper";
import RaceInformationSaved from "../OutputScheduler/RaceInformationSaved";
import InstantComprehensiveDataAnalysisSuggestion from "./Dependencies/InstantComprehensiveDataAnalysisSuggestion";
const nodes = [
  {
    id: "0",
    label: "User",
    position: { x: 150, y: 250 },
  },
  {
    id: "1",
    label: "Robot",
    position: { x: 350, y: 350 },
  },
  {
    id: "2",
    label: "Frontend",
    position: { x: 400, y: 150 },
  },
  {
    id: "3",
    label: "Backend",
    position: { x: 700, y: 250 },
  },
  {
    id: "4",
    label: "DB",
    position: { x: 1000, y: 300 },
  },
];

const edges = [
  { source: "0", target: "2" },
  { source: "1", target: "3" },
  { source: "2", target: "3" },
  { source: "3", target: "4" },
];
const InstantComprehensiveDataAnalysis: React.FC = () => {
  return (
    <div>
      <MainLayout Component={PageDisplay} />
    </div>
  );
};

export default InstantComprehensiveDataAnalysis;
const getOrdinal = (n: number) => {
  const s = ["th", "st", "nd", "rd"];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

// Render function with ordinal suffix in superscript
const renderHorseNumber = (text: number, index: number) => {
  const ordinal = getOrdinal(index + 1); // Get ordinal suffix based on index
  return (
    <span style={{ textAlign: "center" }}>
      {" "}
      {text}
      {/* Render the ordinal suffix in superscript */}
    </span>
  );
};

interface RaceData {
  "Horse number": number;
  "Strength index": number;
}

const PageDisplay: React.FC = () => {
  const { t } = useTranslation();
  const envUrl = process.env.REACT_APP_API_URL;
  const [raceNumber, setRaceNumber] = useState<number>(1);
  const [maxRaceNumber, setMaxRaceNumber] = useState<number>(1);
  const [selectedRace, setSelectedRace] = useState<string[]>([]);
  const [finalPrediction, setFinalPrediction] = useState<number>(0);
  const [suggestedPrediction, setSuggestedPrediction] = useState("");
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [raceTitle, setRaceTitle] = useState("");
  const [raceDayPlace, setRaceDayPlace] = useState("");
  const [restOfDetails, setRestOfDetails] = useState("");
  const [anchorHorse, setAnchorHorse] = useState<number>(0);
  const [isMobile, setIsMobile] = useState(false);
  const [horseCombination, setHorseCombination] = useState<[]>([]);
  const [showCardContent, setShowCardContent] = useState<boolean>(true);
  const [isSchedule, setIsScheduled] = useState<boolean>(true);
  const horseTrendUrl = `${envUrl}instant-comprehensive-recommendations/`;
  const isLoggedInRedux2 = useSelector((state: RootState) => state.auth.user);
  const currentLanguage: string = useSelector(
    (state: RootState) => state.language.lng
  );
  const columns = [
    {
      title: t("trio_picks"), // Common title for both columns
      align: "center",
      children: [
        {
          width: isMobile ? 20 : 120,
          render: (_: any, record: any, index: number) => {
            // Display "膽" only for the first row, "拖" only for the second row
            if (index === 0) {
              return t("key"); // Only in the first row
            } else if (index === 2) {
              return t("with"); // Only in the second row
            } else {
              return null; // Empty for all other rows
            }
          },
        },
        {
          dataIndex: "horseNumber",
          key: "horseNumber",
          align: isMobile ? "center" : "inherit",
          render: (text: number, record: any, index: number) => {
            // Display horse numbers
            return (
              <Flex>
                <div
                  style={{
                    fontSize: isMobile ? "0.7rem" : "inherit",
                  }}
                >
                  {renderHorseNumber(text, index)}
                </div>
              </Flex>
            );
          },
        },
      ],
    },
  ];
  const columns2 = [
    {
      title: t("quartet_picks"), // Common title for both columns
      align: "center",
      children: [
        {
          width: isMobile ? 20 : 120,
          render: (_: any, record: any, index: number) => {
            // Display "膽" only for the first row, "拖" only for the second row
            if (index === 0) {
              return t("key"); // Only in the first row
            } else if (index === 2) {
              return t("with"); // Only in the second row
            } else {
              return null; // Empty for all other rows
            }
          },
        },
        {
          dataIndex: "horseNumber",
          key: "horseNumber",
          align: isMobile ? "center" : "inherit",
          render: (text: number, record: any, index: number) => {
            // Display horse numbers
            return (
              <Flex>
                <div
                  style={{
                    fontSize: isMobile ? "0.7rem" : "inherit",
                  }}
                >
                  {renderHorseNumber(text, index)}
                </div>
              </Flex>
            );
          },
        },
      ],
    },
  ];
  const tableContent = (response: any, metaRaceData: any) => {
    const responseData = response.data.data;
    const totalRaces = Object.keys(metaRaceData).length;
    setMaxRaceNumber(totalRaces);
    const selectedRace = responseData.data[raceNumber - 1];

    console.log(selectedRace.Data["Key horses"]);
    const horseNumbers = selectedRace.Data["Key horses"];
    const [jockeyNames, horseNames, trainerNames, currentRatings] =
      FetchHorseNames(raceNumber, horseNumbers, metaRaceData, currentLanguage);

    setSelectedRace(horseNames);

    setFinalPrediction(selectedRace.Data["Final prediction"]);

    setSuggestedPrediction(
      InstantComprehensiveDataAnalysisSuggestion(
        selectedRace.Data["Final prediction"]
      )
    );
    setAnchorHorse(
      responseData.data[raceNumber - 1].Data["Key combination"]["Anchor horse"]
    );
    setHorseCombination(
      responseData.data[raceNumber - 1].Data["Key combination"][
        "Secondary horses"
      ]
    );
    setIsLoading(false);
  };

  const fetchTotalMetaData = async () => {
    const [metaRaceData, totalRace] = await totalRaceMetaData();
    return [metaRaceData, totalRace];
  };

  const fetchMetaRaceDataDetails = async () => {
    const [metaRaceData, metaRaceDataInfo] = await fetchAllMetaDetailsOdds(
      currentLanguage
    );
    return [metaRaceData, metaRaceDataInfo];
  };
  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      if (isLoggedInRedux2 && isLoggedInRedux2.user.refreshToken) {
        const refreshToken = isLoggedInRedux2.user.refreshToken;

        const accessToken = await RefreshAccessToken(refreshToken);
        if (accessToken) {
          const [metaRaceData, totalRace] = await fetchTotalMetaData();
          const [
            savedMetaRaceData,
            savedMetaRaceInfoValue,
            responseValue,
            accessLevelValue,
          ] = await fetchStatisticsKingData(accessToken);

          const [metaRaceDatas, metaRaceDataInfo] =
            await fetchMetaRaceDataDetails();

          const [raceStartDate, raceStartTime] = fetchRaceStartTime(
            raceNumber,
            metaRaceData
          );

          const [savedRaceStartDate, savedRaceStartTime] = fetchRaceStartTime(
            raceNumber,
            savedMetaRaceData
          );

          const isSchedule: boolean = await InstantScheduleTimer(
            raceStartTime,
            raceStartDate,
            savedRaceStartDate,
            savedRaceStartTime
          );
          setIsScheduled(isSchedule);
          if (isSchedule) {
            const raceDayPlaceVenue = Object.keys(savedMetaRaceInfoValue)
              .filter((key: any) => !isNaN(key))
              .sort((a: any, b: any) => a - b)
              .map((key: any) => savedMetaRaceInfoValue[key])
              .join("");

            const [raceTitle, raceDayPlace, restOfDetails] =
              FetchRaceInformation(
                raceNumber,
                raceDayPlaceVenue,
                savedMetaRaceData,
                currentLanguage
              );
            {
              setRaceTitle(raceTitle);
              setRaceDayPlace(raceDayPlace);
              setRestOfDetails(restOfDetails);
            }

            if (accessLevelValue === null) {
              tableContent(responseValue, savedMetaRaceData);
            } else if (accessLevelValue !== null && raceNumber >= 2) {
              setSelectedRace([]);
              setShowCardContent(false);
              setIsLoading(false);
            } else {
              tableContent(responseValue, savedMetaRaceData);
            }
          } else {
            const raceDayPlaceVenue = Object.keys(metaRaceDataInfo)
              .filter((key: any) => !isNaN(key))
              .sort((a: any, b: any) => a - b)
              .map((key: any) => metaRaceDataInfo[key])
              .join("");

            const [raceTitle, raceDayPlace, restOfDetails] =
              FetchRaceInformation(
                raceNumber,
                raceDayPlaceVenue,
                metaRaceData,
                currentLanguage
              );
            {
              setRaceTitle(raceTitle);
              setRaceDayPlace(raceDayPlace);
              setRestOfDetails(restOfDetails);
            }
            if (accessLevelValue === null) {
              tableContent(responseValue, metaRaceData);
            } else if (accessLevelValue !== null && raceNumber >= 2) {
              setSelectedRace([]);
              setShowCardContent(false);
              setIsLoading(false);
            } else {
              tableContent(responseValue, metaRaceData);
            }
          }
        }
      }
    };
    fetchData();
  }, [raceNumber, currentLanguage]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchStatisticsKingData = async (accessToken: string) => {
    let savedMetaRaceData: string = "";
    let responseValue: any = "";
    let accessLevelValue: string | null = "";
    let savedMetaRaceInfoValue: any = "";
    await axios
      .get(horseTrendUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const accessLevel: string | null = response.data.message;
        savedMetaRaceData = response?.data?.data?.meta_data?.data?.raceMeetings;

        const [savedMetaData, savedMetaRaceInfo, savedVenueCode] =
          RaceInformationSaved(savedMetaRaceData, currentLanguage);
        savedMetaRaceData = savedMetaData;
        savedMetaRaceInfoValue = savedMetaRaceInfo;
        responseValue = response;
        accessLevelValue = accessLevel;
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
    return [
      savedMetaRaceData,
      savedMetaRaceInfoValue,
      responseValue,
      accessLevelValue,
    ];
  };
  const items = Array.from({ length: maxRaceNumber }, (_, i) => ({
    key: (i + 1).toString(),
    label: `${t("race")} ${i + 1}`,
    children: "",
  }));

  const onChange = (key: string) => {
    setRaceNumber(parseInt(key, 10));
    console.log(key);
  };
  // Map the horses array to a table data source
  const dataSource = selectedRace.slice(0, 4).map((horse, index) => ({
    key: index, // Set a unique key for each row
    horseNumber: horse,
  }));

  const dataSource2 = selectedRace.slice(0, 5).map((horse, index) => ({
    key: index, // Set a unique key for each row
    horseNumber: horse,
  }));

  return (
    <div>
      {isMobile ? <Segment value="InstantComprehensiveDataAnalysis" /> : <></>}

      {isMobile ? (
        <TitleCard
          title={t("instant_comprehensive_data_analysis")}
          date={""}
          venue={""}
          day={""}
          maxRaceNumber={maxRaceNumber}
          onChange={onChange}
        />
      ) : (
        <>
          <TitleCardDesktop
            title={t("instant_comprehensive_data_analysis")}
            date={""}
            venue={""}
            day={""}
            maxRaceNumber={maxRaceNumber}
            onChange={onChange}
          />
        </>
      )}

      <Flex
        justify="space-between"
        style={{
          marginBottom: "12px",
          paddingLeft: "4px",
          paddingRight: "4px",
        }}
      >
        <p
          style={{
            fontFamily: '"Montserrat", sans-serif',
            margin: "0px",
            fontSize: isMobile ? "0.6rem" : "0.8rem",
          }}
        >
          {" "}
          {raceTitle}
        </p>
        <p
          style={{
            fontFamily: '"Montserrat", sans-serif',
            margin: "0px",
            fontSize: isMobile ? "0.6rem" : "0.8rem",
          }}
        >
          {" "}
          {restOfDetails}
        </p>
      </Flex>

      {isLoading ? (
        <Flex justify="center" align="center">
          <Spin tip="Loading" size="large"></Spin>
        </Flex>
      ) : (
        <>
          {isSchedule ? (
            <>
              {showCardContent ? (
                <>
                  {" "}
                  <Flex justify="space-between" style={{ width: "100%" }}>
                    <Flex gap={20} style={{ width: "100%" }} vertical>
                      <Table
                        style={{ width: isMobile ? "85%" : "30%" }}
                        dataSource={dataSource}
                        columns={columns}
                        size="small"
                        pagination={false}
                        locale={{
                          emptyText: <LockedContent />,
                        }}
                        rowClassName={(_, index) => {
                          if (index === 2) {
                            return "row-odd";
                          }
                          return index % 2 === 0 ? "row-even" : "row-odd";
                        }}
                      />
                      <Table
                        style={{ width: isMobile ? "85%" : "30%" }}
                        dataSource={dataSource2}
                        columns={columns2}
                        size="small"
                        pagination={false}
                        locale={{
                          emptyText: <LockedContent />,
                        }}
                        rowClassName={(_, index) => {
                          if (index === 2) {
                            return "row-odd";
                          }
                          return index % 2 === 0 ? "row-even" : "row-odd";
                        }}
                      />
                    </Flex>
                    <Flex
                      style={{ width: isMobile ? "50%" : "max-content" }}
                      vertical
                    >
                      {" "}
                      <div>
                        <Card
                          title={
                            <span
                              style={{
                                fontSize: isMobile ? "0.8rem" : "inherit",
                              }}
                            >
                              {t("suggested_betting_amount")}
                            </span>
                          }
                          style={{
                            width: isMobile ? 180 : 300,
                            textAlign: "center",
                            marginTop: "8px",
                          }}
                        >
                          <Flex justify="center">
                            {" "}
                            <span>{suggestedPrediction}</span>
                          </Flex>
                        </Card>
                      </div>
                      <div>
                        <Card
                          title={
                            <span
                              style={{
                                fontSize: isMobile ? "0.8rem" : "inherit",
                              }}
                            >
                              {t("prediction_confidence")}
                            </span>
                          }
                          style={{
                            width: isMobile ? 180 : 300,
                            textAlign: "center",
                          }}
                        >
                          <Flex justify="center">
                            {" "}
                            <Rate
                              style={{
                                fontSize: isMobile ? "0.9rem" : "inherit",
                              }}
                              disabled
                              defaultValue={finalPrediction * 10}
                            />
                          </Flex>
                        </Card>
                      </div>
                      <div>
                        <Card
                          title={
                            <span
                              style={{
                                fontSize: isMobile ? "0.8rem" : "inherit",
                              }}
                            >
                              {t("quinella/_quinella_place")}
                            </span>
                          }
                          style={{
                            width: isMobile ? 180 : 300,
                            textAlign: "center",
                            marginTop: "8px",
                          }}
                        >
                          {anchorHorse !== null && (
                            <p>
                              <Tag color="gold">{anchorHorse}</Tag>
                              {t("with")} {"   "}
                              <span style={{ marginLeft: "3px" }}>
                                {horseCombination.map((combination, index) => (
                                  <Tag key={index} color="magenta">
                                    {combination}
                                  </Tag>
                                ))}
                              </span>
                            </p>
                          )}
                        </Card>
                      </div>
                    </Flex>
                  </Flex>
                </>
              ) : (
                <>
                  <LockedContent />
                </>
              )}
            </>
          ) : (
            <>
              <ResultsNA />
            </>
          )}
        </>
      )}
    </div>
  );
};
