import FetchRaceInformation from "../../Mapper/raceInformationMapper";

const InstantKeyHorseSetup = (
  accessLevel: string | null,
  response: any,
  metaRaceData: any,
  metaRaceDataInfo: any,
  raceNumber: number,
  currentLanguage: string,
  isScheduled: boolean,
  executeMysteryBoxContent: (
    response: any,
    metaRaceData: any,
    metaRaceDataInfo: any,
    isScheduled: boolean
  ) => void
) => {
  let raceTitle = "";
  let raceDayPlace = "";
  let restOfDetails = "";
  let showCube = false;
  let isLoading = false;
  console.log(metaRaceData);
  if (accessLevel === null) {
    executeMysteryBoxContent(
      response,
      metaRaceData,
      metaRaceDataInfo,
      isScheduled
    );
    console.log(metaRaceData);
    console.log(metaRaceDataInfo);
    const raceDayPlaceVenue = Object.keys(metaRaceDataInfo)
      .filter((key: any) => !isNaN(key))
      .sort((a: any, b: any) => a - b)
      .map((key: any) => metaRaceDataInfo[key])
      .join("");

    console.log(raceDayPlaceVenue);

    const [raceTitleMatch, raceDayPlaceMatch, restOfDetailsMatch] =
      FetchRaceInformation(
        raceNumber,
        raceDayPlaceVenue,
        metaRaceData,
        currentLanguage
      );
    raceTitle = raceTitleMatch;
    raceDayPlace = raceDayPlaceMatch;
    restOfDetails = restOfDetailsMatch;
    console.log("glow");
    showCube = true;
    isLoading = false;
  } else if (accessLevel !== null && raceNumber >= 2) {
    const raceDayPlaceVenue = Object.keys(metaRaceDataInfo)
      .filter((key: any) => !isNaN(key))
      .sort((a: any, b: any) => a - b)
      .map((key: any) => metaRaceDataInfo[key])
      .join("");

    const [raceTitleMatch, raceDayPlaceMatch, restOfDetailsMatch] =
      FetchRaceInformation(
        raceNumber,
        raceDayPlaceVenue,
        metaRaceData,
        currentLanguage
      );
    raceTitle = raceTitleMatch;
    raceDayPlace = raceDayPlaceMatch;
    restOfDetails = restOfDetailsMatch;
    showCube = false;
    isLoading = false;
  } else {
    executeMysteryBoxContent(
      response,
      metaRaceData,
      metaRaceDataInfo,
      isScheduled
    );
    showCube = true;
    isLoading = false;
  }
  return [raceTitle, raceDayPlace, restOfDetails, showCube, isLoading];
};

export default InstantKeyHorseSetup;
