import React from "react";
import { Button, Typography } from "antd";
import MainLayout from "../Layout/MainLayout";
import { useNavigate } from "react-router-dom";

const { Title, Paragraph } = Typography;

const PasswordChangedPage: React.FC = () => {
  return (
    <div>
      <MainLayout Component={PasswordChangedDisplay} />
    </div>
  );
};

export default PasswordChangedPage;

const PasswordChangedDisplay = () => {
  const navigate = useNavigate();

  const handleLoginRedirect = () => {
    navigate("/login"); // Redirects to the login page
  };

  return (
    <div
      style={{
        width: "30%",
        margin: "0 auto",
        boxShadow: "0 0 8px rgba(0, 0, 0, 0.4)",
        padding: "2rem",
        textAlign: "center",
      }}
    >
      <Title level={4} style={{ marginBottom: "16px" }}>
        Password Changed Successfully
      </Title>
      <Paragraph>
        Your password has been changed successfully. You can now use your new
        password to log in.
      </Paragraph>
      <Button
        type="primary"
        onClick={handleLoginRedirect}
        style={{ marginTop: "16px" }}
      >
        Go to Login
      </Button>
    </div>
  );
};
