import { PlusOutlined, SwapOutlined } from "@ant-design/icons";
import {
  Table,
  Typography,
  Button,
  Drawer,
  Space,
  Form,
  Row,
  Col,
  Input,
  Select,
  message,
} from "antd";
import axios from "axios";
import React, { useState, useRef } from "react";
import type { DraggableData, DraggableEvent } from "react-draggable";
import Draggable from "react-draggable";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import RefreshAccessToken from "../Authentication/RefreshToken";
import { useTranslation } from "react-i18next";

const { Title } = Typography;
const { Option } = Select;

type User = {
  id: number;
  last_login: string | null;
  is_superuser: boolean;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  is_staff: boolean;
  is_active: boolean;
  date_joined: string;
  mobile_number: string;
  is_verified: boolean;
  is_subscribed: boolean;
  verification_method: string;
  groups: number[];
  user_permissions: [];
};

type AdminUsersProps = {
  users: User[] | null; // Adjust the type as per your requirement
};

const AdminUsers = ({ users }: AdminUsersProps) => {
  const [open, setOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [form] = Form.useForm(); // Ant Design form instance
  const { t } = useTranslation();
  const envUrl = process.env.REACT_APP_API_URL;
  const addAuthorUrl = envUrl + "author/add/";
  const removeAuthorUrl = envUrl + "author/remove/";

  // Function to open the drawer and set selected user
  const showDrawer = (user: User) => {
    setSelectedUser(user);
    setOpen(true);

    // Reset form fields with the new user's data
    form.setFieldsValue({
      name: `${user.first_name} ${user.last_name}`,
      currentRole:
        user.groups.length === 0
          ? "Upick User"
          : user.groups[0] === 1
          ? "Author"
          : "Other",
      changeRole: undefined, // Reset the change role field to be empty when opening
    });
  };

  const onClose = () => {
    setOpen(false);
    setSelectedUser(null);
  };

  const isLoggedInRedux2 = useSelector((state: RootState) => state.auth.user);
  const isLoggedInRedux: boolean = useSelector(
    (state: RootState) => state.auth.isLoggedIn
  );

  const addAuthor = async (id: number) => {
    const payload = {
      user_id: id,
    };
    try {
      if (isLoggedInRedux2 && isLoggedInRedux2.user.refreshToken) {
        const refreshToken = isLoggedInRedux2.user.refreshToken;
        const accessToken = await RefreshAccessToken(refreshToken);
        if (accessToken) {
          const response = await axios.post(addAuthorUrl, payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          setLoading(false);
          console.log(response.data.message);
          message.success(response.data.message);
        }
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      message.error("something went wrong, please try again");
    }
  };

  const removeAuthor = async (id: number) => {
    const payload = {
      user_id: id,
    };
    try {
      if (isLoggedInRedux2 && isLoggedInRedux2.user.refreshToken) {
        const refreshToken = isLoggedInRedux2.user.refreshToken;
        const accessToken = await RefreshAccessToken(refreshToken);
        if (accessToken) {
          const response = await axios.post(removeAuthorUrl, payload, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
          setLoading(false);

          console.log(response.data.message);
          message.success(response.data.message);
        }
      }
    } catch (error) {
      setLoading(false);
      console.error(error);
      message.error("something went wrong, please try again");
    }
  };

  const handleFormSubmit = () => {
    setLoading(true);
    form.validateFields().then((values) => {
      if (values.changeRole != undefined) {
        console.log("Form Values:", values);
        const userId = selectedUser?.id;
        // console.log(userId);
        // Perform different operations based on selected role
        // Example API call
        if (values.currentRole === "Upick User" && values.changeRole === "2") {
          setLoading(false);
          console.log("error: user already assigned as Upick User");
          message.error("error: user already assigned as Upick User");
        } else if (
          values.currentRole === "Author" &&
          values.changeRole === "1"
        ) {
          setLoading(false);
          message.error("error: user already assigned as Author");
          console.log("error: user already assigned as Author");
        }

        if (values.changeRole === "2") {
          //remove author api ///author/remove/
          if (userId !== undefined) {
            removeAuthor(userId);
          }
        } else if (values.changeRole === "1") {
          //add author api //
          if (userId !== undefined) {
            addAuthor(userId);
          }
        }
        console.log(values.changeRole);
      }

      // setOpen(false);
    });
  };

  // Define columns for the table
  const columns = [
    {
      title: t("id"),
      dataIndex: "id",
      key: "id",
    },
    {
      title: t("last_login"),
      dataIndex: "last_login",
      key: "last_login",
    },
    {
      title: t("is_superuser"),
      dataIndex: "is_superuser",
      key: "is_superuser",
    },
    {
      title: t("username"),
      dataIndex: "username",
      key: "username",
    },
    {
      title: t("first_name"),
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: t("last_name"),
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: t("is_staff"),
      dataIndex: "is_staff",
      key: "is_staff",
    },
    {
      title: t("is_active"),
      dataIndex: "is_active",
      key: "is_active",
    },
    {
      title: t("date_joined"),
      dataIndex: "date_joined",
      key: "date_joined",
    },
    {
      title: t("mobile_number"),
      dataIndex: "mobile_number",
      key: "mobile_number",
    },
    {
      title: t("email"),
      dataIndex: "email",
      key: "email",
    },
    {
      title: t("is_verified"),
      dataIndex: "is_verified",
      key: "is_verified",
    },
    {
      title: t("is_subscribed"),
      dataIndex: "is_subscribed",
      key: "is_subscribed",
    },
    {
      title: t("verification_method"),
      dataIndex: "verification_method",
      key: "verification_method",
    },
    {
      title: t("change_role"),
      key: "changeRole",
      render: (_: any, record: User) => (
        <>
          <Button onClick={() => showDrawer(record)} icon={<SwapOutlined />} />
        </>
      ),
    },
  ];

  return (
    <div>
      <Title level={4} style={{ textAlign: "left" }}>
        {t("upick_users")}
      </Title>
      <Table columns={columns} dataSource={users} rowKey="id" />

      <Drawer
        title={t("update_user_role")}
        width={720}
        onClose={onClose}
        open={open}
        bodyStyle={{
          paddingBottom: 80,
        }}
        // footer={
        //   <Space>
        //     <Button onClick={onClose}>Cancel</Button>
        //     <Button
        //       onClick={handleFormSubmit}
        //       type="primary"
        //       htmlType="submit"
        //       block
        //     >
        //       Submit
        //     </Button>
        //   </Space>
        // }
      >
        <Form layout="vertical" form={form} onFinish={handleFormSubmit}>
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="name" label={t("name")}>
                <Input placeholder="Current Name" disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="currentRole" label={t("current_role")}>
                <Input placeholder="Current Role" disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="changeRole"
                label={t("change_role")}
                rules={[{ required: true, message: t("cannot_be_empty") }]}
                validateTrigger={["onChange", "onBlur"]} // Ensures validation triggers on change or blur events
              >
                <Select
                  style={{ width: 200 }}
                  placeholder={t("select_a_role")}
                  optionFilterProp="label"
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={[
                    { value: "1", label: "Author" },
                    { value: "2", label: "Upick User" },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Space style={{ marginTop: "110%", padding: "0px" }}>
            <Button onClick={onClose}>{t("cancel")}</Button>
            <Button type="primary" htmlType="submit" loading={loading} block>
              {t("submit")}
            </Button>
          </Space>
        </Form>
      </Drawer>
    </div>
  );
};

export default AdminUsers;
