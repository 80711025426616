import React from "react";
import { Spin, Space } from "antd";

const LoadingPage: React.FC = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#f0f2f5", // Optional background color
      }}
    >
      <Space direction="vertical" size="middle">
        <Spin size="large" tip="Loading..." />
      </Space>
    </div>
  );
};

export default LoadingPage;
