import React, { useEffect, useState } from "react";
import { Alert, Button, Form, Input, message } from "antd";
import MainLayout from "../Layout/MainLayout";
import { useNavigate, useParams } from "react-router-dom";
import { InputOTP } from "antd-input-otp";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../state/auth/authSlice";
import { RootState } from "../../store";
import RefreshAccessToken from "../Authentication/RefreshToken";
import { useTranslation } from "react-i18next";

const AccountVerification = () => {
  return (
    <div>
      <MainLayout Component={PhoneNumberVerificationDisplay} />
    </div>
  );
};

export default AccountVerification;

const PhoneNumberVerificationDisplay: React.FC = () => {
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);
  const [isRunning, setIsRunning] = useState(true);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [verificationStatus, setVerificationStatus] = useState<string | null>(
    null
  );
  const [timeLeft, setTimeLeft] = useState(120); // 120 seconds = 2 minutes

  const { username, uid } = useParams();

  const handleVerifyOtp = () => {
    setLoading(true);
    // Simulate an API call to verify the OTP
    setTimeout(() => {
      setLoading(false);
      // Simulate OTP verification result
      if (otp === "123456") {
        setVerificationStatus("Verification successful!");
      } else {
        setVerificationStatus("Invalid OTP. Please try again.");
      }
    }, 2000);
  };
  const isLoggedInRedux2 = useSelector((state: RootState) => state.auth.user);

  const [form] = Form.useForm();
  const verifyPhoneNumber = async (verifyPhoneUrl: string) => {
    try {
      const response = await axios.get(verifyPhoneUrl);
      console.log(response.data);
      message.success(t("account_verified"));

      const envUrl = process.env.REACT_APP_API_URL;
      const getCurrentUserUrl = envUrl + "users/current/";

      if (isLoggedInRedux2 && isLoggedInRedux2.user.refreshToken) {
        const refreshToken = isLoggedInRedux2.user.refreshToken;

        const accessToken = await RefreshAccessToken(refreshToken);
        const responseCurrentUser = await axios.get(getCurrentUserUrl, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        const currentUserData = responseCurrentUser.data;
        // console.log(`RefreshToken: ${response.data.user.refresh}`);
        dispatch(
          login({
            isLoggedIn: true, // Set isLoggedIn to true
            user: {
              username: currentUserData.data?.username,
              first_name: currentUserData.data?.first_name,
              last_name: currentUserData.data?.last_name,
              is_active: currentUserData.data?.is_active,
              date_joined: currentUserData.data?.date_joined,
              email: currentUserData.data?.email,
              mobile_number: currentUserData.data?.mobile_number,
              is_subscribed: currentUserData.data?.is_subscribed,
              is_verified: currentUserData.data?.is_verified,
              verification_method: currentUserData.data?.verification_method,
              groups: currentUserData.data?.groups[0],
              refreshToken: refreshToken,
              accessToken: accessToken,
            },
          })
        );

        navigate("/");
      }
    } catch (error) {
      message.error(t("something_went_wrong"));
      console.error(error);
    }
  };
  const handleFinish = (values: any) => {
    // The value will be array of string
    // Check the field if there is no value, or value is undefined/empty string
    const { otp } = values;

    if (!otp || otp.includes(undefined) || otp.includes(""))
      return form.setFields([
        {
          name: "otp",
          errors: ["OTP is invalid."],
        },
      ]);

    const envUrl = process.env.REACT_APP_API_URL;
    const verifyPhoneUrl =
      envUrl + `verify-account/?uid=${uid}&otp=${otp.join("")}`;
    verifyPhoneNumber(verifyPhoneUrl);
    console.log(`OTP: ${otp.join("")}`);
  };

  useEffect(() => {
    if (timeLeft > 0) {
      const timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000);

      return () => clearInterval(timer); // Clear the interval on component unmount
    } else {
      setIsRunning(false); // Set isRunning to false when the timer hits zero
    }
  }, [timeLeft]);

  // Format the time as MM:SS
  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${remainingSeconds
      .toString()
      .padStart(2, "0")}`;
  };
  const resendCode = async () => {
    try {
      console.log("Resending OTP code...");
      const envUrl = process.env.REACT_APP_API_URL;
      const verifyTokenUrl = envUrl + "send-verification-token/";

      // Ensure that username is not undefined
      if (!username) {
        throw new Error("Username is missing.");
      }

      const payload = {
        username: username,
      };

      // Send the OTP request
      const response = await axios.post(verifyTokenUrl, payload);
      console.log("OTP resent successfully:", response.data);

      // Reset the timer
      setTimeLeft(120);
      setIsRunning(true); // Restart the countdown
      message.success(t("otp_sent_successful"));
    } catch (error) {
      message.error(t("otp_sent_failed"));
    }
  };
  return (
    <div
      style={{
        padding: "20px",
        maxWidth: "600px",
        margin: "0 auto",
        textAlign: "center",
      }}
    >
      <Alert
        message={t("account_verification")}
        description={
          <div>
            {t("account_verification_description_first_half")}
            <strong>{username}</strong>
            {t("account_verification_description_second_half")}
          </div>
        }
        type="info"
        showIcon
        style={{ marginBottom: "20px" }}
      />
      <section className="card">
        <Form form={form} onFinish={handleFinish}>
          <Form.Item
            name="otp"
            className="center-error-message"
            rules={[{ validator: async () => Promise.resolve() }]}
          >
            <InputOTP
              autoFocus
              inputType="alphabet-numeric"
              length={6}
              style={{
                width: "100%",
                height: "40px", // Adjust the height of each input
              }}
            />
          </Form.Item>

          <Form.Item noStyle>
            <p style={{ color: "red" }}>{formatTime(timeLeft)}</p>
            <Button block htmlType="submit" type="primary">
              {t("submit")}
            </Button>
          </Form.Item>
        </Form>
        {!isRunning && (
          <Button
            block
            onClick={() => {
              resendCode(); // Call the async function
            }}
            style={{ marginTop: "4%" }}
          >
            {t("resend_code")}
          </Button>
        )}
      </section>
      {verificationStatus && (
        <Alert
          message={verificationStatus}
          type={
            verificationStatus.startsWith("Verification successful")
              ? "success"
              : "error"
          }
          showIcon
          style={{ marginTop: "20px" }}
        />
      )}
    </div>
  );
};
