import React, { useEffect, useRef, useState } from "react";
import "../../styles/CubeComponent.css"; // Make sure to import the CSS file
import Horse from "../../pictures/equestrian (2).png";
import { Badge, Flex } from "antd";

interface CubeComponentProps {
  keyHorse: number;
  openMysteryBox: () => void; // Update with the correct type for your function
  closeMysteryBox: () => void; // Update with the correct type for your function
  newTab: any; // Update with a specific type if possible
  firstTabChange: boolean; // Assuming this is a boolean
}

const CubeComponent: React.FC<CubeComponentProps> = ({
  keyHorse,
  openMysteryBox,
  closeMysteryBox,
  newTab,
  firstTabChange,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [c, setC] = useState<number>(0);
  const [showBadge, setShowBadge] = useState<boolean>(false);

  const cubeRef = useRef<HTMLDivElement | null>(null);
  const cbackRef = useRef<HTMLDivElement | null>(null);
  const ctopRef = useRef<HTMLDivElement | null>(null);
  const cleftRef = useRef<HTMLDivElement | null>(null);
  const crightRef = useRef<HTMLDivElement | null>(null);
  const glowRef = useRef<HTMLDivElement | null>(null);
  const powerupRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    closeCube();

    const timer = setTimeout(() => {
      openCube();
    }, 100); // Delay of 1 second (1000 ms)

    // Clear the timer if the component unmounts or dependencies change
    return () => clearTimeout(timer);
  }, [newTab, firstTabChange]);

  const openCube = () => {
    if (isOpen) {
      closeMysteryBox();
    }
    if (!isOpen) {
      award();
      setShowBadge(true);
      openMysteryBox();
      if (ctopRef.current)
        ctopRef.current.style.transform = "translateY(-3rem)";
      if (cleftRef.current)
        cleftRef.current.style.transform = "translateX(-3rem)";
      if (crightRef.current)
        crightRef.current.style.transform = "translateX(3rem)";
      if (ctopRef.current) ctopRef.current.style.opacity = "0.1";
      if (cleftRef.current) cleftRef.current.style.opacity = "0.1";
      if (crightRef.current) crightRef.current.style.opacity = "0.1";
      if (cbackRef.current) cbackRef.current.style.opacity = "0.1";
      if (glowRef.current) glowRef.current.style.opacity = "0.5";
      if (powerupRef.current) {
        powerupRef.current.style.opacity = "1";
        powerupRef.current.style.zIndex = "8";
        powerupRef.current.style.height = "80px";
        powerupRef.current.style.width = "80px";
      }
      if (cubeRef.current) cubeRef.current.style.animationPlayState = "paused";
      setIsOpen(true);
    } else {
      if (ctopRef.current) ctopRef.current.style.transform = "translateY(0)";
      if (cleftRef.current) cleftRef.current.style.transform = "translateX(0)";
      if (crightRef.current)
        crightRef.current.style.transform = "translateX(0)";
      if (cubeRef.current) cubeRef.current.style.opacity = "1";
      if (ctopRef.current) ctopRef.current.style.opacity = "1";
      if (cleftRef.current) cleftRef.current.style.opacity = "1";
      if (crightRef.current) crightRef.current.style.opacity = "1";
      if (cbackRef.current) cbackRef.current.style.opacity = "1";
      if (glowRef.current) glowRef.current.style.opacity = "1";
      if (powerupRef.current) {
        powerupRef.current.style.opacity = "0";
        powerupRef.current.style.zIndex = "0";
        powerupRef.current.style.height = "48px";
        powerupRef.current.style.width = "48px";
      }
      if (cubeRef.current) cubeRef.current.style.animationPlayState = "running";
      changeVar("rgba(255,195,26,0.4)");
      setIsOpen(false);
    }
  };

  const closeCube = () => {
    if (isOpen) {
      closeMysteryBox();
    }

    if (ctopRef.current) ctopRef.current.style.transform = "translateY(0)";
    if (cleftRef.current) cleftRef.current.style.transform = "translateX(0)";
    if (crightRef.current) crightRef.current.style.transform = "translateX(0)";
    if (cubeRef.current) cubeRef.current.style.opacity = "1";
    if (ctopRef.current) ctopRef.current.style.opacity = "1";
    if (cleftRef.current) cleftRef.current.style.opacity = "1";
    if (crightRef.current) crightRef.current.style.opacity = "1";
    if (cbackRef.current) cbackRef.current.style.opacity = "1";
    if (glowRef.current) glowRef.current.style.opacity = "1";
    if (powerupRef.current) {
      powerupRef.current.style.opacity = "0";
      powerupRef.current.style.zIndex = "0";
      powerupRef.current.style.height = "48px";
      powerupRef.current.style.width = "48px";
    }
    if (cubeRef.current) cubeRef.current.style.animationPlayState = "running";
    changeVar("rgba(255,195,26,0.4)");
    setIsOpen(false);
  };

  const changeVar = (glow: string) => {
    document.documentElement.style.setProperty("--glow", glow);
  };

  const award = () => {
    if (powerupRef.current) {
      if (c % 2 === 0) {
        powerupRef.current.style.backgroundImage = `url(${Horse})`;
        changeVar("rgba(69,185,251,0.33)");
      } else {
        powerupRef.current.style.backgroundImage = `url(${Horse})`;
        changeVar("rgba(246,6,120,0.4)");
      }
      setC(c + 1);
    }
  };

  return (
    <>
      <Flex style={{ width: "100%" }} justify="center">
        <div className="bodydiv">
          <div className="bg-black">
            <div
              id="cube"
              className="cube-container"
              onClick={openCube}
              ref={cubeRef}
            >
              <div className="hexagon" ref={glowRef}></div>
              <div className="cube back" ref={cbackRef}></div>
              <div className="cube top" ref={ctopRef}></div>
              <div className="cube left" ref={cleftRef}></div>
              <div className="cube right" ref={crightRef}></div>

              <div className="powerup" ref={powerupRef}>
                <Flex justify="center">
                  {showBadge && (
                    <Badge
                      count={keyHorse}
                      style={{
                        backgroundColor: "orange",
                        marginTop: "380%",
                        fontSize: "16px",
                        fontWeight: "bolder",
                      }}
                    ></Badge>
                  )}
                </Flex>
              </div>
            </div>
          </div>
        </div>
      </Flex>
    </>
  );
};

export default CubeComponent;
