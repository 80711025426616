import React from "react";
import defaultCard from "../../pictures/default-upick-article-cover.jpg";
import { Card } from "antd";
import Meta from "antd/es/card/Meta";
import { useNavigate } from "react-router-dom";
type ArticleProps = {
  id: number;
  title: string | null;
  paragraph: string | null;
  author: {
    username: string;
    email: string;
    first_name: string | null;
    last_name: string | null;
    id: number;
  } | null;
  embedded_link: string | null;
  article_picture: string | null;
  article_banner: string | null;
  post_date_time: string | null;
  status: string;
};

const ArticleCardHome = ({
  id,
  title,
  paragraph,
  article_picture,
}: ArticleProps) => {
  const maxTitleLength = 20; // Adjust as needed
  const maxParagraphLength = 50; // Adjust as needed
  const navigate = useNavigate();
  const truncateText = (text: string | null, maxLength: number): string => {
    if (!text) return ""; // Return empty string if text is null
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + "...";
  };
  const handleCardClick = () => {
    navigate(`/writer-insights/${id}`);
  };
  return (
    <div className="article_card" style={{ width: "300px" }}>
      <Card
        hoverable
        style={{
          width: 300,
          backgroundColor: "rgba(240, 242, 242)",
          height: 300,
        }}
        cover={
          <img
            alt="article_picture"
            src={article_picture ? article_picture : defaultCard}
            style={{ height: "200px" }}
          />
        }
        onClick={handleCardClick}
      >
        <Meta
          title={truncateText(title, maxTitleLength)}
          description={truncateText(paragraph, maxParagraphLength)}
        />
      </Card>
    </div>
  );
};

export default ArticleCardHome;
