import React, { useEffect, useState } from "react";
import MainLayout from "../Layout/MainLayout";
import { Typography, Button, message } from "antd";
import {
  BulbFilled,
  BankFilled,
  FundOutlined,
  PieChartOutlined,
  ClockCircleOutlined,
  CalendarOutlined,
} from "@ant-design/icons";
import "../../styles/PaymentPlans.css";
import axios from "axios";
import RefreshAccessToken from "../Authentication/RefreshToken";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useTranslation } from "react-i18next";

const { Title, Paragraph } = Typography;

const PaymentPlans = () => {
  return (
    <div>
      <MainLayout Component={PageDisplay} />
    </div>
  );
};
export default PaymentPlans;
//daily, monthly, quarterly, yearly
const PageDisplay: React.FC = () => {
  const isLoggedInRedux2 = useSelector((state: RootState) => state.auth.user);
  const envUrl = process.env.REACT_APP_API_URL;
  const refreshToken = isLoggedInRedux2?.user.refreshToken;
  const [sessionId, setSessionId] = useState<string>("");
  const { t } = useTranslation();

  const dailyPlan = async () => {
    const accessToken = await RefreshAccessToken(refreshToken);
    const payload = {
      lookup_key: "standard_daily",
    };
    const url = envUrl + "create-checkout-session/";
    try {
      const response = await axios.post(url, payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const checkoutUrl = response.data.data.checkout_url;
      if (checkoutUrl) {
        // Redirect to the external URL
        window.location.href = checkoutUrl;
      }

      setSessionId(response.data.data.session_id);
    } catch (error) {
      message.error(t("verify_account_payment"));
    }
  };
  const monthlyPlan = async () => {
    const accessToken = await RefreshAccessToken(refreshToken);
    const payload = {
      lookup_key: "standard_monthly",
    };
    const url = envUrl + "create-checkout-session/";
    try {
      const response = await axios.post(url, payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const checkoutUrl = response.data.data.checkout_url;
      if (checkoutUrl) {
        // Redirect to the external URL
        window.location.href = checkoutUrl;
      }
    } catch (error) {
      message.error(t("verify_account_payment"));
    }
  };
  const quarterlyPlan = async () => {
    const accessToken = await RefreshAccessToken(refreshToken);
    const payload = {
      lookup_key: "standard_quarterly",
    };
    const url = envUrl + "create-checkout-session/";
    try {
      const response = await axios.post(url, payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const checkoutUrl = response.data.data.checkout_url;
      if (checkoutUrl) {
        // Redirect to the external URL
        window.location.href = checkoutUrl;
      }
    } catch (error) {
      message.error(t("verify_account_payment"));
    }
  };
  const yearlyPlan = async () => {
    const accessToken = await RefreshAccessToken(refreshToken);
    const payload = {
      lookup_key: "standard_yearly",
    };
    const url = envUrl + "create-checkout-session/";
    try {
      const response = await axios.post(url, payload, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const checkoutUrl = response.data.data.checkout_url;
      if (checkoutUrl) {
        window.location.href = checkoutUrl;
      }
    } catch (error) {
      message.error(t("verify_account_payment"));
    }
  };
  return (
    <div>
      <h1 className="paymentTitle">{t("plan_title")}</h1>
      <div className="paymentPlansContainers">
        <div className="plans" id="basicPlan">
          <ClockCircleOutlined className="icon" />
          <h2 className="planTitle">{t("daily_plan")}</h2>
          <Paragraph className="planParagraph">
            {t("daily_plan_description")}
          </Paragraph>
          <p>
            <h1
              style={{
                display: "inline",
                fontSize: "35px",
                color: "rgb(87, 87, 87)",
              }}
            >
              HK$ 20
            </h1>
          </p>
          <Button type="primary" onClick={dailyPlan}>
            {t("buy_now")}
          </Button>
        </div>
        <div className="plans" id="premiumPlan">
          <CalendarOutlined className="icon" />
          <h2 className="planTitle">{t("monthly_plan")}</h2>
          <Paragraph className="planParagraph">
            {t("monthly_plan_description")}
          </Paragraph>
          <p>
            <h1
              style={{
                display: "inline",
                fontSize: "35px",
                color: "rgb(87, 87, 87)",
              }}
            >
              HK$ 100
            </h1>
          </p>
          <Button type="primary" onClick={monthlyPlan}>
            {t("buy_now")}
          </Button>
        </div>
        <div className="plans" id="premiumPlan">
          <PieChartOutlined className="icon" />
          <h2 className="planTitle">{t("quarterly_plan")}</h2>
          <Paragraph className="planParagraph">
            {t("quarterly_plan_description")}
          </Paragraph>
          <p>
            <h1
              style={{
                display: "inline",
                fontSize: "35px",
                color: "rgb(87, 87, 87)",
              }}
            >
              HK $270
            </h1>
          </p>
          <Button type="primary" onClick={quarterlyPlan}>
            {t("buy_now")}
          </Button>
        </div>
        <div className="plans" id="premiumPlan">
          <BankFilled className="icon" />
          <h2 className="planTitle">{t("yearly_plan")}</h2>
          <Paragraph className="planParagraph">
            {t("yearly_plan_description")}
          </Paragraph>
          <p>
            <h1
              style={{
                display: "inline",
                fontSize: "35px",
                color: "rgb(87, 87, 87)",
              }}
            >
              HK$ 1080
            </h1>
          </p>
          <Button type="primary" onClick={yearlyPlan}>
            {t("buy_now")}
          </Button>
        </div>
      </div>
    </div>
  );
};
