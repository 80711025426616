import fetchAllMetaDetailsOdds from "./metaDataRaceInformation";

const totalRaceMetaData = async () => {
  const [metaRaceData, metaRaceDataInfo] = await fetchAllMetaDetailsOdds();
  const totalRaces = Object.keys(metaRaceData).length;
  const totalRace = totalRaces;
  console.log(totalRace);
  return [metaRaceData, totalRace];
};

export default totalRaceMetaData;
