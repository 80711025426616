import React from "react";
import { Alert, Button } from "antd";
import { useState } from "react";
import MainLayout from "../Layout/MainLayout";
import { useParams } from "react-router-dom";

const EmailSentPage = () => {
  return (
    <div>
      <MainLayout Component={EmailSentDisplay} />
    </div>
  );
};

export default EmailSentPage;

const EmailSentDisplay: React.FC = () => {
  const [resendLoading, setResendLoading] = useState(false);
  const { email, uid } = useParams();
  const handleResendEmail = () => {
    setResendLoading(true);
    // Simulate an API call to resend the email
    setTimeout(() => {
      setResendLoading(false);
      alert("Verification email resent successfully.");
    }, 2000);
  };

  return (
    <div
      style={{
        padding: "20px",
        maxWidth: "600px",
        margin: "0 auto",
        textAlign: "center",
      }}
    >
      <Alert
        message="Email Sent"
        description={
          <div>
            An email has been sent to <strong>{email}</strong>. Please check
            your inbox and verify your email address.
          </div>
        }
        type="info"
        showIcon
        style={{ marginBottom: "20px" }}
      />
      <Button
        type="primary"
        loading={resendLoading}
        onClick={handleResendEmail}
      >
        Resend Verification Email
      </Button>
    </div>
  );
};
