import React, { useEffect } from "react";
import { Card, Button, Typography } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MainLayout from "../Layout/MainLayout";
import axios from "axios";

const { Title, Paragraph } = Typography;

// const AccountVerified = () => {
//   return (
//     <div>
//       <MainLayout Component={AccountVerifiedDisplay} />
//     </div>
//   );
// };

const PaymentSuccess: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // Extract query parameters from the URL
  const searchParams = new URLSearchParams(location.search);
  const uid = searchParams.get("uid");
  const token = searchParams.get("token");

  const verifyEmail = async () => {
    const uid = searchParams.get("uid");
    const token = searchParams.get("token");
    const envUrl = process.env.REACT_APP_API_URL;
    const verifyEmailUrl = envUrl + `verify-email/?uid=${uid}&token=${token}`;
    try {
      const response = await axios.get(verifyEmailUrl);
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    verifyEmail();
    console.log(uid, token);
  }, []);

  const handleContinueClick = () => {
    navigate("/");
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Card
        style={{
          maxWidth: 400,
          textAlign: "center",
          borderRadius: 8,
          boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
        }}
      >
        <CheckCircleOutlined style={{ fontSize: "48px", color: "#52c41a" }} />
        <Title level={3} style={{ marginTop: 20 }}>
          Account Verified!
        </Title>
        <Paragraph>
          Your payment for subscription was successful. You can now access all
          Upick features the features.
        </Paragraph>
        <Button
          type="primary"
          onClick={handleContinueClick}
          style={{ marginTop: 20 }}
        >
          Continue to UPICK
        </Button>
      </Card>
    </div>
  );
};

export default PaymentSuccess;
