import React, { useEffect, useState } from "react";
import MainLayout from "../Layout/MainLayout";
import { Flex, Spin, Table } from "antd";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import RefreshAccessToken from "../Authentication/RefreshToken";
import { useTranslation } from "react-i18next";
import totalRaceMetaData from "../CommonApiCalls/totalRace";
import LockedContent from "../LockedContent/LockedContent";
import TitleCardDesktop from "./Responsive Title/TitleCardDesktop";
import TitleCard from "./Responsive Title/TitleCard";
import Segment from "./Responsive Title/Segment";
import fetchRaceStartTime from "../Mapper/raceSchedule";
import InstantScheduleTimer from "../OutputScheduler/InstantSchedular";
import ResultsNA from "./Dependencies/ResultsNA";
import fetchAllMetaDetailsOdds from "../CommonApiCalls/metaDataRaceInformation";
import FetchRaceInformation from "../Mapper/raceInformationMapper";
import RaceInformationSaved from "../OutputScheduler/RaceInformationSaved";
import FetchHorseNames from "./Dependencies/FetchNameInstantHorseTrend";

const InstantHorseTrendRecommendation: React.FC = () => {
  return (
    <div>
      <MainLayout Component={PageDisplay} />
    </div>
  );
};

export default InstantHorseTrendRecommendation;
const getOrdinal = (n: number) => {
  const s = ["th", "st", "nd", "rd"];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

const renderHorseNumber = (text: string, index: number) => {
  const ordinal = getOrdinal(index + 1);

  return (
    <span style={{ textAlign: "center" }}>
      {index + 1}
      <sup>{ordinal.slice(-2)}</sup> :{" "}
      <span dangerouslySetInnerHTML={{ __html: text }} />
    </span>
  );
};

const PageDisplay: React.FC = () => {
  const { t } = useTranslation();
  const [refreshToken, setRefreshToken] = useState<string>("");
  const envUrl = process.env.REACT_APP_API_URL;
  const [raceTitle, setRaceTitle] = useState("");
  const [raceDayPlace, setRaceDayPlace] = useState("");
  const [restOfDetails, setRestOfDetails] = useState("");
  const [raceNumber, setRaceNumber] = useState<number>(1);
  const [maxRaceNumber, setMaxRaceNumber] = useState<number>(1);
  const [selectedRace, setSelectedRace] = useState<string[]>([]);
  const [isMobile, setIsMobile] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSchedule, setIsScheduled] = useState<boolean>(false);
  const horseTrendUrl = `${envUrl}instant-trend-recommendations/`;
  const isLoggedInRedux2 = useSelector((state: RootState) => state.auth.user);
  const currentLanguage: string = useSelector(
    (state: RootState) => state.language.lng
  );
  const columns = [
    {
      title: t("rank"),
      dataIndex: "horseNumber",
      key: "horseNumber",
      align: "center",
      render: (text: number, record: any, index: number) => (
        <div
          style={{
            textAlign: "center",
            fontSize: isMobile ? "0.7rem" : "inherit",
          }}
        >
          {renderHorseNumber(text, index)}
        </div>
      ),
    },
  ];
  const tableContent = (response: any, metaRaceData: any) => {
    const responseData = response.data.data;

    console.log(
      responseData.meta_data.data.raceMeetings[0].races[raceNumber - 1].runners
    );

    const horseNumbers = responseData.data[raceNumber - 1].Horses;
    const [
      jockeyNames,
      horseNames,
      trainerNames,
      currentRatings,
      horseColorCode,
    ] = FetchHorseNames(
      raceNumber,
      horseNumbers,
      metaRaceData,
      currentLanguage
    );
    setSelectedRace(horseNames);
    console.log(horseColorCode);
    console.log(horseNames);

    setIsLoading(false);
  };

  const fetchTotalMetaData = async () => {
    const [metaRaceData, totalRace] = await totalRaceMetaData();
    return [metaRaceData, totalRace];
  };

  const fetchMetaRaceDataDetails = async () => {
    const [metaRaceData, metaRaceDataInfo] = await fetchAllMetaDetailsOdds(
      currentLanguage
    );
    return [metaRaceData, metaRaceDataInfo];
  };
  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      if (isLoggedInRedux2 && isLoggedInRedux2.user.refreshToken) {
        const refreshToken = isLoggedInRedux2.user.refreshToken;
        setRefreshToken(refreshToken);
        const [metaRaceData, metaRaceDataInfo] =
          await fetchMetaRaceDataDetails();
        const accessToken = await RefreshAccessToken(refreshToken);
        if (accessToken) {
          const [metaRaceData, totalRace] = await fetchTotalMetaData();

          const [
            savedMetaRaceData,
            savedMetaRaceInfoValue,
            responseValue,
            accessLevelValue,
          ] = await fetchStatisticsKingData(accessToken);
          // const isSchedule: boolean = await InstantScheduleTimer(
          //   raceStartTime,
          //   raceStartDate
          // );
          const [raceStartDate, raceStartTime] = fetchRaceStartTime(
            raceNumber,
            metaRaceData
          );

          const [savedRaceStartDate, savedRaceStartTime] = fetchRaceStartTime(
            raceNumber,
            savedMetaRaceData
          );

          const isSchedule: boolean = await InstantScheduleTimer(
            raceStartTime,
            raceStartDate,
            savedRaceStartDate,
            savedRaceStartTime
          );

          if (isSchedule) {
            const totalRaces = Object.keys(savedMetaRaceData).length;
            setMaxRaceNumber(totalRaces);
            const raceDayPlaceVenue = Object.keys(savedMetaRaceInfoValue)
              .filter((key: any) => !isNaN(key))
              .sort((a: any, b: any) => a - b)
              .map((key: any) => savedMetaRaceInfoValue[key])
              .join("");

            const [raceTitle, raceDayPlace, restOfDetails] =
              FetchRaceInformation(
                raceNumber,
                raceDayPlaceVenue,
                savedMetaRaceData,
                currentLanguage
              );
            {
              setRaceTitle(raceTitle);
              setRaceDayPlace(raceDayPlace);
              setRestOfDetails(restOfDetails);
            }

            if (accessLevelValue === null) {
              tableContent(responseValue, savedMetaRaceData);
            } else if (accessLevelValue !== null && raceNumber >= 2) {
              setSelectedRace([]);
              setIsLoading(false);
            } else {
              tableContent(responseValue, savedMetaRaceData);
            }
          } else {
            const totalRaces = Object.keys(metaRaceData).length;
            setMaxRaceNumber(totalRaces);
            const raceDayPlaceVenue = Object.keys(metaRaceDataInfo)
              .filter((key: any) => !isNaN(key))
              .sort((a: any, b: any) => a - b)
              .map((key: any) => metaRaceDataInfo[key])
              .join("");
            const [raceTitle, raceDayPlace, restOfDetails] =
              FetchRaceInformation(
                raceNumber,
                raceDayPlaceVenue,
                metaRaceData,
                currentLanguage
              );
            {
              setRaceTitle(raceTitle);
              setRaceDayPlace(raceDayPlace);
              setRestOfDetails(restOfDetails);
            }

            if (accessLevelValue === null) {
              tableContent(responseValue, metaRaceData);
            } else if (accessLevelValue !== null && raceNumber >= 2) {
              setSelectedRace([]);
              setIsLoading(false);
            } else {
              tableContent(responseValue, metaRaceData);
            }
          }

          setIsScheduled(isSchedule);
        }
      }
    };
    fetchData();
  }, [raceNumber, currentLanguage]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const fetchStatisticsKingData = async (accessToken: string) => {
    let savedMetaRaceData: string = "";
    let responseValue: any = "";
    let accessLevelValue: string | null = "";
    let savedMetaRaceInfoValue: any = "";
    await axios
      .get(horseTrendUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const accessLevel: string | null = response.data.message;
        savedMetaRaceData = response?.data?.data?.meta_data?.data?.raceMeetings;

        const [savedMetaData, savedMetaRaceInfo, savedVenueCode] =
          RaceInformationSaved(savedMetaRaceData, currentLanguage);
        savedMetaRaceData = savedMetaData;
        savedMetaRaceInfoValue = savedMetaRaceInfo;
        responseValue = response;
        accessLevelValue = accessLevel;
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
    return [
      savedMetaRaceData,
      savedMetaRaceInfoValue,
      responseValue,
      accessLevelValue,
    ];
  };
  const items = Array.from({ length: maxRaceNumber }, (_, i) => ({
    key: (i + 1).toString(),
    label: `${t("race")} ${i + 1}`,
    children: "",
  }));

  const onChange = (key: string) => {
    setRaceNumber(parseInt(key, 10));
    console.log(key);
  };
  const dataSource = selectedRace.map((horse, index) => ({
    key: index,
    horseNumber: horse,
  }));

  return (
    <div>
      {isMobile ? <Segment value="InstantHorseTrendRecommendation" /> : <></>}
      {isMobile ? (
        <TitleCard
          title={t("instant_horse_trend_recommendation")}
          date={""}
          venue={""}
          day={""}
          maxRaceNumber={maxRaceNumber}
          onChange={onChange}
        />
      ) : (
        <>
          <TitleCardDesktop
            title={t("instant_horse_trend_recommendation")}
            date={""}
            venue={""}
            day={""}
            maxRaceNumber={maxRaceNumber}
            onChange={onChange}
          />
        </>
      )}

      <Flex
        justify="space-between"
        style={{
          marginBottom: "12px",
          paddingLeft: "4px",
          paddingRight: "4px",
        }}
      >
        <p
          style={{
            fontFamily: '"Montserrat", sans-serif',
            margin: "0px",
            fontSize: isMobile ? "0.6rem" : "0.8rem",
          }}
        >
          {" "}
          {raceTitle}
        </p>
        <p
          style={{
            fontFamily: '"Montserrat", sans-serif',
            margin: "0px",
            fontSize: isMobile ? "0.6rem" : "0.8rem",
          }}
        >
          {" "}
          {restOfDetails}
        </p>
      </Flex>

      {isLoading ? (
        <Flex justify="center" align="center">
          <Spin tip="Loading" size="large"></Spin>
        </Flex>
      ) : (
        <>
          {isSchedule ? (
            <>
              {" "}
              <Flex justify="center" style={{ width: "100%" }}>
                <Table
                  dataSource={dataSource}
                  columns={columns}
                  size="small"
                  style={{ width: isMobile ? "inherit" : "20%" }}
                  pagination={false}
                  locale={{
                    emptyText: <LockedContent />,
                  }}
                  rowClassName={(_, index) =>
                    index % 2 === 0 ? "row-even" : "row-odd"
                  }
                />
              </Flex>
            </>
          ) : (
            <>
              <ResultsNA />
            </>
          )}
        </>
      )}
    </div>
  );
};
