// Will have information for everything about this logged in user like
// 1. User profile picture , 2. Username, 3. Real Name, 4. Date of birth, 5. Email (if not available urge user to have email), 6. Phone number (if not available urge user to add)
// 7. Subscribed user or not, change password option (for users to change password).
import React, { useEffect, useState } from "react";
import { Typography } from "antd";
import MainLayout from "../Layout/MainLayout";
import "../../styles/AccountInformation.css";
import { useSelector } from "react-redux";
import { selectUser } from "../../state/selector/selectors";
import axios from "axios";
import RefreshAccessToken from "../Authentication/RefreshToken";
import { RootState } from "../../store";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

const AccountInformation: React.FC = () => {
  return (
    <div>
      <MainLayout Component={PageDisplay} />
    </div>
  );
};

export default AccountInformation;

const PageDisplay: React.FC = () => {
  const user = useSelector(selectUser);
  const envUrl = process.env.REACT_APP_API_URL;
  const getCurrentUserUrl = envUrl + "users/current/";
  const isLoggedInRedux2 = useSelector((state: RootState) => state.auth.user);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userName, setUserName] = useState("");
  const { t } = useTranslation();
  const [currentPlan, setCurrentPlan] = useState(false);
  const [expirationDate, setExpirationDate] = useState("");

  const GetCurrentUser = async () => {
    const refreshToken = isLoggedInRedux2?.user.refreshToken;
    const accessToken = await RefreshAccessToken(refreshToken);
    if (accessToken) {
      const response = await axios.get(getCurrentUserUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const responseCurrentUser = response?.data?.data;
      setFirstName(responseCurrentUser.first_name);
      setLastName(responseCurrentUser.last_name);
      setUserName(responseCurrentUser.username);
      setCurrentPlan(responseCurrentUser.is_subscribed);
      setExpirationDate(responseCurrentUser.subscription_end_date);
    }
  };

  useEffect(() => {
    const getCurrentUser = async () => {
      await GetCurrentUser();
    };
    getCurrentUser();
  }, []);

  const formatDate = (dateString: string) => {
    if (!dateString) return "";

    const date = new Date(dateString);

    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: false,
      timeZone: "Asia/Hong_Kong",
    };
    return date.toLocaleString("en-US", options);
  };
  const getRole = () => {
    if (user?.user?.groups === 1) {
      return "Author";
    } else if (user?.user?.is_superuser) {
      return "Admin";
    }
    return null; // For customers, we return null
  };
  const role = getRole();
  return (
    <div>
      <Title
        level={3}
        style={{
          paddingTop: "0",
          margin: "0",
          paddingBottom: "16px",
          textAlign: "center",
        }}
      >
        {t("account_details")}
      </Title>
      <div className="account">
        <div className="detail">
          <p className="content_title">{t("first_name")}</p>
          <p className="content">{firstName}</p>
        </div>
        <div className="detail">
          <p className="content_title">{t("last_name")}</p>
          <p className="content">{lastName}</p>
        </div>
        <div className="detail">
          <p className="content_title">{t("username")}</p>
          <p className="content">{userName}</p>
        </div>
        <div className="detail">
          <p className="content_title">{t("current_plan")}:</p>
          <p className="content">
            {" "}
            {currentPlan === false ? (
              <>{t("free_tier")}</>
            ) : (
              <>{t("premium_tier")}</>
            )}
          </p>
        </div>
        <div className="detail">
          <p className="content_title">{t("expiration_date")}</p>
          <p className="content">
            {" "}
            {user?.user.subscription_end_date === null ? (
              <>N/A</>
            ) : (
              <>{formatDate(expirationDate)}</>
            )}
          </p>
        </div>
        {role && (
          <div className="detail">
            <p className="content_title">{t("role")}</p>
            <p className="content">{role}</p>
          </div>
        )}
      </div>
    </div>
  );
};
