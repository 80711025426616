// Core i18next library.
import i18n from "i18next";
// Bindings for React: allow components to
// re-render when language changes.
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import { store } from "../store";

i18n
  .use(HttpApi)
  // Add React bindings as a plugin.
  .use(initReactI18next)
  // Initialize the i18next instance.
  .init({
    lng: store.getState().language.lng,
    fallbackLng: "zh",
    debug: true,
    backend: {
      loadPath: "/locales/{{lng}}.json", // Path to load translations
    },
    interpolation: {
      escapeValue: false,
    },
  });

store.subscribe(() => {
  const currentLanguage = store.getState().language.lng;
  if (i18n.language !== currentLanguage) {
    i18n.changeLanguage(currentLanguage); // Change language when Redux state changes
  }
});

export default i18n;
