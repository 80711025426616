import axios from "axios";

const envUrl = process.env.REACT_APP_API_URL;
const allAuthorsUrl = envUrl + "author/users/";
type User = {
  id: number;
  last_login: string | null;
  is_superuser: boolean;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  is_staff: boolean;
  is_active: boolean;
  date_joined: string;
  mobile_number: string;
  is_verified: boolean;
  is_subscribed: boolean;
  verification_method: string;
  groups: [];
  user_permissions: [];
};
const GetAllAuthors = async (accessToken: string): Promise<User[] | null> => {
  try {
    const response = await axios.get(allAuthorsUrl, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    console.log(response.data);
    return response.data.data as User[];
  } catch (error) {
    console.log("Unexpected Error During Fetching Articles:", error);
    return null;
  }
};

export default GetAllAuthors;
