const EightPMSchedule = async (
  raceStartTimeString: string,
  raceStartDate: string,
  raceStartDateSaved: string,
  raceStartTimeStringSaved: string
) => {
  const currentDate = new Date();
  let isSchedule: boolean = false;

  // Get the current date and time in Hong Kong timezone
  const currentHongKongDateUnformatted = currentDate.toLocaleDateString(
    "en-US",
    {
      timeZone: "Asia/Hong_Kong",
    }
  );
  const [month, day, year] = currentHongKongDateUnformatted.split("/");
  const currentHongKongDateFormatted = `${year}-${month.padStart(
    2,
    "0"
  )}-${day.padStart(2, "0")}`;

  const currentHongKongTimeString = currentDate.toLocaleTimeString("en-US", {
    hour12: false,
    timeZone: "Asia/Hong_Kong",
  });

  //   console.log(`This is race start date: ${raceStartDate}`);
  //   console.log("Current Hong Kong Date: ", currentHongKongDateFormatted);
  //   console.log("Current Hong Kong Time: ", currentHongKongTimeString);

  // Calculate the day before the race start date
  const raceStartDateTime = new Date(`${raceStartDate}T${raceStartTimeString}`);
  const dayBeforeRaceDate = new Date(raceStartDateTime);
  dayBeforeRaceDate.setDate(raceStartDateTime.getDate() - 1); // Subtract one day
  const dayBeforeRaceFormatted = dayBeforeRaceDate.toISOString().split("T")[0];

  const raceStartDateTimeSaved = new Date(
    `${raceStartDateSaved}T${raceStartTimeStringSaved}`
  );
  const dayAfterRaceDate = new Date(raceStartDateTimeSaved);
  dayAfterRaceDate.setDate(raceStartDateTimeSaved.getDate() + 1);
  const dayAfterRaceFormatted = dayAfterRaceDate.toISOString().split("T")[0];

  if (
    currentHongKongDateFormatted >= dayBeforeRaceFormatted ||
    currentHongKongDateFormatted <= dayAfterRaceFormatted
  ) {
    const currentHongKongDateTime = new Date(
      `${dayBeforeRaceFormatted}T${currentHongKongTimeString}`
    );
    const eightPMDateTime = new Date(`${dayBeforeRaceFormatted}T20:00:00`); // 8 PM

    // Compare if current time is exactly 8 PM
    if (currentHongKongDateTime.getTime() >= eightPMDateTime.getTime()) {
      isSchedule = true;
    } else if (currentHongKongDateFormatted <= dayAfterRaceFormatted) {
      isSchedule = true;
    }
  } else {
    isSchedule = false;
  }

  return isSchedule;
};

export default EightPMSchedule;
