import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import type { FormProps, UploadFile } from "antd";
import {
  Button,
  Form,
  Input,
  Typography,
  Upload,
  Select,
  Tooltip,
  Checkbox,
  message,
} from "antd";
import MainLayout from "../Layout/MainLayout";
import "../../styles/ArticleWrite.css";
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { RcFile } from "antd/es/upload";
import RefreshAccessToken from "../Authentication/RefreshToken";
import { useTranslation } from "react-i18next";
const { Title } = Typography;
const { Option } = Select;
const ArticleWrite: React.FC = () => {
  return (
    <div>
      <MainLayout Component={PageDisplay} />
    </div>
  );
};

export default ArticleWrite;

const PageDisplay: React.FC = () => {
  type FieldType = {
    fileList: any;
    title?: string;
    paragraph?: string;
    authorName?: string;
    embeddedLink?: string;
  };
  const envUrl = process.env.REACT_APP_API_URL;
  const { t } = useTranslation();
  const writeArticleUrl = envUrl + "articles/";
  const [loading, setLoading] = useState<boolean>(false);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [title, setTitle] = useState<string>("");
  const [embeddedLinkDisabled, setEmbeddedLinkDisabled] =
    useState<boolean>(true);
  const [bannerDisabled, setBannerDisabled] = useState<boolean>(true);
  const [pictureDisabled, setPictureDisabled] = useState<boolean>(true);
  const [paragraph, setParagraph] = useState<string>("");
  const [embeddedLink, setEmbeddedLink] = useState<string | null>(null);
  const [pictureFileList, setPictureFileList] = useState<UploadFile[]>([]);
  const [bannerFileList, setBannerFileList] = useState<UploadFile[]>([]);
  const isLoggedInRedux2 = useSelector((state: RootState) => state.auth.user);
  const isLoggedInRedux: boolean = useSelector(
    (state: RootState) => state.auth.isLoggedIn
  );

  useEffect(() => {
    const refreshAccessToken = async () => {
      if (isLoggedInRedux2 && isLoggedInRedux2.user.refreshToken) {
        const refreshTokens = isLoggedInRedux2.user.refreshToken;
        const refreshedAccessToken = await RefreshAccessToken(refreshTokens);
        setAccessToken(refreshedAccessToken);
      } else {
      }
    };
    refreshAccessToken();
  }, [isLoggedInRedux, isLoggedInRedux2]);

  const WriteArticle = async (formData: FormData) => {
    axios
      .post(writeArticleUrl, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log("Data posted successfully:", response.data);
        setLoading(false);
        message.success("Article added Successfully!");
      })
      .catch((error) => {
        console.error("Error posting data:", error);
        setLoading(false);
        message.error("something went wrong! try again");
      })
      .finally(() => {
        setLoading(false);

        console.log("Post request completed.");
      });
  };

  const onFinish = async () => {
    setLoading(true);
    const formData = new FormData();

    formData.append("title", title);
    formData.append("paragraph", paragraph);
    formData.append("status", "DRAFT");

    if (embeddedLinkDisabled && embeddedLink !== "") {
      formData.append("embedded_link", embeddedLink);
    }
    if (bannerDisabled && bannerFileList[0]?.originFileObj) {
      formData.append("article_banner", bannerFileList[0].originFileObj);
    }
    if (pictureDisabled && pictureFileList[0]?.originFileObj) {
      formData.append("article_picture", pictureFileList[0].originFileObj);
    }

    await WriteArticle(formData);
  };

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (
    errorInfo
  ) => {
    console.log("Failed:", errorInfo);
  };

  const handlePictureFileChange = (info: any) => {
    let fileList = [...info.fileList];
    setPictureFileList(fileList);
  };

  const handleBannerFileChange = (info: any) => {
    let fileList = [...info.fileList];
    setBannerFileList(fileList);
  };

  return (
    <div>
      <Title
        style={{
          margin: "0px",
          padding: "0px",
          display: "inline",
          fontFamily: "sans-serif",
        }}
        level={2}
      >
        {t("write_article")}
      </Title>
      <p
        style={{
          display: "inline",
          marginLeft: "8px",
          fontSize: "16px",
          fontFamily: "sans-serif",
          color: "rgb(100, 100, 100)",
        }}
      >
        {t("saved_in_draft")}
      </p>
      <Form
        name="basic"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        style={{
          /*maxWidth: 600*/ margin: "0px",
          padding: "0px",
          marginTop: "4%",
        }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item<FieldType>
          label={t("article_title")}
          name="title"
          rules={[{ required: true, message: t("title_needed") }]}
        >
          <Input
            className="inputParametersArticleWrite"
            placeholder={t("insert_title")}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label={t("paragraph")}
          name="paragraph"
          rules={[{ required: true, message: t("paragraph_needed") }]}
        >
          <TextArea
            rows={2}
            placeholder={t("insert_paragraph")}
            value={paragraph}
            onChange={(e) => setParagraph(e.target.value)}
          />
        </Form.Item>{" "}
        <Checkbox
          checked={embeddedLinkDisabled}
          onChange={(e) => setEmbeddedLinkDisabled(e.target.checked)}
          style={{ marginBottom: "16px" }}
        >
          {t("include_youtube_link")}
        </Checkbox>
        {embeddedLinkDisabled && (
          <Form.Item<FieldType>
            label={
              <span>
                {t("youtube_embedded_link")}&nbsp;
                <Tooltip title={t("youtube_embedded_link_caution")}>
                  <ExclamationCircleOutlined style={{ color: "red" }} />
                </Tooltip>
              </span>
            }
            name="embeddedLink"
          >
            <Input
              className="inputParametersArticleWrite"
              placeholder={t("insert_embedded_link")}
              // value={embeddedLink}
              onChange={(e) => setEmbeddedLink(e.target.value)}
              disabled={!embeddedLinkDisabled}
            />
          </Form.Item>
        )}{" "}
        <Checkbox
          checked={bannerDisabled}
          onChange={(e) => setBannerDisabled(e.target.checked)}
          style={{ marginBottom: "16px" }}
        >
          {t("include_banner_picture")}
        </Checkbox>
        {bannerDisabled && (
          <Form.Item
            label={t("upload_banner_picture")}
            valuePropName="fileList"
          >
            <Upload
              listType="picture-card"
              maxCount={1}
              showUploadList={true}
              disabled={!bannerDisabled}
              onChange={handleBannerFileChange}
              customRequest={({ file, onSuccess }) => {
                setBannerFileList([file]);
                onSuccess?.(file);
              }}
            >
              <button style={{ border: 0, background: "none" }} type="button">
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>{t("upload")}</div>
              </button>
            </Upload>
          </Form.Item>
        )}
        <Checkbox
          checked={pictureDisabled}
          onChange={(e) => setPictureDisabled(e.target.checked)}
          style={{ marginBottom: "16px" }}
        >
          {t("include_picture")}
        </Checkbox>
        {pictureDisabled && (
          <Form.Item label={t("upload_picture")} valuePropName="fileList">
            <Upload
              listType="picture-card"
              maxCount={1}
              showUploadList={{ showPreviewIcon: true, showRemoveIcon: true }}
              fileList={pictureFileList}
              disabled={!pictureDisabled}
              onChange={handlePictureFileChange}
            >
              <button style={{ border: 0, background: "none" }} type="button">
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>{t("upload")}</div>
              </button>
            </Upload>
          </Form.Item>
        )}
        <Form.Item wrapperCol={{ offset: 0, span: 4 }}>
          <Button
            type="primary"
            htmlType="submit"
            style={{ marginTop: "12%" }}
            loading={loading}
          >
            {t("submit")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
