import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import type { FormProps, UploadFile } from "antd";
import {
  Button,
  Form,
  Input,
  Typography,
  Upload,
  Tooltip,
  Checkbox,
  message,
} from "antd";
import MainLayout from "../Layout/MainLayout";
import "../../styles/ArticleWrite.css";
import { ExclamationCircleOutlined, PlusOutlined } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { RcFile } from "antd/es/upload";
import RefreshAccessToken from "../Authentication/RefreshToken";

const { Title } = Typography;

type ArticleProps = {
  id: number;
  title: string;
  paragraph: string;
  author: {
    username: string;
    email: string;
    first_name: string;
    last_name: string;
    id: number;
  };
  embedded_link: string | null;
  article_picture: string;
  article_banner: string;
  post_date_time: string;
  status: string;
};

const ArticleEdit: React.FC = () => {
  return (
    <div>
      <MainLayout Component={PageDisplay} />
    </div>
  );
};

export default ArticleEdit;

const PageDisplay: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const envUrl = process.env.REACT_APP_API_URL;
  const refreshAccessUrl: string = envUrl + "token/refresh/";
  const editArticleUrl = envUrl + "articles/";
  const [loading, setLoading] = useState<boolean>(false);

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>();
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [embeddedLinkDisabled, setEmbeddedLinkDisabled] =
    useState<boolean>(true);
  const [bannerDisabled, setBannerDisabled] = useState<boolean>(true);
  const [pictureDisabled, setPictureDisabled] = useState<boolean>(true);
  const [article, setArticle] = useState<ArticleProps | null>(null);
  const [pictureFileList, setPictureFileList] = useState<UploadFile[]>([]);
  const [bannerFileList, setBannerFileList] = useState<UploadFile[]>([]);
  const isLoggedInRedux2 = useSelector((state: RootState) => state.auth.user);
  const [refreshToken, setRefreshtoken] = useState<string | null>(null);
  const isLoggedInRedux: boolean = useSelector(
    (state: RootState) => state.auth.isLoggedIn
  );
  const { id } = useParams();

  useEffect(() => {
    setIsLoggedIn(isLoggedInRedux);

    const checkLoginAndFetchArticles = async () => {
      if (isLoggedInRedux2 && isLoggedInRedux2.user.refreshToken) {
        const refreshTokens = isLoggedInRedux2.user.refreshToken;
        console.log(refreshToken);
        setRefreshtoken(refreshTokens);
        const accessToken = await RefreshAccessToken(refreshTokens);
        setAccessToken(accessToken);
        if (accessToken) {
          await GetArticleData(accessToken);
          console.log("User is logged in and articles fetched");
        }
      } else {
        console.log("User is not logged in or access token is missing.");
      }
    };
    checkLoginAndFetchArticles();
  }, [isLoggedInRedux, isLoggedInRedux2]);

  const GetArticleData = async (accessToken: string) => {
    console.log("Access token: " + accessToken);
    try {
      const response = await axios.get(`${editArticleUrl}${id}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });

      const articleData = response.data.data;
      console.log(articleData);
      setArticle(articleData); // Store the article data in state

      // Set the form fields with the fetched article data
      form.setFieldsValue({
        title: articleData.title,
        paragraph: articleData.paragraph,
        embeddedLink: articleData.embedded_link,
      });

      // Set the initial state for file uploads
      if (articleData.article_picture) {
        setPictureFileList([
          {
            uid: "-1",
            name: "article_picture.png",
            status: "done",
            url: articleData.article_picture,
          },
        ]);
      }

      if (articleData.article_banner) {
        setBannerFileList([
          {
            uid: "-1",
            name: "article_banner.png",
            status: "done",
            url: articleData.article_banner,
          },
        ]);
      }

      console.log(response.data);
    } catch (error) {
      console.log("Unexpected Error During Fetching Articles:", error);
    }
  };

  const WriteArticle = async (formData: FormData) => {
    console.log(formData.values());
    axios
      .put(`${editArticleUrl}${id}/`, formData, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response) => {
        console.log("Data posted successfully:", response.data);
        setLoading(false);

        message.success("Article Edited Successfully!");
      })
      .catch((error) => {
        message.error("Unexpected error occured, please try again");
        setLoading(false);
        console.error("Error posting data:", error);
      })
      .finally(() => {
        setLoading(false);
        console.log("Post request completed.");
      });
  };

  const onFinish = async () => {
    try {
      setLoading(true);
      const fieldsValue = form.getFieldsValue();
      console.log(fieldsValue);

      const formData = new FormData();

      formData.append("title", fieldsValue.title);
      formData.append("paragraph", fieldsValue.paragraph);
      formData.append("status", "DRAFT");

      if (embeddedLinkDisabled && fieldsValue.embeddedLink) {
        formData.append("embedded_link", fieldsValue.embeddedLink);
      }

      if (bannerDisabled && bannerFileList[0]?.originFileObj) {
        formData.append("article_banner", bannerFileList[0].originFileObj);
      }
      if (pictureDisabled && pictureFileList[0]?.originFileObj) {
        formData.append("article_picture", pictureFileList[0].originFileObj);
      }

      console.log("FormData contents:");

      // Call the function to submit the data
      await WriteArticle(formData);
    } catch (error) {
      console.error("Error preparing form data:", error);
    }
  };

  const onFinishFailed: FormProps["onFinishFailed"] = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handlePictureFileChange = (info: any) => {
    let fileList = [...info.fileList];
    setPictureFileList(fileList);
  };

  const handleBannerFileChange = (info: any) => {
    let fileList = [...info.fileList];
    setBannerFileList(fileList);
  };

  return (
    <div>
      <Title
        style={{
          margin: "0px",
          padding: "0px",
          display: "inline",
          fontFamily: "sans-serif",
        }}
        level={2}
      >
        Edit Article
      </Title>
      <p
        style={{
          display: "inline",
          marginLeft: "8px",
          fontSize: "16px",
          fontFamily: "sans-serif",
          color: "rgb(100, 100, 100)",
        }}
      >
        saved in draft
      </p>
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        style={{
          margin: "0px",
          padding: "0px",
          marginTop: "4%",
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
      >
        <Form.Item
          label="Article Title"
          name="title"
          rules={[{ required: true, message: "Please input your title!" }]}
        >
          <Input
            className="inputParametersArticleWrite"
            placeholder="Insert Title"
            value={article?.title}
            onChange={(e) => form.setFieldValue("title", e.target.value)}
          />
        </Form.Item>
        <Form.Item
          label="Paragraph"
          name="paragraph"
          rules={[{ required: true, message: "Please input your paragraph!" }]}
        >
          <TextArea
            rows={2}
            placeholder="Insert Paragraph"
            value={article?.paragraph}
            onChange={(e) => form.setFieldValue("paragraph", e.target.value)}
          />
        </Form.Item>
        <Checkbox
          checked={embeddedLinkDisabled}
          onChange={(e) => setEmbeddedLinkDisabled(e.target.checked)}
          style={{ marginBottom: "16px" }}
        >
          Include Youtube Link?
        </Checkbox>
        {embeddedLinkDisabled && (
          <Form.Item label="YouTube Link" name="embeddedLink">
            <Input
              className="inputParametersArticleWrite"
              placeholder="Paste Embedded Link"
              value={article?.embedded_link || ""}
              onChange={(e) =>
                form.setFieldValue("embeddedLink", e.target.value)
              }
            />
          </Form.Item>
        )}
        <Checkbox
          checked={bannerDisabled}
          onChange={(e) => setBannerDisabled(e.target.checked)}
          style={{ marginBottom: "16px" }}
        >
          Include Banner Image?
        </Checkbox>
        {bannerDisabled && (
          <Form.Item
            label="Upload Banner"
            tooltip={{
              title: "Max size: 5MB",
              icon: <ExclamationCircleOutlined />,
            }}
          >
            <Upload
              listType="picture-card"
              maxCount={1}
              onChange={handleBannerFileChange}
              fileList={bannerFileList}
              beforeUpload={() => false} // Prevent automatic upload
            >
              {bannerFileList.length >= 1 ? null : (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              )}
            </Upload>
          </Form.Item>
        )}
        <Checkbox
          checked={pictureDisabled}
          onChange={(e) => setPictureDisabled(e.target.checked)}
          style={{ marginBottom: "16px" }}
        >
          Include Article Picture?
        </Checkbox>
        {pictureDisabled && (
          <Form.Item
            label="Upload Picture"
            tooltip={{
              title: "Max size: 5MB",
              icon: <ExclamationCircleOutlined />,
            }}
          >
            <Upload
              listType="picture-card"
              maxCount={1}
              onChange={handlePictureFileChange}
              fileList={pictureFileList}
              beforeUpload={() => false} // Prevent automatic upload
            >
              {pictureFileList.length >= 1 ? null : (
                <div>
                  <PlusOutlined />
                  <div style={{ marginTop: 8 }}>Upload</div>
                </div>
              )}
            </Upload>
          </Form.Item>
        )}
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Save as Draft
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
