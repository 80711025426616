import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App.tsx";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import { store, persistor } from "./store.ts";
import { ConfigProvider } from "antd";
import { APPLICATION_THEME } from "./theme/APPLICATION_THEME.ts";
import LoadingPage from "./components/Loading/loading.tsx";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "./i18n/config.ts";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ConfigProvider theme={APPLICATION_THEME}>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <React.Suspense fallback={<LoadingPage />}>
            <App />
          </React.Suspense>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </ConfigProvider>
);

reportWebVitals();
