import React from "react";
import { Table } from "antd";
import "../../styles/QuinellaTable.css";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

// Define the manually selected diagonal cells

type QwinTableProps = {
  qwinOdds: any[];
  horseInfoColumns: any[];
  widthStyle: string;
  isMobile: boolean;
};

const QwinTable = ({ qwinOdds, widthStyle, isMobile }: QwinTableProps) => {
  const currentLanguage: string = useSelector(
    (state: RootState) => state.language.lng
  );
  const { t } = useTranslation();
  const leftDiagonal = [
    [0, 1],
    [1, 2],
    [2, 3],
    [3, 4],
    [4, 5],
    [5, 6],
    [6, 7],
  ];
  const rightDiagonal = [
    [0, 2],
    [1, 3],
    [2, 4],
    [3, 5],
    [4, 6],
    [5, 7],
    [6, 8],
  ];

  const columnWidth = 5;
  const columns = [
    {
      title: isMobile ? (
        currentLanguage === "zh" ? (
          <p style={{ margin: "0px", padding: "0px", fontSize: "0.3rem" }}>
            {t("qwin")}
          </p>
        ) : (
          <p style={{ margin: "0px", padding: "0px", fontSize: "0.4rem" }}>
            Q<sup style={{ fontSize: "smaller", textAlign: "center" }}>win</sup>
          </p>
        )
      ) : (
        t("qwin")
      ),
      dataIndex: "col1",
      key: "col1",
      width: columnWidth,
    },
    { title: "", dataIndex: "col2", key: "col2", width: columnWidth },
    { title: "", dataIndex: "col3", key: "col3", width: columnWidth },
    ...Array.from({ length: 13 }, (_, index) => ({
      title: (index + 2).toString(),
      dataIndex: `col${index + 4}`,
      key: `col${index + 4}`,
      width: columnWidth,
      align: "center",
    })),
    { title: "", dataIndex: "col17", key: "col17", width: 5 },
  ];

  // Generate rows dynamically based on provided data
  const dataTemplate = [
    ["", "8", "1", "", "", "", "", "", "", "", "", "", "", "", "", "", "1"],
    ["9", "", "9", "2", "", "", "", "", "", "", "", "", "", "", "", "", "2"],
    ["10", "", "", "10", "3", "", "", "", "", "", "", "", "", "", "", "", "3"],
    ["11", "", "", "", "11", "4", "", "", "", "", "", "", "", "", "", "", "4"],
    ["12", "", "", "", "", "12", "5", "", "", "", "", "", "", "", "", "", "5"],
    ["13", "", "", "", "", "", "13", "6", "", "", "", "", "", "", "", "", "6"],
    ["14", "", "", "", "", "", "", "14", "7", "", "", "", "", "", "", "", "7"],
  ];

  const transformedData = qwinOdds.reduce((acc, item) => {
    acc[item.combString] = item.oddsValue;
    return acc;
  }, {});

  console.log(transformedData);

  //+======================================================+
  dataTemplate[1][1] = transformedData["08,09"]?.toString();
  dataTemplate[2][1] = transformedData["08,10"]?.toString();
  dataTemplate[3][1] = transformedData["08,11"]?.toString();
  dataTemplate[4][1] = transformedData["08,12"]?.toString();
  dataTemplate[5][1] = transformedData["08,13"]?.toString(); //absent
  dataTemplate[6][1] = transformedData["08,14"]?.toString(); // absent
  //+========================================================+
  dataTemplate[2][2] = transformedData["09,10"]?.toString();
  dataTemplate[3][2] = transformedData["09,11"]?.toString();
  dataTemplate[4][2] = transformedData["09,12"]?.toString();
  dataTemplate[5][2] = transformedData["09,13"]?.toString(); // absent
  dataTemplate[6][2] = transformedData["09,14"]?.toString(); // absent
  //+========================================================+
  dataTemplate[3][3] = transformedData["10,11"]?.toString();
  dataTemplate[4][3] = transformedData["10,12"]?.toString();
  dataTemplate[5][3] = transformedData["10,13"]?.toString(); // absent
  dataTemplate[6][3] = transformedData["10,14"]?.toString(); // absent
  //+=========================================================+
  dataTemplate[4][4] = transformedData["11,12"]?.toString();
  dataTemplate[5][4] = transformedData["11,13"]?.toString(); // absent
  dataTemplate[6][4] = transformedData["11,14"]?.toString(); // absent
  //+=========================================================+
  dataTemplate[5][5] = transformedData["12,13"]?.toString(); //absent
  dataTemplate[6][5] = transformedData["12,14"]?.toString(); //absent
  //+=========================================================+
  dataTemplate[6][6] = transformedData["13,14"]?.toString(); // absent

  dataTemplate[0][3] = transformedData["01,02"]?.toString();
  dataTemplate[0][4] = transformedData["01,03"]?.toString();
  dataTemplate[0][5] = transformedData["01,04"]?.toString();
  dataTemplate[0][6] = transformedData["01,05"]?.toString();
  dataTemplate[0][7] = transformedData["01,06"]?.toString();
  dataTemplate[0][8] = transformedData["01,07"]?.toString();
  dataTemplate[0][9] = transformedData["01,08"]?.toString();
  dataTemplate[0][10] = transformedData["01,09"]?.toString();
  dataTemplate[0][11] = transformedData["01,10"]?.toString();
  dataTemplate[0][12] = transformedData["01,11"]?.toString();
  dataTemplate[0][13] = transformedData["01,12"]?.toString();
  dataTemplate[0][14] = transformedData["01,13"]?.toString();
  dataTemplate[0][15] = transformedData["01,14"]?.toString();
  //+========================================================+
  dataTemplate[1][4] = transformedData["02,03"]?.toString();
  dataTemplate[1][5] = transformedData["02,04"]?.toString();
  dataTemplate[1][6] = transformedData["02,05"]?.toString();
  dataTemplate[1][7] = transformedData["02,06"]?.toString();
  dataTemplate[1][8] = transformedData["02,07"]?.toString();
  dataTemplate[1][9] = transformedData["02,08"]?.toString();
  dataTemplate[1][10] = transformedData["02,09"]?.toString();
  dataTemplate[1][11] = transformedData["02,10"]?.toString();
  dataTemplate[1][12] = transformedData["02,11"]?.toString();
  dataTemplate[1][13] = transformedData["02,12"]?.toString();
  dataTemplate[1][14] = transformedData["02,13"]?.toString();
  dataTemplate[1][15] = transformedData["02,14"]?.toString();
  //+========================================================+
  dataTemplate[2][5] = transformedData["03,04"]?.toString();
  dataTemplate[2][6] = transformedData["03,05"]?.toString();
  dataTemplate[2][7] = transformedData["03,06"]?.toString();
  dataTemplate[2][8] = transformedData["03,07"]?.toString();
  dataTemplate[2][9] = transformedData["03,08"]?.toString();
  dataTemplate[2][10] = transformedData["03,09"]?.toString();
  dataTemplate[2][11] = transformedData["03,10"]?.toString();
  dataTemplate[2][12] = transformedData["03,11"]?.toString();
  dataTemplate[2][13] = transformedData["03,12"]?.toString();
  dataTemplate[2][14] = transformedData["03,13"]?.toString();
  dataTemplate[2][15] = transformedData["03,14"]?.toString();
  //+========================================================+
  dataTemplate[3][6] = transformedData["04,05"]?.toString();
  dataTemplate[3][7] = transformedData["04,06"]?.toString();
  dataTemplate[3][8] = transformedData["04,07"]?.toString();
  dataTemplate[3][9] = transformedData["04,08"]?.toString();
  dataTemplate[3][10] = transformedData["04,09"]?.toString();
  dataTemplate[3][11] = transformedData["04,10"]?.toString();
  dataTemplate[3][12] = transformedData["04,11"]?.toString();
  dataTemplate[3][13] = transformedData["04,12"]?.toString();
  dataTemplate[3][14] = transformedData["04,13"]?.toString();
  dataTemplate[3][15] = transformedData["04,14"]?.toString();
  //+========================================================+
  dataTemplate[4][7] = transformedData["05,06"]?.toString();
  dataTemplate[4][8] = transformedData["05,07"]?.toString();
  dataTemplate[4][9] = transformedData["05,08"]?.toString();
  dataTemplate[4][10] = transformedData["05,09"]?.toString();
  dataTemplate[4][11] = transformedData["05,10"]?.toString();
  dataTemplate[4][12] = transformedData["05,11"]?.toString();
  dataTemplate[4][13] = transformedData["05,12"]?.toString();
  dataTemplate[4][14] = transformedData["05,13"]?.toString();
  dataTemplate[4][15] = transformedData["05,14"]?.toString();
  //+========================================================+
  dataTemplate[5][8] = transformedData["06,07"]?.toString();
  dataTemplate[5][9] = transformedData["06,08"]?.toString();
  dataTemplate[5][10] = transformedData["06,09"]?.toString();
  dataTemplate[5][11] = transformedData["06,10"]?.toString();
  dataTemplate[5][12] = transformedData["06,11"]?.toString();
  dataTemplate[5][13] = transformedData["06,12"]?.toString();
  dataTemplate[5][14] = transformedData["06,13"]?.toString();
  dataTemplate[5][15] = transformedData["06,14"]?.toString();
  //+========================================================+
  dataTemplate[6][9] = transformedData["07,08"]?.toString();
  dataTemplate[6][10] = transformedData["07,09"]?.toString();
  dataTemplate[6][11] = transformedData["07,10"]?.toString();
  dataTemplate[6][12] = transformedData["07,11"]?.toString();
  dataTemplate[6][13] = transformedData["07,12"]?.toString();
  dataTemplate[6][14] = transformedData["07,13"]?.toString();
  dataTemplate[6][15] = transformedData["07,14"]?.toString();

  // Convert template into an Ant Design-compatible format
  const dataSource = dataTemplate.map((row, rowIndex) => {
    const rowData = { key: rowIndex + 1 };
    row.forEach((value, colIndex) => {
      rowData[`col${colIndex + 1}`] = value;
    });
    return rowData;
  });

  const isDiagonal = (rowIndex: number, colIndex: number) => {
    return (
      leftDiagonal.some(([r, c]) => r === rowIndex && c === colIndex) ||
      rightDiagonal.some(([r, c]) => r === rowIndex && c === colIndex)
    );
  };
  return (
    <>
      {isMobile ? (
        <Table
          className="quinellaTableMobile"
          columns={columns.map((col, colIndex) => ({
            ...col,
            render: (text: string, record: any, rowIndex: number) => {
              const backgroundColor = isDiagonal(rowIndex, colIndex)
                ? "#F0F0F0"
                : "transparent";
              return (
                <div
                  style={{
                    backgroundColor,
                    textAlign: "center",
                    fontSize: "0.5rem",
                  }}
                >
                  {text}
                </div>
              );
            },
          }))}
          style={{ width: widthStyle }}
          scroll={{ x: "max-content" }}
          dataSource={dataSource}
          pagination={false} // Disable pagination if not needed
          rowClassName={(_, index) =>
            index % 2 === 0 ? "row-even" : "row-odd"
          }
          tableLayout="fixed"
        />
      ) : (
        <Table
          className="quinellaTable"
          columns={columns.map((col, colIndex) => ({
            ...col,
            render: (text: string, record: any, rowIndex: number) => {
              const backgroundColor = isDiagonal(rowIndex, colIndex)
                ? "#F0F0F0"
                : "transparent";
              return (
                <div
                  style={{
                    backgroundColor,
                    textAlign: "center",
                  }}
                >
                  {text}
                </div>
              );
            },
          }))}
          style={{ width: widthStyle }}
          dataSource={dataSource}
          pagination={false} // Disable pagination if not needed
          rowClassName={(_, index) =>
            index % 2 === 0 ? "row-even" : "row-odd"
          }
          tableLayout="fixed"
        />
      )}
    </>
  );
};

export default QwinTable;
