const FetchHorseInformaiton = (
  raceNumber: number,
  horseNumber: number,
  metaRaceDataDetails: any,
  currentLanguage: string
) => {
  console.log(metaRaceDataDetails);
  const runners: any[] = metaRaceDataDetails[raceNumber - 1].runners;
  let jockeyName, horseName, trainerName, currentRating;
  runners.forEach((runner: any) => {
    if (horseNumber.toString() === runner.no) {
      if (currentLanguage === "en") {
        jockeyName = runner.jockey.name_en;
        horseName = runner.name_en;
        trainerName = runner.trainer.name_en;
      } else {
        jockeyName = runner.jockey.name_ch;
        horseName = runner.name_ch;
        trainerName = runner.trainer.name_ch;
      }
      currentRating = Number(runner.currentRating);
    }
  });

  return [jockeyName, horseName, trainerName, currentRating];
};

export default FetchHorseInformaiton;
