import React, { useEffect, useState } from "react";
import MainLayout from "../Layout/MainLayout";
import { Card, Row, Col, Pagination, Skeleton } from "antd";
import axios, { AxiosResponse } from "axios";
import UpickBanner from "../../pictures/channels4_banner.jpg";
import UpickProfile from "../../pictures/channels4_profile.jpg";
import Paragraph from "antd/es/typography/Paragraph";
import "../../styles/YoutubeLinks.css";
const { Meta } = Card;

const API_KEY = "AIzaSyCsa9yjYo9gYoUGN5cx_ZlY4C_6srspwR4"; // Replace with your actual API key
const CHANNEL_ID = "UCQUDZ0_EZGAlEaC7wXt-yoA"; // Replace with your channel ID
const BASE_URL = "https://www.googleapis.com/youtube/v3";

interface YouTubeVideo {
  title: string;
  videoId: string;
  thumbnailUrl: string;
}

const VideoListTest: React.FC = () => {
  const [videos, setVideos] = useState<YouTubeVideo[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize] = useState<number>(20);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [bannerUrl, setBannerUrl] = useState<string | null>(null);
  const [channelName, setChannelName] = useState<string>("");
  const [subscriberCount, setSubscriberCount] = useState<string>("");
  const [videoCount, setVideoCount] = useState<string>("");
  const [description, setDescription] = useState<string>("");

  useEffect(() => {
    const fetchChannelDetails = async () => {
      try {
        const channelUrl = `${BASE_URL}/channels?part=brandingSettings,snippet,statistics,contentDetails&id=${CHANNEL_ID}&key=${API_KEY}`;
        const channelResponse: AxiosResponse<any> = await axios.get(channelUrl);

        const channelData = channelResponse.data.items[0];
        setChannelName(channelData.snippet.title);
        setSubscriberCount(channelData.statistics.subscriberCount);
        setVideoCount(channelData.statistics.videoCount);
        setDescription(channelData.snippet.description);

        setBannerUrl(channelData.brandingSettings.image.bannerExternalUrl);
      } catch (error) {
        setError("Failed to fetch channel details: " + error);
      }
    };

    const fetchAllVideos = async () => {
      try {
        let allVideos: YouTubeVideo[] = [];
        let nextPageToken: string | null = null;

        const channelUrl = `${BASE_URL}/channels?part=contentDetails&id=${CHANNEL_ID}&key=${API_KEY}`;
        const channelResponse: AxiosResponse<any> = await axios.get(channelUrl);
        const uploadsPlaylistId =
          channelResponse.data.items[0].contentDetails.relatedPlaylists.uploads;

        do {
          const videosUrl = `${BASE_URL}/playlistItems?part=snippet&maxResults=50&playlistId=${uploadsPlaylistId}&key=${API_KEY}&pageToken=${
            nextPageToken || ""
          }`;
          const videosResponse: AxiosResponse<any> = await axios.get(videosUrl);

          const fetchedVideos = videosResponse.data.items.map((item: any) => ({
            title: item.snippet.title,
            videoId: item.snippet.resourceId.videoId,
            thumbnailUrl: item.snippet.thumbnails.high.url,
          }));

          allVideos = [...allVideos, ...fetchedVideos];
          nextPageToken = videosResponse.data.nextPageToken;
        } while (nextPageToken);

        setVideos(allVideos);
      } catch (error) {
        setError("Failed to fetch videos: " + error);
      } finally {
        setLoading(false);
      }
    };

    fetchChannelDetails();
    fetchAllVideos();
  }, []);

  const indexOfLastVideo = currentPage * pageSize;
  const indexOfFirstVideo = indexOfLastVideo - pageSize;
  const currentVideos = videos.slice(indexOfFirstVideo, indexOfLastVideo);

  const handleChangePage = (page: number) => {
    setCurrentPage(page);
  };

  if (loading) {
    return (
      <div>
        <Skeleton active />
        <Skeleton active />
        <Skeleton active />
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      {bannerUrl && (
        <img
          src={UpickBanner}
          className="channelBanner"
          alt="YouTube Channel Banner"
          style={{
            width: "100%",
            maxHeight: "200px",
            objectFit: "cover",
            marginBottom: "30px",
          }}
        />
      )}
      <div className="channelInfo">
        <div className="channelImage channelInfoItems">
          <img
            className="upickProfile"
            alt="upick-channel-profile"
            src={UpickProfile}
          />
        </div>
        <div className="channelDetails channelInfoItems">
          <h1 style={{ marginBottom: "0" }} className="channelName">
            {channelName}
          </h1>
          <Paragraph
            className="profileDescription"
            style={{ color: "#71797E" }}
          >
            @{channelName.toLowerCase().replace(/\s+/g, "_")} •{" "}
            {parseInt(subscriberCount).toLocaleString()} subscribers •{" "}
            {parseInt(videoCount).toLocaleString()} videos
          </Paragraph>
          <Paragraph className="profileDescription">{description}</Paragraph>
        </div>
      </div>
      <Row gutter={[16, 16]}>
        {currentVideos.map((video) => (
          <Col key={video.videoId} xs={24} sm={12} md={8} lg={6}>
            <a
              href={`https://www.youtube.com/watch?v=${video.videoId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Card
                hoverable
                cover={<img alt={video.title} src={video.thumbnailUrl} />}
              >
                <Meta title={video.title} />
              </Card>
            </a>
          </Col>
        ))}
      </Row>
      <Pagination
        current={currentPage}
        pageSize={pageSize}
        total={videos.length}
        onChange={handleChangePage}
        style={{ marginTop: "20px" }}
        className="pagination-center"
      />
    </div>
  );
};

const YoutubeLinks: React.FC = () => {
  return (
    <div>
      <MainLayout Component={VideoListTest} />
    </div>
  );
};

export default YoutubeLinks;
