import React, { useEffect, useState } from "react";
import MainLayout from "../Layout/MainLayout";
import { Flex, Progress, Spin, Table, Tabs } from "antd";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import RefreshAccessToken from "../Authentication/RefreshToken";
import totalRaceMetaData from "../CommonApiCalls/totalRace";
import { useTranslation } from "react-i18next";
import LockedContent from "../LockedContent/LockedContent";
import { Radar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from "chart.js";
import TitleCardDesktop from "./Responsive Title/TitleCardDesktop";
import Segment from "./Responsive Title/Segment";
import TitleCard from "./Responsive Title/TitleCard";
import FetchHorseNames from "../Mapper/horseName";
import fetchRaceStartTime from "../Mapper/raceSchedule";
import EightPMSchedule from "../OutputScheduler/EightPMSchedule";
import ResultsNa8Pm from "./ResultsNa8Pm";
import RaceInformationSaved from "../OutputScheduler/RaceInformationSaved";
import FetchRaceInformation from "../Mapper/raceInformationMapper";
import fetchAllMetaDetailsOdds from "../CommonApiCalls/metaDataRaceInformation";

// Register the radar chart components
ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);
const HorseStrengthIndex: React.FC = () => {
  return (
    <div>
      <MainLayout Component={PageDisplay} />
    </div>
  );
};

export default HorseStrengthIndex;

interface RaceData {
  "Horse number": number;
  "Strength index": number;
}

const PageDisplay: React.FC = () => {
  const envUrl = process.env.REACT_APP_API_URL;
  const [raceNumber, setRaceNumber] = useState<number>(1);
  const [maxRaceNumber, setMaxRaceNumber] = useState<number>(1);
  const { t } = useTranslation();
  const [selectedRace, setSelectedRace] = useState<RaceData[]>([]);
  const [isMobile, setIsMobile] = useState(false);
  const [isSchedule, setIsScheduled] = useState<boolean>(true);
  const [raceTitle, setRaceTitle] = useState("");
  const [raceDayPlace, setRaceDayPlace] = useState("");
  const [restOfDetails, setRestOfDetails] = useState("");
  const [strengthIndicatorsArray, setStrengthIndicatorsArray] = useState<[]>(
    []
  );

  const columns = [
    {
      title: t("horses"),
      dataIndex: "Horse number",
      key: "Horse number",
      align: isMobile ? "center" : "inherit", // Aligns the content of the cell
      render: (text: string) => (
        <span style={{ fontSize: isMobile ? "0.7rem" : "inherit" }}>
          {text}
        </span>
      ),
    },
    {
      title: t("strength_index"),
      dataIndex: "Strength index",
      key: "Strength index",
      render: (text: number) => (
        <Progress
          percent={text * 140}
          // percent={text * 50}
          showInfo={true}
          format={(percent) => `${Math.floor(percent)}%`}
        />
      ),
    },
  ];
  const [strengthIndicators, setStrengthIndicators] = useState<any[]>([]);
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const statisticskingUrl = `${envUrl}horse-strength-index/`;
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([1]);
  const isLoggedInRedux2 = useSelector((state: RootState) => state.auth.user);
  const currentLanguage: string = useSelector(
    (state: RootState) => state.language.lng
  );
  const fetchTotalMetaData = async () => {
    const [metaRaceData, totalRace] = await totalRaceMetaData();
    return [metaRaceData, totalRace];
  };
  useEffect(() => {
    setIsLoading(true);
    setIsSelected(false);
    const fetchData = async () => {
      const [metaRaceData, totalRace] = await fetchTotalMetaData();
      const [metaRaceDatas, metaRaceDataInfo] = await fetchAllMetaDetailsOdds(
        currentLanguage
      );
      if (isLoggedInRedux2 && isLoggedInRedux2.user.refreshToken) {
        const refreshToken = isLoggedInRedux2.user.refreshToken;
        const accessToken = await RefreshAccessToken(refreshToken);
        if (accessToken && metaRaceData) {
          const [
            savedMetaRaceData,
            savedMetaRaceInfoValue,
            responseValue,
            accessLevelValue,
          ] = await fetchStatisticsKingData(accessToken);

          const [raceStartDate, raceStartTime] = fetchRaceStartTime(
            raceNumber,
            metaRaceData
          );

          const [savedRaceStartDate, savedRaceStartTime] = fetchRaceStartTime(
            raceNumber,
            savedMetaRaceData
          );

          let isSchedule: boolean = await EightPMSchedule(
            raceStartTime,
            raceStartDate,
            savedRaceStartDate,
            savedRaceStartTime
          );
          if (isSchedule) {
            const raceDayPlaceVenue = Object.keys(savedMetaRaceInfoValue)
              .filter((key: any) => !isNaN(key))
              .sort((a: any, b: any) => a - b)
              .map((key: any) => savedMetaRaceInfoValue[key])
              .join("");

            const [raceTitle, raceDayPlace, restOfDetails] =
              FetchRaceInformation(
                raceNumber,
                raceDayPlaceVenue,
                savedMetaRaceData,
                currentLanguage
              );
            {
              setRaceTitle(raceTitle);
              setRaceDayPlace(raceDayPlace);
              setRestOfDetails(restOfDetails);
            }

            if (accessLevelValue === null) {
              tableResponse(responseValue, savedMetaRaceData);
            } else if (accessLevelValue !== null && raceNumber >= 2) {
              setSelectedRace([]);
              setIsLoading(false);
            } else {
              tableResponse(responseValue, savedMetaRaceData);
            }
          } else {
            const raceDayPlaceVenue = Object.keys(metaRaceDataInfo)
              .filter((key: any) => !isNaN(key))
              .sort((a: any, b: any) => a - b)
              .map((key: any) => metaRaceDataInfo[key])
              .join("");
            const [raceTitle, raceDayPlace, restOfDetails] =
              FetchRaceInformation(
                raceNumber,
                raceDayPlaceVenue,
                metaRaceData,
                currentLanguage
              );
            {
              setRaceTitle(raceTitle);
              setRaceDayPlace(raceDayPlace);
              setRestOfDetails(restOfDetails);
            }
            if (accessLevelValue === null) {
              tableResponse(responseValue, metaRaceData);
            } else if (accessLevelValue !== null && raceNumber >= 2) {
              setSelectedRace([]);

              setIsLoading(false);
            } else {
              tableResponse(responseValue, metaRaceData);
            }
          }
          setIsScheduled(isSchedule);
        }
      }
    };
    fetchData();
  }, [raceNumber, currentLanguage]);

  useEffect(() => {
    if (selectedRace.length > 0) {
      setSelectedRowKeys([1]); // Set the first row as selected by default
      console.log();
      console.log(strengthIndicatorsArray);
      const dataObject = strengthIndicatorsArray[selectedRowKeys[0] - 1];
      const valuesArray = Object.values(dataObject);
      const multipliedValuesArray = valuesArray.map(
        (value: any) => value * 100 // multiplied the decimal points with 100
      );
      setStrengthIndicators(multipliedValuesArray);
      setIsSelected(true);
    }
  }, [selectedRace, strengthIndicatorsArray]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const tableResponse = (response: any, metaRaceData: any) => {
    const responseData = response.data.data;
    const totalRaces = Object.keys(metaRaceData).length;
    setMaxRaceNumber(totalRaces);
    const horseNumbers = responseData.data[raceNumber - 1].Data.map(
      (item: any) => item["Horse number"]
    );

    const [jockeyNames, horseNames, trainerNames, currentRatings] =
      FetchHorseNames(raceNumber, horseNumbers, metaRaceData, currentLanguage);
    console.log(horseNames);
    console.log(responseData.data[raceNumber - 1].Data);
    const statisticKingData = responseData.data[raceNumber - 1].Data;

    const updatedData = statisticKingData.map((item: any, index: any) => ({
      ...item,
      "Horse number": horseNames[index], // replace the horse number with the respective name
    }));
    setSelectedRace(
      updatedData.map((item: any, index: any) => ({
        ...item,
        key: index + 1, // Adding an indexed key starting from 1
      }))
    );

    setStrengthIndicatorsArray(
      responseData.data[raceNumber - 1].Data.map(
        (item: any) => item["Strength Indicators"]
      )
    );
    setIsLoading(false);
  };

  const fetchStatisticsKingData = async (accessToken: string) => {
    let savedMetaRaceData: string = "";
    let responseValue: any = "";
    let accessLevelValue: string | null = "";
    let savedMetaRaceInfoValue: any = "";
    await axios
      .get(statisticskingUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const accessLevel: string | null = response.data.message;
        savedMetaRaceData = response?.data?.data?.meta_data?.data?.raceMeetings;
        const [savedMetaData, savedMetaRaceInfo, savedVenueCode] =
          RaceInformationSaved(savedMetaRaceData, currentLanguage);
        savedMetaRaceData = savedMetaData;
        savedMetaRaceInfoValue = savedMetaRaceInfo;
        responseValue = response;
        accessLevelValue = accessLevel;
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
    return [
      savedMetaRaceData,
      savedMetaRaceInfoValue,
      responseValue,
      accessLevelValue,
    ];
  };
  const items = Array.from({ length: maxRaceNumber }, (_, i) => ({
    key: (i + 1).toString(),
    label: `${t("race")} ${i + 1}`,
    children: "",
  }));

  const onChange = (key: string) => {
    setRaceNumber(parseInt(key, 10));
    console.log(key);
  };

  const rowSelection = {
    type: "radio", // Ensuring it's a radio button selection
    selectedRowKeys, // Set the selected row keys
    onChange: (selectedKeys: number[]) => {
      setSelectedRowKeys(selectedKeys); // Update the selected keys state

      const selectedHorseNumber = selectedKeys[0] - 1;
      console.log("Selected Horse Number:", selectedHorseNumber);

      const dataObject = strengthIndicatorsArray[selectedHorseNumber];
      console.log(strengthIndicatorsArray);
      const valuesArray = Object.values(dataObject);
      const multipliedValuesArray = valuesArray.map(
        (value: any) => value * 100
      );
      console.log(selectedKeys);
      console.log(multipliedValuesArray);
      setStrengthIndicators(multipliedValuesArray);

      setIsSelected(true);
    },
  };
  let data;
  if (currentLanguage === "en") {
    data = {
      labels: [
        "Track performance", // 同程同道
        "Jockey Draw P%", // 騎檔上名
        "Horse State", // 馬匹狀態
        "Jockey P%", // 騎程上名
        "Trainer P%", // 練程上名
        "Horse Strength", // 馬匹實力
        "HKJC opening", // 馬會開盤
      ],
      datasets: [
        {
          label: "",
          data: strengthIndicators,
          fill: true,
          backgroundColor: "rgba(255, 99, 132, 0.2)",
          borderColor: "rgb(255, 99, 132)",
          pointBackgroundColor: "rgb(255, 99, 132)",
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "rgb(255, 99, 132)",
        },
      ],
    };
  } else {
    data = {
      labels: [
        "同程同道",
        "騎檔上名",
        "馬匹狀態",
        "騎程上名",
        "練程上名",
        "馬匹實力",
        "馬會開盤",
      ],
      datasets: [
        {
          label: "",
          data: strengthIndicators,
          fill: true,
          backgroundColor: "rgba(255, 99, 132, 0.2)",
          borderColor: "rgb(255, 99, 132)",
          pointBackgroundColor: "rgb(255, 99, 132)",
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "rgb(255, 99, 132)",
        },
      ],
    };
  }

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    scales: {
      r: {
        beginAtZero: true,
        ticks: {
          maxTicksLimit: isMobile ? 5 : null,
        },
      },
    },
  };
  return (
    <div>
      {isMobile ? (
        <div>
          <Segment value="horse_strength_index" />
        </div>
      ) : (
        <></>
      )}
      {isMobile ? (
        <TitleCard
          title={t("horse_strength_index")}
          date={""}
          venue={""}
          day={""}
          maxRaceNumber={maxRaceNumber}
          onChange={onChange}
        />
      ) : (
        <>
          <TitleCardDesktop
            title={t("horse_strength_index")}
            date={""}
            venue={""}
            day={""}
            maxRaceNumber={maxRaceNumber}
            onChange={onChange}
          />
        </>
      )}
      <Flex
        justify="space-between"
        style={{
          marginBottom: "12px",
          paddingLeft: "4px",
          paddingRight: "4px",
        }}
      >
        <p
          style={{
            fontFamily: '"Montserrat", sans-serif',
            margin: "0px",
            fontSize: isMobile ? "0.6rem" : "0.8rem",
          }}
        >
          {" "}
          {raceTitle}
        </p>
        <p
          style={{
            fontFamily: '"Montserrat", sans-serif',
            margin: "0px",
            fontSize: isMobile ? "0.6rem" : "0.8rem",
          }}
        >
          {" "}
          {restOfDetails}
        </p>
      </Flex>
      {isLoading ? (
        <Flex justify="center" align="center">
          <Spin tip="Loading" size="large"></Spin>
        </Flex>
      ) : (
        <>
          {isSchedule ? (
            <>
              {" "}
              <Table
                dataSource={selectedRace}
                columns={columns}
                size="small"
                rowSelection={rowSelection} // Apply the updated rowSelection
                pagination={false}
                locale={{
                  emptyText: <LockedContent />,
                }}
              />
              {isSelected && (
                <>
                  {isMobile ? (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      <Radar data={data} options={options} />
                    </div>
                  ) : (
                    <Flex
                      style={{ width: "100%" }}
                      justify="center"
                      align="center"
                    >
                      <div
                        style={{
                          width: "40%",
                          height: "40%",
                        }}
                      >
                        <Radar data={data} options={options} />
                      </div>
                    </Flex>
                  )}
                </>
              )}
            </>
          ) : (
            <ResultsNa8Pm />
          )}
        </>
      )}
    </div>
  );
};
