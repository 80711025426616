import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Checkbox, Form, Input, Flex, Typography, message } from "antd";
import MainLayout from "../Layout/MainLayout";
import axios from "axios";
import { Tabs } from "antd";
import type { TabsProps } from "antd";
import AdminHome from "./AdminHome";
import AdminUsers from "./AdminUsers";
import AdminAuthors from "./AdminAuthors";
import RefreshAccessToken from "../Authentication/RefreshToken";
import { RootState } from "../../store";
import GetAllAuthors from "../Authentication/Authors";
import { useTranslation } from "react-i18next";
const { Title } = Typography;

const AdminHomePage: React.FC = () => {
  return (
    <div>
      <MainLayout Component={PageDisplay} />
    </div>
  );
};

export default AdminHomePage;

type User = {
  id: number;
  last_login: string | null;
  is_superuser: boolean;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  is_staff: boolean;
  is_active: boolean;
  date_joined: string;
  mobile_number: string;
  is_verified: boolean;
  is_subscribed: boolean;
  verification_method: string;
  groups: [];
  user_permissions: [];
};
const PageDisplay: React.FC = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingAuthors, setIsLoadingAuthors] = useState<boolean>(true);
  const [refreshToken, setRefreshToken] = useState<string>("");
  const [users, setUsers] = useState<User[] | null>([]);
  const [authors, setAuthors] = useState<User[] | null>([]);
  const { t } = useTranslation();
  const [totalAuthors, setTotalAuthors] = useState<number>(0);
  const [subscribedUsers, setSubscribedUsers] = useState<number>(0);
  const [unsubscribedUsers, setUnsubscribedUsers] = useState<number>(0);

  const envUrl = process.env.REACT_APP_API_URL;
  const allUserUrl = envUrl + "users/";
  const allAuthorsUrl = envUrl + "author/users/";
  const isLoggedInRedux2 = useSelector((state: RootState) => state.auth.user);
  const isLoggedInRedux: boolean = useSelector(
    (state: RootState) => state.auth.isLoggedIn
  );

  const GetAllUsers = async (accessToken: string): Promise<User[] | null> => {
    try {
      const response = await axios.get(allUserUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      console.log(response.data);
      return response.data.data as User[];
    } catch (error) {
      console.log("Unexpected Error During Fetching Articles:", error);
      return null;
    }
  };

  useEffect(() => {
    const checkLoginAndFetchArticles = async () => {
      setIsLoading(true);
      setIsLoggedIn(isLoggedInRedux);

      if (isLoggedInRedux2 && isLoggedInRedux2.user.refreshToken) {
        const refreshToken = isLoggedInRedux2.user.refreshToken;
        setRefreshToken(refreshToken);

        const accessToken = await RefreshAccessToken(refreshToken);
        if (accessToken) {
          const usersData = await GetAllUsers(accessToken);
          if (usersData) {
            setUsers(usersData);

            // Calculate total number of authors, subscribed users, and unsubscribed users
            const totalAuthors = usersData.filter((user) =>
              user.groups.includes(1)
            ).length;
            const subscribedUsers = usersData.filter(
              (user) => user.is_subscribed
            ).length;
            const unsubscribedUsers = usersData.filter(
              (user) => !user.is_subscribed
            ).length;

            console.log(
              `Total Authors: ${totalAuthors}\nTotal Subscribed Users: ${subscribedUsers}\nUnsubscribed Users: ${unsubscribedUsers}`
            );

            setTotalAuthors(totalAuthors);
            setSubscribedUsers(subscribedUsers);
            setUnsubscribedUsers(unsubscribedUsers);
          }

          const authorsData = await GetAllAuthors(accessToken);
          setIsLoadingAuthors(false);
          if (authorsData) {
            setAuthors(authorsData);
          }
        }
      }

      setIsLoading(false);
    };

    checkLoginAndFetchArticles();
  }, []);
  const onChange = (key: string) => {
    console.log(key);
  };

  const refetchAuthors = async () => {
    setIsLoadingAuthors(true);
    const accessToken = await RefreshAccessToken(refreshToken);
    if (accessToken) {
      const authorsData = await GetAllAuthors(accessToken);
      setIsLoadingAuthors(false);
      console.log(`Authors: ${authorsData}`);
      if (authorsData) {
        setAuthors(authorsData);
      }
    }
  };

  const items: TabsProps["items"] = [
    {
      key: "Home",
      label: t("home"),
      children: (
        // setTotalAuthors(totalAuthors);
        // setSubscribedUsers(subscribedUsers);
        // setUnsubscribedUsers(unsubscribedUsers);
        <AdminHome
          authors={authors}
          isLoading={isLoadingAuthors}
          refreshToken={refreshToken}
          refetchAuthors={refetchAuthors}
          totalAuthors={totalAuthors}
          totalSubscribedUsers={subscribedUsers}
          totalUnsubscribedUsers={unsubscribedUsers}
        />
      ),
    },
    {
      key: "All Users",
      label: t("users"),
      children: <AdminUsers users={users} />, // Pass the users prop here
    },
    {
      key: "Authors",
      label: t("authors"),
      disabled: true,
      children: <AdminAuthors />,
    },
    {
      key: "Subscription",
      label: t("subscription"),
      children: "Show subscription panel and increase and decrease prizes here",
      disabled: true,
    },
  ];
  return (
    <div>
      {" "}
      <Tabs defaultActiveKey="Home" items={items} onChange={onChange} />
    </div>
  );
};
