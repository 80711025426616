import React, { useEffect, useState } from "react";
import MainLayout from "../Layout/MainLayout";
import { Flex, Progress, Spin, Table } from "antd";
import axios from "axios";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import RefreshAccessToken from "../Authentication/RefreshToken";
import { useTranslation } from "react-i18next";
import totalRaceMetaData from "../CommonApiCalls/totalRace";
import LockedContent from "../LockedContent/LockedContent";
import TitleCardDesktop from "../InstantBettingSuggestion/Responsive Title/TitleCardDesktop";
import Segment from "./Responsive Title/Segment";
import TitleCard from "../InstantBettingSuggestion/Responsive Title/TitleCard";
import FetchHorseNames from "../Mapper/horseName";
import fetchRaceStartTime from "../Mapper/raceSchedule";
import InstantSchedularOneHour from "../OutputScheduler/InstantSchedularOneHour";
import OneHourNA from "../InstantBettingSuggestion/Dependencies/OneHourNA";
import RaceInformationSaved from "../OutputScheduler/RaceInformationSaved";
import FetchRaceInformation from "../Mapper/raceInformationMapper";
import fetchAllMetaDetailsOdds from "../CommonApiCalls/metaDataRaceInformation";

const FinalTip: React.FC = () => {
  return (
    <div>
      <MainLayout Component={PageDisplay} />
    </div>
  );
};

export default FinalTip;

interface RaceData {
  "Horse number": number;
  "Strength index": number;
}

const PageDisplay: React.FC = () => {
  const { t } = useTranslation();
  const envUrl = process.env.REACT_APP_API_URL;
  const [raceNumber, setRaceNumber] = useState<number>(1);
  const [maxRaceNumber, setMaxRaceNumber] = useState<number>(1);
  const [selectedRace, setSelectedRace] = useState<RaceData[]>([]);
  const [isMobile, setIsMobile] = useState(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const statisticskingUrl = `${envUrl}final-tips/`;
  const isLoggedInRedux2 = useSelector((state: RootState) => state.auth.user);
  const [raceTitle, setRaceTitle] = useState("");
  const [raceDayPlace, setRaceDayPlace] = useState("");
  const [restOfDetails, setRestOfDetails] = useState("");
  const [isSchedule, setIsScheduled] = useState<boolean>(true);
  const currentLanguage: string = useSelector(
    (state: RootState) => state.language.lng
  );

  const columns = [
    {
      title: t("horse"),
      dataIndex: "Horse number",
      key: "Horse number",
      align: isMobile ? "center" : "inherit",
      render: (text: string) => (
        <span style={{ fontSize: isMobile ? "0.7rem" : "inherit" }}>
          {text}
        </span>
      ),
    },
    {
      title: t("score"),
      dataIndex: "Percentage",
      key: "Percentage",
      align: isMobile ? "center" : "inherit",
      render: (text: number) => (
        <Progress
          percent={text * 50}
          showInfo={true}
          format={(percent) => `${Math.floor(percent)}%`}
        />
      ),
    },
  ];

  const fetchTotalMetaData = async () => {
    const [metaRaceData, totalRace] = await totalRaceMetaData();
    return [metaRaceData, totalRace];
  };

  const fetchMetaRaceDataDetails = async () => {
    const [metaRaceData, metaRaceDataInfo] = await fetchAllMetaDetailsOdds(
      currentLanguage
    );
    return [metaRaceData, metaRaceDataInfo];
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      if (isLoggedInRedux2 && isLoggedInRedux2.user.refreshToken) {
        const refreshToken = isLoggedInRedux2.user.refreshToken;

        const accessToken = await RefreshAccessToken(refreshToken);
        if (accessToken) {
          const [metaRaceData, totalRace] = await fetchTotalMetaData();
          const [
            savedMetaRaceData,
            savedMetaRaceInfoValue,
            responseValue,
            accessLevelValue,
          ] = await fetchStatisticsKingData(accessToken);
          const [metaRaceDatas, metaRaceDataInfo] =
            await fetchMetaRaceDataDetails();

          const [raceStartDate, raceStartTime] = fetchRaceStartTime(
            1,
            metaRaceData
          );
          const [savedRaceStartDate, savedRaceStartTime] = fetchRaceStartTime(
            1,
            savedMetaRaceData
          );
          const isSchedule: boolean = await InstantSchedularOneHour(
            raceStartTime,
            raceStartDate,
            savedRaceStartDate,
            savedRaceStartTime
          );

          if (isSchedule) {
            const raceDayPlaceVenue = Object.keys(savedMetaRaceInfoValue)
              .filter((key: any) => !isNaN(key))
              .sort((a: any, b: any) => a - b)
              .map((key: any) => savedMetaRaceInfoValue[key])
              .join("");

            const [raceTitle, raceDayPlace, restOfDetails] =
              FetchRaceInformation(
                raceNumber,
                raceDayPlaceVenue,
                savedMetaRaceData,
                currentLanguage
              );
            {
              setRaceTitle(raceTitle);
              setRaceDayPlace(raceDayPlace);
              setRestOfDetails(restOfDetails);
            }
            if (accessLevelValue === null) {
              console.log("here");
              tableContent(responseValue, savedMetaRaceData);
            } else if (accessLevelValue !== null && raceNumber >= 2) {
              setSelectedRace([]);
              setIsLoading(false);
            } else {
              tableContent(responseValue, savedMetaRaceData);
            }
          } else {
            const raceDayPlaceVenue = Object.keys(metaRaceDataInfo)
              .filter((key: any) => !isNaN(key))
              .sort((a: any, b: any) => a - b)
              .map((key: any) => metaRaceDataInfo[key])
              .join("");

            const [raceTitle, raceDayPlace, restOfDetails] =
              FetchRaceInformation(
                raceNumber,
                raceDayPlaceVenue,
                metaRaceData,
                currentLanguage
              );
            {
              setRaceTitle(raceTitle);
              setRaceDayPlace(raceDayPlace);
              setRestOfDetails(restOfDetails);
            }
            if (accessLevelValue === null) {
              console.log("here");
              tableContent(responseValue, metaRaceData);
            } else if (accessLevelValue !== null && raceNumber >= 2) {
              setSelectedRace([]);
              setIsLoading(false);
            } else {
              tableContent(responseValue, metaRaceData);
            }
          }
          setIsScheduled(isSchedule);
        }
      }
    };
    fetchData();
  }, [raceNumber, currentLanguage]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const tableContent = (response: any, metaRaceData: any) => {
    try {
      const responseData = response.data.data;
      const totalRaces = Object.keys(metaRaceData).length;
      setMaxRaceNumber(totalRaces);

      const horseNumbers = responseData.data[raceNumber - 1].Data.map(
        (item: any) => item["Horse number"]
      );
      console.log(metaRaceData);
      const [jockeyNames, horseNames, trainerNames, currentRatings] =
        FetchHorseNames(
          raceNumber,
          horseNumbers,
          metaRaceData,
          currentLanguage
        );

      const statisticKingData = responseData.data[raceNumber - 1].Data;
      const updatedData = statisticKingData.map((item: any, index: any) => ({
        ...item,
        "Horse number": horseNames[index],
      }));
      setSelectedRace(
        updatedData.map((item: any, index: any) => ({
          ...item,
          key: index + 1,
        }))
      );
    } catch (error) {
      console.error(error);
    }

    setIsLoading(false);
  };

  const fetchStatisticsKingData = async (accessToken: string) => {
    let savedMetaRaceData: string = "";
    let responseValue: any = "";
    let accessLevelValue: string | null = "";
    let savedMetaRaceInfoValue: any = "";
    await axios
      .get(statisticskingUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((response) => {
        const accessLevel: string | null = response.data?.message;
        savedMetaRaceData = response?.data?.data?.meta_data?.data?.raceMeetings;

        const [savedMetaData, savedMetaRaceInfo, savedVenueCode] =
          RaceInformationSaved(savedMetaRaceData, currentLanguage);
        savedMetaRaceData = savedMetaData;
        savedMetaRaceInfoValue = savedMetaRaceInfo;
        responseValue = response;
        accessLevelValue = accessLevel;
      })
      .catch((error) => {
        console.error("Error posting data:", error);
      });
    return [
      savedMetaRaceData,
      savedMetaRaceInfoValue,
      responseValue,
      accessLevelValue,
    ];
  };
  const items = Array.from({ length: maxRaceNumber }, (_, i) => ({
    key: (i + 1).toString(),
    label: `${t("race")} ${i + 1}`,
    children: "",
  }));

  const onChange = (key: string) => {
    setRaceNumber(parseInt(key, 10));
  };

  return (
    <div>
      {" "}
      {isMobile ? <Segment value="Final_Tip" /> : <></>}
      {isMobile ? (
        <TitleCard
          title={t("final_tips")}
          date={""}
          venue={""}
          day={""}
          maxRaceNumber={maxRaceNumber}
          onChange={onChange}
        />
      ) : (
        <>
          <TitleCardDesktop
            title={t("final_tips")}
            date={""}
            venue={""}
            day={""}
            maxRaceNumber={maxRaceNumber}
            onChange={onChange}
          />
        </>
      )}
      <Flex
        justify="space-between"
        style={{
          marginBottom: "12px",
          paddingLeft: "4px",
          paddingRight: "4px",
        }}
      >
        <p
          style={{
            fontFamily: '"Montserrat", sans-serif',
            margin: "0px",
            fontSize: isMobile ? "0.6rem" : "0.8rem",
          }}
        >
          {" "}
          {raceTitle}
        </p>
        <p
          style={{
            fontFamily: '"Montserrat", sans-serif',
            margin: "0px",
            fontSize: isMobile ? "0.6rem" : "0.8rem",
          }}
        >
          {" "}
          {restOfDetails}
        </p>
      </Flex>
      {isLoading ? (
        <Flex justify="center" align="center">
          <Spin tip="Loading" size="large"></Spin>
        </Flex>
      ) : (
        <>
          {isSchedule ? (
            <>
              {" "}
              <Table
                dataSource={selectedRace}
                columns={columns}
                size="small"
                pagination={false}
                locale={{
                  emptyText: <LockedContent />,
                }}
                rowClassName={(_, index) =>
                  index % 2 === 0 ? "row-even" : "row-odd"
                }
              />
            </>
          ) : (
            <OneHourNA />
          )}
        </>
      )}
    </div>
  );
};
