// Settings: Billing information change here
// Phone number, user name,  emails, passwords change here
// Payment plans change here
import React from "react";
import { useNavigate } from "react-router-dom";
import { Button, Checkbox, Form, Input, Flex, Typography } from "antd";
import MainLayout from "../Layout/MainLayout";

const { Title } = Typography;

const AccountSettings: React.FC = () => {
  return (
    <div>
      <MainLayout Component={PageDisplay} />
    </div>
  );
};

export default AccountSettings;

const PageDisplay: React.FC = () => {
  const navigate = useNavigate();

  return <div></div>;
};
