// src/state/language/languageSlice.js
import { createSlice } from "@reduxjs/toolkit";

const languageSlice = createSlice({
  name: "language",
  initialState: {
    lng: "zh", // Default language
  },
  reducers: {
    switchLanguage: (state, action) => {
      state.lng = action.payload;
    },
  },
});

export const { switchLanguage } = languageSlice.actions;
export default languageSlice.reducer;
