import axios from "axios";
const envUrl = process.env.REACT_APP_API_URL;
const allHorseInfoRaceMetaDetails = envUrl + "race-meta-detail/";

const fetchAllMetaDetailsOdds = async (currentLanguage: string) => {
  try {
    const response = await axios.get(allHorseInfoRaceMetaDetails);
    const racesString = response.data.data.data; // Assuming this is the string
    let races;

    try {
      races = JSON.parse(racesString);
      const raceMeetings = races.data.raceMeetings; // add filter where venue = hv or st

      const selectedRaceMeeting = raceMeetings.find(
        (meeting: any) =>
          meeting.venueCode === "HV" || meeting.venueCode === "ST"
      );
      const allRacesData = selectedRaceMeeting.races;
      let allRacesDataInfo = selectedRaceMeeting.dateOfWeek;
      if (selectedRaceMeeting.dateOfWeek == "SUN") {
        allRacesDataInfo = currentLanguage === "zh" ? "星期日" : "Sunday";
      } else if (selectedRaceMeeting.dateOfWeek == "WED") {
        allRacesDataInfo = currentLanguage === "zh" ? "星期三" : "Wednesday";
      } else {
        allRacesDataInfo = selectedRaceMeeting.dateOfWeek;
      }
      const dateTime = selectedRaceMeeting.date;

      let venueCode = selectedRaceMeeting.venueCode;
      if (currentLanguage === "zh") {
        venueCode = venueCode === "HV" ? "跑馬地" : "沙田馬場";
      } else {
        venueCode = venueCode === "HV" ? "Happy Valley" : "Sha Tin";
      }

      allRacesDataInfo = allRacesDataInfo + ", " + dateTime + ", " + venueCode;

      return [allRacesData, allRacesDataInfo, venueCode];
    } catch (error) {
      console.error("Error parsing JSON:", error);
      return [[], ""];
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    return [[], ""];
  }
};

export default fetchAllMetaDetailsOdds;
