import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import type { FormProps } from "antd";
import { useDispatch } from "react-redux";
import { Button, Form, Input, Typography, message } from "antd";
import MainLayout from "../Layout/MainLayout";
import axios from "axios";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import { login } from "../../state/auth/authSlice";
import { setAuthors } from "../../state/author/authorSlice";
import { useTranslation } from "react-i18next";

const { Title } = Typography;

type FieldType = {
  username?: string;
  password?: string;
  remember?: boolean;
};

const boxStyle: React.CSSProperties = {
  width: "90%",
  margin: "0 auto",
  maxWidth: "400px", // Added to limit width for larger screens
};

const innerStyle: React.CSSProperties = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  marginRight: "0", // Removed margin for centering
};

const Login: React.FC = () => {
  return (
    <div>
      <MainLayout Component={PageDisplay} />
    </div>
  );
};

export default Login;

const PageDisplay: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [postSubmitMessage, setPostSubmitMessage] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [showForgotPasswordForm, setShowForgotPasswordForm] =
    useState<boolean>(false);
  const { t } = useTranslation();
  const envUrl = process.env.REACT_APP_API_URL;
  const loginUrl = envUrl + "login/";
  const getCurrentUserUrl = envUrl + "users/current/";
  const fetchAuthorsUrl = envUrl + "author/users/";

  interface Author {
    id: string;
    first_name: string;
    last_name: string;
    email: string;
  }
  const fetchAuthors = async (accessToken: string) => {
    try {
      const responseAuthor = await axios.get(fetchAuthorsUrl, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      const authorData = responseAuthor.data.data;
      const payload = authorData.map((data: Author) => ({
        id: data.id,
        firstName: data.first_name,
        lastName: data.last_name,
        email: data.email,
      }));
      console.log(payload);
      message.success(t("login_successful"));
      dispatch(setAuthors(payload));
    } catch (error) {
      console.error(error);
    }
  };
  const loginUser = async () => {
    const payload = {
      username: userName,
      password: password,
    };
    try {
      const response = await axios.post(loginUrl, payload);
      const responseData = response.data;
      const responseCurrentUser = await axios.get(getCurrentUserUrl, {
        headers: {
          Authorization: `Bearer ${responseData.data.access}`,
        },
      });
      const currentUserData = responseCurrentUser.data;
      dispatch(
        login({
          isLoggedIn: true,
          user: {
            id: currentUserData.data?.id,
            username: currentUserData.data?.username,
            first_name: currentUserData.data?.first_name,
            last_name: currentUserData.data?.last_name,
            is_active: currentUserData.data?.is_active,
            date_joined: currentUserData.data?.date_joined,
            email: currentUserData.data?.email,
            mobile_number: currentUserData.data?.mobile_number,
            is_subscribed: currentUserData.data?.is_subscribed,
            is_superuser: currentUserData.data?.is_superuser,
            subscription_end_date: currentUserData.data?.subscription_end_date,
            is_verified: currentUserData.data?.is_verified,
            verification_method: currentUserData.data?.verification_method,
            groups: currentUserData.data?.groups[0],
            refreshToken: responseData.data.refresh,
            accessToken: responseData.data.access,
          },
        })
      );
      setTimeout(() => {
        setLoading(false);
        form.resetFields();
      }, 1000);
      await fetchAuthors(responseData.data.access);
      navigate("/");
    } catch (error) {
      try {
        if (axios.isAxiosError(error)) {
          setLoading(false);
          const serverError = error.response?.data;
          if (
            serverError.message ===
            "No active account found with the given credentials"
          ) {
            setLoading(false);
            message.error(t("invalid_credentials"));
          }
        } else {
          setLoading(false);
          setPostSubmitMessage("*Invalid Credentials.");
          message.error(t("unexpected_error_occurred"));
        }
      } catch (error) {
        message.error(t("something_went_wrong"));
        console.error(error);
      }
    }
  };

  const onFinish: FormProps<FieldType>["onFinish"] = async (values) => {
    setLoading(true);
    await loginUser();
  };

  const onFinishFailed: FormProps<FieldType>["onFinishFailed"] = (
    errorInfo
  ) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div>
      <div style={boxStyle}>
        <div style={innerStyle}>
          <Title level={2}>{t("login_lower_caps")}</Title>
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 600 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item<FieldType>
              label={t("username")}
              name="username"
              rules={[{ required: true, message: t("username_required") }]}
            >
              <Input
                prefix={<UserOutlined />}
                placeholder={t("username")}
                size="large"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
            </Form.Item>

            <Form.Item<FieldType>
              label={t("password")}
              name="password"
              rules={[{ required: true, message: t("enter_password") }]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder={t("password")}
                size="large"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Form.Item>

            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "space-evenly",
                  gap: "4%",
                  alignItems: "center",
                }}
              >
                <Button
                  className="submitBtn"
                  type="primary"
                  htmlType="submit"
                  loading={loading}
                >
                  {t("submit")}
                </Button>
                <Link
                  to="/forget_password"
                  onClick={() => setShowForgotPasswordForm((prev) => !prev)}
                  style={{ width: "100%", paddingTop: "10px" }}
                >
                  {t("forgot_password")}
                </Link>
              </div>
            </Form.Item>
            <p style={{ textAlign: "center" }}>
              {t("do_not_have_account")}{" "}
              <Link to="/registration">{t("here")}</Link>
            </p>
          </Form>
        </div>
      </div>
    </div>
  );
};
