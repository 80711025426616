import React, { useEffect, useState } from "react";
import MainLayout from "../Layout/MainLayout.tsx";
import { Card, Col, Row, Table, Tabs, Button } from "antd";
import axios from "axios";

const { Meta } = Card;

// Define TypeScript interfaces for the data structures
interface Horse {
  [key: string]: any;
  "Horse number": string;
  "Horse name": string;
}

interface RaceData {
  horses: Horse[];
  pq_results: any[];
}

interface RawData {
  race_number: number;
  race_data: RaceData;
}

interface ProcessedHorse {
  index: number;
  horse1?: Horse;
  horse2?: Horse;
}

function OnccResults() {
  return (
    <div>
      <MainLayout Component={HorseDataTables} />
    </div>
  );
}

export default OnccResults;

const HorseDataTables: React.FC = () => {
  const [horses, setHorses] = useState<Horse[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [maxRaceLength, setMaxRaceLength] = useState<number>(0);
  const [raceNumber, setRaceNumber] = useState<number>(1);
  const [rawData, setRawData] = useState<RawData[]>([]);
  const [pqResults, setPqResults] = useState<any[]>([]);
  const [hideSecondIndex, setHideSecondIndex] = useState<boolean>(false);

  // const dataUrl =
  //   "https://upick-api.ixorasolution.com/api/oncc-race-pq-dynamic/";
  const envUrl = process.env.REACT_APP_API_URL;
  const dataUrl = `${envUrl}/oncc-race-pq-dynamic/`;
  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await axios.get<RawData[]>(dataUrl);
        const data = response.data;
        setMaxRaceLength(data.length);
        setRawData(data);
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    try {
      const raceIdString = raceNumber.toString();
      const selectedRace = rawData.find(
        (race) => race.race_number.toString() === raceIdString
      );
      if (selectedRace) {
        setHorses(selectedRace.race_data.horses);
        setPqResults(selectedRace.race_data.pq_results);
      }
    } catch (error) {
      console.error(error);
    }
  }, [rawData, raceNumber]);

  // Group horses in pairs
  const processedHorses: ProcessedHorse[] = horses.reduce(
    (acc: ProcessedHorse[], horse: Horse, index: number) => {
      const pairIndex = Math.floor(index / 2);
      if (!acc[pairIndex]) acc[pairIndex] = { index: pairIndex + 1 };
      const horseKey = index % 2 === 0 ? "horse1" : "horse2";
      acc[pairIndex][horseKey] = horse;
      return acc;
    },
    []
  );

  const onChange = (key: string) => {
    setRaceNumber(parseInt(key, 10));
  };

  const toggleSecondIndex = () => {
    setHideSecondIndex(!hideSecondIndex);
  };

  const columnHorse = [
    {
      title: "Index",
      dataIndex: "index",
      key: "index",
      render: (text: any) => <span>{text}</span>,
    },
    {
      title: "Horse No. 1",
      dataIndex: ["horse1", "Horse number"],
      key: "horse_number_1",
    },
    {
      title: "Name 1",
      dataIndex: ["horse1", "Horse name"],
      key: "horse_name_1",
    },
    {
      title: "Horse No. 2",
      dataIndex: ["horse2", "Horse number"],
      key: "horse_number_2",
    },
    {
      title: "Horse 2",
      dataIndex: ["horse2", "Horse name"],
      key: "horse_name_2",
    },
  ];

  const pqColumns = Array.from({ length: 15 }, (_, i) => ({
    title: `Value ${i + 1}`,
    dataIndex: `value_${i + 1}`,
    key: `value_${i + 1}`,
    render: (text: any) => (
      <div style={{ display: "flex", flexDirection: "column" }}>
        {Array.isArray(text) ? (
          text.map((item: any, index: number) => (
            <div
              key={index}
              style={{
                color: hideSecondIndex && index === 1 ? "transparent" : "black",
              }}
            >
              {item}
            </div>
          ))
        ) : (
          <div>{text}</div>
        )}
      </div>
    ),
  }));

  return (
    <div className="mainContainerV5">
      <h1>Qwin/Qplace</h1>
      <Tabs
        defaultActiveKey="1"
        items={rawData.map((_, i) => ({
          key: (i + 1).toString(),
          label: `Race ${i + 1}`,
          children: "",
        }))}
        onChange={onChange}
      />

      <Table
        dataSource={pqResults}
        columns={pqColumns}
        scroll={{ x: 600 }} // Enable horizontal scrolling
        pagination={{ position: ["bottomCenter"], pageSize: 15 }}
      />
      <Button
        type="primary"
        onClick={toggleSecondIndex}
        style={{ margin: "16px 0" }}
      >
        {hideSecondIndex ? "Undo Transformation" : "Transform"}
      </Button>
    </div>
  );
};
